import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { imageState, isNextClickState } from '../store/bets_store';
//import { fixtures } from '../assets/data/fixtures';
import { GiSoccerBall } from 'react-icons/gi';
import { useEffect } from 'react';
import { getDailyFootballFixtures } from '../functions/api';
import Spinner from 'react-activity/dist/Spinner';
import Slider from './slider'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom';
import AddBetComponent from '../functions/bet'
import { getPaysByCC, renderCote } from '../functions/utils';
import { MdLock } from 'react-icons/md';

dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

function SportMiddle(props) {
    const [fixtures, setFixtures] = useState(null);
    const [page, setPage] = useState(1);
    const [resultLength, setResultLength] = useState(0)
    const [renderButtonText, setRenderButtonText] = useState({ text: 'Charger plus de resultats', disabled: false })
    const [searchParams, setSearchParams] = useSearchParams();
    const image = useRecoilValue(imageState)

    const getLeague = () => {
        if (parseInt(searchParams.get("league")) > 0) {
            return parseInt(searchParams.get("league"))
        }
        return 0
    }
    const league = getLeague()
    const [isNextClick, setIsNextClick] = useRecoilState(isNextClickState)
    const navigate = useNavigate()
    //const filteredArray = (arr) => arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

    const setTheFootball = (res) => {
        if (fixtures === null) {
            setFixtures(res.data)
        } else {
            setFixtures(fixtures.concat(res.data))
        }
    }

    useEffect(() => {
        if (league === 0 && isNextClick === true) {
            getDailyFootballFixtures(props.sport, page, setTheFootball, setResultLength, dayjs.utc().unix(), null)
        } else if (league === 0 && isNextClick === false) {
            setFixtures(null)
            getDailyFootballFixtures(props.sport, 1, (res) => {
                setFixtures(res.data)
            }, setResultLength, dayjs.utc().unix(), null)
        }
        else if (league > 0 && isNextClick === false) {
            setFixtures(null)
            getDailyFootballFixtures(props.sport, 1, (res) => {
                setFixtures(res.data)
            }, setResultLength, dayjs.utc().unix(), parseInt(league))
        } else if (league > 0 && isNextClick === true) {
            getDailyFootballFixtures(props.sport, page, setTheFootball, setResultLength, dayjs.utc().unix(), parseInt(league))
        }
    }, [page, league, props.sport]);


    useEffect(() => {
        if (fixtures !== null) {
            if (page <= 10) {
                setResultLength(fixtures.length)
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
                setIsNextClick(false)
            } else if (page >= 11) {
                setResultLength(fixtures.length)
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: true })
                setIsNextClick(false)
            }
        }
    }, [fixtures]);

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {
            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart

    const paginate = () => {
        if (fixtures !== null) {

            if (fixtures) {
                //openLoad()
                setIsNextClick(true)
                setRenderButtonText({ text: <Spinner color='#FFC900' />, disabled: true })
                setTimeout(() => {
                    setPage(page + 1)
                }, 2000);
            }
        }
    }


    const renderButton = () => {
        if (fixtures !== null && fixtures.length >= 1) {
            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => paginate()} disabled={renderButtonText.disabled}>{renderButtonText.text}</button>
                </div>
            )
        }
    }


    const renderMobile = () => {

        if (fixtures === null) {
            return <div className='is-flex is-justify-content-center mt-4'>
                <Spinner color='#FFC900' />
            </div>
        } else if (fixtures.length == 0) {

            return (
                <div className='mt-4'>
                    {/* <div className='is-flex is-justify-content-center px-6'>
                        <p className='has-text-centered is-size-5 has-text-light'> {props.icon} {props.name} </p>
                    </div> */}
                    <div className='is-flex is-justify-content-center mt-4 has-text-dark'>
                        <p className='has-text-dark'> Désolé il n' y a plus d' evenement a afficher .</p>
                    </div>
                </div>
            )
        }

        //return JSON.stringify(fixtures.odds)

        let theArr = fixtures

        return (
            <div>
                <div className=''>
                    <div className='mt-4 px-2 pb-2'>

                        {/* <div className='is-flex is-justify-content-center px-6'>
                            <p className='has-text-centered is-size-5 has-text-light'> {props.icon} {props.name} </p>
                        </div> */}
                        {/* <div className='is-flex is-justify-content-center'>
                            <p className='has-text-centered has-text-dark'><small> {getCompetition()} </small></p>
                        </div> */}
                        {
                            theArr.map((val, i) => {
                                return (
                                    <div className="mt-2">
                                        <p className="is-flex py-2" style={{ fontSize: '13.5px' }}>
                                            <small onClick={() => navigate('/sports/' + props.path.toLowerCase() + '/event?game=' + val.fixture.bet365_id)}><b> {props.icon} {val.fixture.home.name} </b>&nbsp; - &nbsp; <b> {val.fixture.away.name} &nbsp; </b> <small className=''>  {val.fixture.league.name}</small></small>
                                        </p>
                                        <div className="px-2 columns is-mobile" style={{ backgroundColor: 'transparent', fontSize: '13.5px', borderBottom: '1px solid black' }}>
                                            <p className="column is-3">
                                                <span className="ml-auto" ><small>{dayjs.unix(val.timestamp).format('h:mm A')}</small></span> <br />
                                                <span className="ml-auto"><small>{dayjs.unix(val.timestamp).format('D / MM / YYYY')}</small></span>
                                            </p>
                                            <div className="column is-auto is-flex is-justify-content-space-between">
                                                {
                                                    val.bets.map((v, i) => <div class="field has-addons pr-2" onClick={() => addBet({
                                                        sport: props.abr,
                                                        fixtureId: val.fixture.bet365_id,
                                                        country: val.fixture.league.name,
                                                        league: val.fixture.league.name,
                                                        homeTeam: val.fixture.home.name,
                                                        awayTeam: val.fixture.away.name,
                                                        timestamp: val.timestamp,
                                                        betName: 'Résultat final',
                                                        betChoose: v.name,
                                                        cote: v.odds > 1.01 ? renderCote(v.odds) : null,
                                                        amount: 0,
                                                        data: val.bets
                                                    }, val.fixture.bet365_id)}>
                                                        <div class="control">
                                                            <input className="input is-small" type="text" value={v.name} readOnly style={getInputStyle(val.fixture.bet365_id, { betName: 'Résultat final', betChoose: v.name })} />
                                                        </div>
                                                        <div class="control">
                                                            <a className="button is-small is-primary" >
                                                                {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                                            </a>
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                            <div className=" column is-1 is-flex pl-2">
                                                <div onClick={() => navigate('/sports/' + props.path.toLowerCase() + '/event?game=' + val.fixture.bet365_id)} >
                                                    <p style={{ fontSize: '15px' }}>{"+" + val.total}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </div>

        )
    }

    return (

        <>

            <div className='my-2' style={{ overflowY: 'auto', height: '150vh' }}>
                <div className='is-flex is-justify-content-center has-text-light'>
                    {/* {JSON.stringify(image)} */}

                    <Slider img1={image.img4} img2={image.img5} img3={image.img6} img4={image.img7} img5={image.img8} img6={image.img9} />


                </div>

                <div className='card mt-2' style={{ backgroundColor: '#EAEAEA' }}>
                    <header class="card-header">
                        <p class="card-header-title has-text-dark">
                            {props.icon} {props.name}
                        </p>
                    </header>
                    <div className='mt-4'>

                        {
                            renderMobile()
                        }
                    </div>
                    {renderButton()}
                </div>

            </div>
        </>

    )
}

export default SportMiddle;
