import React, { useState, useEffect } from 'react'
import { MdBarChart, MdSecurity } from 'react-icons/md';
import { BsFillCircleFill } from 'react-icons/bs'
import { BiBasket, BiStats } from 'react-icons/bi'
import { GiTrophy, GiSoccerBall, GiBasketballBall } from 'react-icons/gi';
import { getSingleOdd, getSingleMatch } from '../functions/api';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'react-activity/dist/Spinner';
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState, isNextClickState } from '../store/bets_store';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import img from '../assets/images/back2.jpg'
import * as dayjs from 'dayjs'
import { bet_list_basketball } from '../assets/data/bet_lists_basket';
import AddBetComponent from '../functions/bet';


dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')


function OddsListBasket() {

    const [style, setStyle] = useState({ enCours: 'has-text-primary', gagne: '', perdu: '', tout: '' })
    const [resultLength, setResultLength] = useState(0)
    const [oddsObj, setOddsObj] = useState(null)
    const [matchObj, setMatchObj] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [text, setText] = useRecoilState(textState)
    const [oddsArray, setOddsArray] = useState(bet_list_basketball.response)


    const getGame = () => {

        if (parseInt(searchParams.get("game")) > 0) {
            return parseInt(searchParams.get("game"))
        }
        return 0
    }

    const game = getGame()

    useEffect(() => {
        if (game > 0) {

            getSingleOdd(setOddsObj, setResultLength, parseInt(game), 'basketball')
            getSingleMatch('basketball', game, setMatchObj)

        }
    }, [game]);

const  addBet = AddBetComponent().addBet
const  verifBetInCart = AddBetComponent().verifBetInCart

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const toggleTabsCss = (tab) => {
        if (tab === 'tout') {
            setOddsArray(setTheArray(0))
            setStyle({
                tout: 'has-text-primary',
                resultat: '',
                buts: '',
                stats: ''
            })
        } else if (tab === 'resultat') {
            setOddsArray(setTheArray(1))
            setStyle({
                tout: '',
                resultat: 'has-text-primary',
                buts: '',
                stats: ''
            })
        } else if (tab === 'buts') {
            setOddsArray(setTheArray(2))
            setStyle({
                tout: '',
                resultat: '',
                buts: 'has-text-primary',
                stats: ''
            })
        } else {
            setOddsArray(setTheArray(3))
            setStyle({
                tout: '',
                resultat: '',
                buts: '',
                stats: 'has-text-primary'
            })
        }
    }

    const setTheArray = (payload) => {

        let arr = []

        switch (payload) {
            case 1:
                for (const i of bet_list_basketball.response) {

                    if (i.type == 1 || i.type == 12) {
                        arr.push(i)
                    }

                }
            case 2:
                for (const i of bet_list_basketball.response) {

                    if (i.type == 2 || i.type == 12) {
                        arr.push(i)
                    }

                }
            case 3:
                for (const i of bet_list_basketball.response) {

                    if (i.type == 3) {
                        arr.push(i)
                    }

                }

                break;

            default:
                arr = bet_list_basketball.response
        }

        return arr


    }





    const parseName = (nom, id) => {

        if (![92, 93, 95, 96].includes(id)) {

            const name = nom.toString()

            const mapObj = {
                Home: '1',
                Away: '2',
                Draw: 'X',
                Over: ">",
                Under: "<",
                Odd: "impair",
                Even: "pair",
                more: "",
                by: " -> ",
                "o/yes": "oui >",
                "u/yes": "oui <",
                "o/no": "non >",
                "u/no": "non <",
                "Score Draw": 'pas de but',
                Yes: "oui",
                No: "non",
                "1st Half": '1er',
                "2nd Half": '2eme',
                Header: 'Tete',
                FreeKick: 'Coup Franc',
                OwnGoal: 'Contre son camp',
                Shot: 'Tir',
                Exactly: ""

            }

            const result = name.replace(/Home|Away|Draw|Over|Under|Odd|Even|more|by|o\/yes|u\/yes|o\/no|u\/no|Score Draw|Yes|No|1st Half|2nd Half|Header|FreeKick|OwnGoal|Shot|Exactly/gi, function (matched) {
                return mapObj[matched];
            })

            return result
        } else {
            return nom.toString()
        }
    }

    const getBet = (betId, betName) => {
        if (oddsObj) {

            const newArr = () => {
                if (oddsObj !== null) {
                    return oddsObj.data.bets.map((v, i) => v)
                }
                else {
                    return null
                }
            }

            function search(idKey, myArray) {
                for (var i = 0; i < myArray.length; i++) {
                    if (myArray[i].id === idKey) {
                        if (true) {
                            const oldArr = myArray[i]
                            const newArr = []
                            for (const elem of oldArr.values) {

                                if (!elem.value.includes('.25') && !elem.value.includes('.75')) {
                                    newArr.push(elem)
                                }

                            }
                            return {
                                id: idKey,
                                name: myArray[i].name,
                                values: newArr
                            }
                        } else {
                            return myArray[i]
                        }

                    }
                }
            }

            const betArrayIdList = () => {
                if (oddsObj) {
                    return oddsObj.data.bets.map(x => x.id)
                }
                else {
                    return null
                }
            }
            const test = search(betId, newArr())

            if (betArrayIdList().includes(betId) && betId !== 50 && betId !== 72 && test.values.length < 4) {
                return test.values.map((v, i) =>
                    <div className='is-flex is-justify-content-space-between'>
                        <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between">
                            <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                sport: 'B',
                                fixtureId: game,
                                country:matchObj.data[0].country.name,
                                league:matchObj.data[0].league.name,
                                status:'pending',
                                homeTeam: matchObj.data[0].teams.home.name,
                                awayTeam: matchObj.data[0].teams.away.name,
                                timestamp: matchObj.data[0].timestamp,
                                betName: bet_list_basketball.response.find(x => x.id == test.id).name,
                                betChoose: parseName(v.value),
                                cote: v.odd,
                                amount: 0
                            }, game)}>
                                <div class="control" style={{ width: '100px' }}>
                                    <input className="input is-small" type="text" value={parseName(v.value, betId)} width='10px' readOnly style={getInputStyle(game, { betName: bet_list_basketball.response.find(x => x.id == test.id).name, betChoose: parseName(v.value) })} />
                                </div>
                                <div class="control is-small">
                                    <a className="button is-primary is-small" href="#">
                                        {v.odd}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="is-hidden-desktop is-flex ">
                            <div class="field has-addons" onClick={() => addBet({
                                sport: 'B',
                                fixtureId: game,
                                country:matchObj.data[0].country.name,
                                league:matchObj.data[0].league.name,
                                status:'pending',
                                homeTeam: matchObj.data[0].teams.home.name,
                                awayTeam: matchObj.data[0].teams.away.name,
                                timestamp: matchObj.data[0].timestamp,
                                betName: bet_list_basketball.response.find(x => x.id == test.id).name,
                                betChoose: parseName(v.value),
                                cote: v.odd,
                                amount: 0
                            }, game)}>
                                <div class="control" style={{ width: '50px' }}>
                                    <input className="input is-small" type="text" value={parseName(v.value, betId)} width='10px' readOnly style={getInputStyle(game, { betName: bet_list_basketball.response.find(x => x.id == test.id).name, betChoose: parseName(v.value) })} />
                                </div>
                                <div class="control is-small">
                                    <a className="button is-primary is-small" href="#">
                                        {v.odd}
                                    </a>
                                </div>
                            </div>

                        </div>
                    </ div>
                )

            }
            else if (betArrayIdList().includes(betId) && betId !== 50 && betId !== 72) {
                return test.values.map((v, i) =>
                    <>
                        <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                            <div class="field has-addons px-1
                 is-flex-grow-1" onClick={() => addBet({
                                sport: 'B',
                                fixtureId: game,
                                country:matchObj.data[0].country.name,
                                league:matchObj.data[0].league.name,
                                status:'pending',
                                homeTeam: matchObj.data[0].teams.home.name,
                                awayTeam: matchObj.data[0].teams.away.name,
                                timestamp: matchObj.data[0].timestamp,
                                betName: bet_list_basketball.response.find(x => x.id == test.id).name,
                                betChoose: parseName(v.value),
                                cote: v.odd,
                                amount: 0
                            }, game)}>
                                <div class="control" style={{ width: '80px' }}>
                                    <input className="input is-small" type="text" value={parseName(v.value, betId)} width='10px' readOnly style={getInputStyle(game, { betName: bet_list_basketball.response.find(x => x.id == test.id).name, betChoose: parseName(v.value) })} />
                                </div>
                                <div class="control is-small">
                                    <a className="button is-primary is-small" href="#">
                                        {v.odd}
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="is-hidden-desktop is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                            <div class="field has-addons px-1
                 is-flex-grow-1" onClick={() => addBet({
                                sport: 'B',
                                fixtureId: game,
                                country:matchObj.data[0].country.name,
                                league:matchObj.data[0].league.name,
                                status:'pending',
                                homeTeam: matchObj.data[0].teams.home.name,
                                awayTeam: matchObj.data[0].teams.away.name,
                                timestamp: matchObj.data[0].timestamp,
                                betName: bet_list_basketball.response.find(x => x.id == test.id).name,
                                betChoose: parseName(v.value),
                                cote: v.odd,
                                amount: 0
                            }, game)}>
                                <div class="control" style={{ width: '80px' }}>
                                    <input className="input is-small" type="text" value={parseName(v.value, betId)} readOnly style={getInputStyle(game, { betName: bet_list_basketball.response.find(x => x.id == test.id).name, betChoose: parseName(v.value) })} />
                                </div>
                                <div class="control is-small">
                                    <a className="button is-primary is-small" href="#">
                                        {v.odd}
                                    </a>
                                </div>
                            </div>

                        </div>
                    </>
                )
            }
            else {
                return <div className="is-flex is-flex-wrap-wrap p-4">
                    <div class="field has-addons px-1 is-flex-grow-1">
                        <div class="control is-small">
                            <input className="input is-small" type="text" value='Non Disponible' disabled />
                        </div>
                        <div class="control is-small">
                            <a className="button is-primary is-small" href="#">
                                <MdSecurity />
                            </a>
                        </div>
                    </div>

                </div>
            }
        }
    }

    const renderBet = () => {

        if (oddsObj === null || matchObj === null) {
            // return <div className='is-flex is-justify-content-center mt-4 pb-4'>
            //     <Spinner color='#FFC900' />
            // </div>
            return void (0)
        }
        return (
            <>
                <div className='card my-2 py-1 mx-2' >
                    <div className="mt-2 px-2 is-flex is-justify-content-center">
                        <button class="button is-white" onClick={() => toggleTabsCss('tout')}><p className={style.tout}> <BsFillCircleFill /> Tout</p></button>
                        {/* <button class="button is-white" onClick={() => toggleTabsCss('resultat')}><p className={style.resultat}><GiTrophy /> Resultat</p></button>
                        <button class="button is-white" onClick={() => toggleTabsCss('buts')}><p className={style.buts}><IoBasketball /> Points</p></button>
                        <button class="button is-white" onClick={() => toggleTabsCss('stats')}><p className={style.stats}><BiStats /> Stats</p></button> */}
                    </div>

                </div>

                <div className='px-2 pb-4'>
                    {
                        oddsArray.map((v, i) =>
                            <div className="mt-2">

                                <div className="card mt-2">
                                    <header className="card-header" style={{ backgroundColor: '#FFC900' }}>
                                        <p className="ml-2 has-text-dark">
                                            {v.name}
                                        </p>
                                    </header>
                                    <div className="card-content is-flex is-flex-wrap-wrap is-justify-content-space-between" style={{ backgroundColor: '#ffecb3' }}>
                                        {
                                            getBet(v.id, v.name)
                                        }
                                    </div>
                                </div>

                            </div>
                        )
                    }
                </div>
            </>
        )
    }

    const renderPanel = () => {

        const topRight = () => {
            if (matchObj.data[0].league.round) {
                return matchObj.data[0].league.round
            } else {
                return matchObj.data[0].league.season
            }
        }
        if (matchObj !== null) {

            // return <div class="mt-4 columns is-mobile">
            //     <div class="column has-text-centered">1</div>
            //     <div class="column  has-text-centered">2</div>
            //     <div class="column  has-text-centered">3</div>
            // </div>

            return <div className="mt-4">
                <div className="card mt-4 mx-2 pb-2" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover' }}>

                    <div className="header is-primary" style={{ backgroundColor: '#198754' }}>
                        <div className='is-flex px-2 has-text-light'>
                            <div className='is-flex'>
                                <div className='mt-1'><GiBasketballBall /></div>
                                <p className='ml-2'><small> {matchObj.data[0].country.name} - {matchObj.data[0].league.name} </small></p>
                            </div>
                            <div className='ml-auto'>
                                <p><small>{topRight()}</small></p>
                            </div>
                        </div>
                    </div>


                    <div class="mt-4 columns is-mobile" style={{ color: 'white' }}>
                        <div class="column has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(${matchObj.data[0].teams.home.logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' style={{ textShadow: 'black 2px 0 30px' }}>{matchObj.data[0].teams.home.name}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div>
                                <div className='is-flex is-justify-content-center'>   <p className='is-size-2 has-text-centered' >{dayjs.unix(matchObj.data[0].timestamp).format('h:mm A')}</p> </div>
                                <div className='is-flex is-justify-content-center'>   <p className='has-text-centered' >{dayjs.unix(matchObj.data[0].timestamp).format('D MMMM YYYY')}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(${matchObj.data[0].teams.away.logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' >{matchObj.data[0].teams.away.name}</p> </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='is-flex is-justify-content-space-between px-4 mt-4' style={{ color: 'white' }} >
                        <div>
                            <div style={{ width: '64px', height: '64px', backgroundImage: `url(${matchObj.data[0].teams.home.logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                            <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' style={{ textShadow: 'black 2px 0 30px' }}>{matchObj.data[0].teams.home.name}</p> </div>
                        </div>

                        <div>
                            <div className='is-flex is-justify-content-center'>   <p className='is-size-2 has-text-centered' >{dayjs.unix(matchObj.data[0].timestamp).format('h:mm A')}</p> </div>
                            <div className='is-flex is-justify-content-center'>   <p className='has-text-centered' >{dayjs.unix(matchObj.data[0].timestamp).format('D MMMM YYYY')}</p> </div>
                        </div>

                        <div>
                            <div style={{ width: '64px', height: '64px', backgroundImage: `url(${matchObj.data[0].teams.away.logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                            <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' >{matchObj.data[0].teams.away.name}</p> </div>
                        </div>
                    </div> */}

                </div>
            </div>
        } else {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>
        }
    }

    return (
        <div className='pt-2' style={{ backgroundColor: '#141E27' }}>

            {
                renderPanel()
            }



            {/* <div className="mt-2">
                <p className="has-text-centered">{odds.response.map((val, i) => val.league.name)} <img src={odds.response.map((val, i) => val.league.logo)} alt={odds.response.map((val, i) => val.league.name)} width='32px' height='32px' /> </p>
                <p className="has-text-centered is-size-5"> <img src={match.response.map((val, i) => val.teams.home.logo)} alt={match.response.map((val, i) => val.teams.home.name)} width='24px' height='24px' />&nbsp;{Home} vs {Away}&nbsp;<img src={match.response.map((val, i) => val.teams.away.logo)} alt={match.response.map((val, i) => val.teams.away.name)} width='24px' height='24px' /></p>
                <p className="has-text-centered">{match.response.map((val, i) => <><Moment format="DD/MM/YYYY">{val.fixture.date}</Moment> - <Moment format="hh:mm">{val.fixture.date}</Moment></>)}</p>
                <p className="has-text-centered is-size-4"><a class="button" onClick={() => alert('test')}> <MdBarChart /> Stats </a></p>
            </div> */}
            {/* <div id="wg-api-football-fixture"
                data-host="v3.football.api-sports.io"
                data-refresh="60"
                data-id="731882"
                data-key="9639bb14849d23270b37fdddf7cd292a"
                data-theme=""
                data-show-errors="true"
                class="api_football_loader">
            </div> */}


            {
                renderBet()
            }
            {/*             
            <div hidden>
                {
                    odds.response.map((val, i) =>
                        <div className="mt-2">
                            <div className="box">
                                {
                                    val.bookmakers.map((val, i) =>
                                        val.bets.map((val, i) =>
                                            <div clasName="card mt-2">
                                                <header className="card-header">
                                                    <p className="card-header-title">
                                                        {i + 1 + "-" + val.name}
                                                    </p>
                                                </header>

                                                <div className="card-content">
                                                    <div className="is-flex is-flex-wrap-wrap">
                                                        {
                                                            val.values.map((val, id) =>
                                                                <div class="field has-addons px-1
                                                             is-flex-grow-1">
                                                                    <div class="control">
                                                                        <input className="input is-small" type="text" value={parseName(val.value)} readOnly />
                                                                    </div>
                                                                    <div class="control is-small">
                                                                        <a className="button is-primary is-small" href="#">
                                                                            {val.odd}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                    )
                }
            </div> */}
        </div>
    )
}

export default OddsListBasket
