import React from "react";
import Spinner from "react-activity/dist/Spinner";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Loader = () => {
    return (
        <>
            <div  className="is-flex is-justify-content-center mt-2">
                <Spinner color="black" size={32} speed={1} animating={true}  />
            </div>
        </>
    )
}

const MySwal = withReactContent(Swal)

const openLoad = () => {
    MySwal.fire({
        html: <div style={{zIndex:'900', overflow:'hidden', overflowX:'hidden', overflowY:'hidden', height:'100vh',}} className="is-flex is-align-items-center is-justify-content-center"><Loader/></div>,
        showCloseButton: false,
        showConfirmButton: false,
        background:'none',
        allowOutsideClick:false,
    })
}

const closeLoader = ()=>{

    MySwal.close()
}

export {openLoad, closeLoader}