
export const saveToken = (id, token) => {
    localStorage.setItem('pjparyaj.com_login_data_id', id)
    localStorage.setItem('pjparyaj.com_login_data_token', token)
}

export const getToken = () => {
    return {
        id: localStorage.getItem('pjparyaj.com_login_data_id')|| 0,
        token: localStorage.getItem('pjparyaj.com_login_data_token')
    }
}

export const logout = () => {
    localStorage.removeItem('pjparyaj.com_login_data_id')
    localStorage.removeItem('pjparyaj.com_login_data_token')
}

