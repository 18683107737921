import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BetReducer } from './reducers/bets_reducers';
import { addBetReducer } from './reducers/bets_reducers';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  RecoilRoot,
} from 'recoil';
import { BrowserRouter } from "react-router-dom";
import  RecoilOutside  from "recoil-outside";





ReactDOM.render(
  <RecoilRoot>
    <RecoilOutside />
    <BrowserRouter >
      <App />
    </BrowserRouter >
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
