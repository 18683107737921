import React, { useState, useEffect } from 'react'
import { MdLock } from 'react-icons/md';
import { getSingleOdd, getSingleMatch } from '../functions/api';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'react-activity/dist/Spinner';
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState, isNextClickState } from '../store/bets_store';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import img from '../assets/images/back2.jpg'
import { getPaysByCC, getSportsList, groupBy, renderCote } from '../functions/utils';
import * as dayjs from 'dayjs'
import AddBetComponent from '../functions/bet';
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')


function SportOddsList(props) {

    const [resultLength, setResultLength] = useState(0)
    const [oddsObj, setOddsObj] = useState(null)
    const [matchObj, setMatchObj] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()


    const getGame = () => {

        if (parseInt(searchParams.get("game")) > 0) {
            return searchParams.get("game")
        }
        return 0
    }

    const game = getGame()

    useEffect(() => {
        if (game) {
            getSingleOdd(setOddsObj, setResultLength, game, props.id)
            getSingleMatch(props.id, game, setMatchObj)
        }
    }, [game]);

    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const parseName = (nom, id) => {

        //return nom

        if (true) {

            RegExp.quote = function (str) {
                return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }

            let h = new RegExp(RegExp.quote(matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name), "g");
            let a = new RegExp(RegExp.quote(matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name), "g");
            let s = new RegExp('/', "g");
            const name = nom.toString().replace(h, "1").replace(a, "2").replace(s, "")


            const mapObj = {
                Home: '1',
                Away: '2',
                Draw: 'X',
                // Over: "superieur à",
                // Under: "inferieur à",
                Over: ">",
                Under: "<",
                Odd: "impair",
                Even: "pair",
                more: "",
                by: " -> ",
                "NO GOALS": '0',
                "o/yes": "oui >",
                "u/yes": "oui <",
                "o/no": "non >",
                "u/no": "non <",
                "Score Draw": 'pas de but',
                Yes: "oui",
                No: "non",
                "1st Half": '1er',
                "First": '1er',
                "Last": 'dernier',
                "2nd Half": '2eme',
                Header: 'Tete',
                FreeKick: 'Coup Franc',
                OwnGoal: 'Contre son camp',
                Shot: 'Tir',
                Exactly: "",
                Goal: 'but',
                before: 'avant',
                after: 'apres',
                "Full Time": '90',
                goal: 'but',
                Tie: 'X',
                " or ": '/',
                Anytime: '',
                Only: 'uniquement',
                "Both Teams": 'Les deux equipes',
                "Free Kick": 'Coup franc',
                "Own Goal": "Contre son camp",
                "More": 'plus',
                "Starting Players": '',
                "Substitutes": '',
                "No Goalscorer": 'pas de buteur',
                "Outside the Box": 'Dehors du carré',
                'Cards': ' carton'

            }

            const result = name.replace(/Home|Away|Draw|Over|Under|Odd|Even|more|by|o\/yes|u\/yes|o\/no|u\/no|Score Draw|Yes|No|1st Half|2nd Half|Header|FreeKick|OwnGoal|Shot|Exactly|First|Last|Goal|before|after|Full Time|goal|Tie|NO GOALS| or |Anytime|Only|Both Teams|Free Kick|Own Goal|More|Starting Players|Substitutes|No Goalscorer|Outside the Box|Cards/gi, function (matched) {
                return mapObj[matched];
            })

            return result
        } else {
            return nom.toString()
        }
    }


    const getBet = (betId, betName, arr) => {
        if (oddsObj) {
            if (arr.length < 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    for (const elem of theArr) {
                        theArr2.push(arr.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arr.filter(x => x.header == v).map((v, i) => <th><div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                            sport: props.abr,
                                            fixtureId: game,
                                            homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                            awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                            timestamp: matchObj.data[0].time,
                                            betName: betName,
                                            betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`),
                                            cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                            amount: 0,
                                            data: { ...v, id: betId }
                                        }, game)}>
                                            <div class="control" style={{ width: '100px' }}>
                                                <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                            </div>
                                            <div class="control is-small">
                                                <a className="button is-primary is-small" href="#">
                                                    {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                                </a>
                                            </div>
                                        </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <div className='is-flex is-justify-content-space-between'>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between">
                                <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                    sport: props.abr,
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '100px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="is-hidden-desktop is-flex ">
                                <div class="field has-addons" onClick={() => addBet({
                                    sport: props.abr,
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '50px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </ div>
                    )

                }
            }
            else if (arr.length >= 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    theArr = theArr.map((v, i) => {
                        if (v == 'undefined') {
                            return 'autre'
                        } else {
                            return v
                        }
                    })



                    let arrNew = arr.map((v, i) => {
                        if (!v.header) {
                            return { ...v, header: 'autre' }
                        } else {
                            return v
                        }
                    })

                    for (const elem of theArr) {
                        theArr2.push(arrNew.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arrNew.filter(x => x.header == v).map((v, i) => <th className='is-flex is-flex-wrap-wrap p-1 is-justify-content-center'>
                                            <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                                sport: props.abr,
                                                fixtureId: game,
                                                homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                                awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                                timestamp: matchObj.data[0].time,
                                                betName: betName,
                                                betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`),
                                                cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                                amount: 0,
                                                data: { ...v, id: betId }
                                            }, game)}>
                                                <div class="control" style={{ width: '100px' }}>
                                                    <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap ? v.handicap + ' ' : '' || ''}${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                                </div>
                                                <div class="control is-small">
                                                    <a className="button is-primary is-small" href="#">
                                                        {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                                    </a>
                                                </div>
                                            </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: props.abr,
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className="is-hidden-desktop is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: props.abr,
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: v.odds > 1.01 ? renderCote(v.odds): null,
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </>
                    )
                }
            }
            else {
                return <div className="is-flex is-flex-wrap-wrap p-4">
                    <div class="field has-addons px-1 is-flex-grow-1">
                        <div class="control is-small">
                            <input className="input is-small" type="text" value='Non Disponible' disabled />
                        </div>
                        <div class="control is-small">
                            <a className="button is-primary is-small" href="#">
                                <MdLock />
                            </a>
                        </div>
                    </div>

                </div>
            }
        }
    }

    const renderBet = () => {

        if (oddsObj === null || matchObj === null) {
            return void (0)
        }

       //return <p className='has-text-light'> {JSON.stringify(oddsObj.data.map(v => { return { name: v.name, id: v.id } }))}</p>
        return (
            <>
                <div className='px-2 pb-4'>
                    <div className='has-text-light'>
                    </div>
                    {
                        getSportsList(props.id).map((v, i) => {
                            if (oddsObj.data.find(x => x.id == v.id)) {
                                return <div className="mt-2" style={{ fontSize: '13.5px' }}>

                                    <div className="card mt-2">
                                        <header className="card-header" style={{ backgroundColor: 'white' }}>
                                            <p className="ml-2 has-text-dark">
                                                {v.fr || v.name}
                                            </p>
                                        </header>
                                        {/* // */}
                                        <div className="card-content is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between" style={{ backgroundColor: 'white' }}>
                                            {
                                                getBet(v.id, v.fr || v.name, v.render ? v.render(oddsObj.data.find(x => x.id == v.id).odds) : oddsObj.data.find(x => x.id == v.id).odds.map((v, i) => { return { ...v, headerShown: true } }))
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                        }
                        )
                    }
                </div>
            </>
        )
    }

    const renderPanel = () => {
        if (matchObj !== null) {

            //return JSON.stringify(matchObj)

            return <div className="">
                <div className="card mx-2 pb-2" style={{ backgroundColor:'transparent' , backgroundSize: 'cover' }}>

                    <div className="header is-primary" style={{ backgroundColor: 'yellow' }}>
                        <div className='is-flex px-2 has-text-dark'>
                            <div className='is-flex'>
                                <div className='mt-1'>{props.icon}</div>
                                <p className='ml-2'><small> {getPaysByCC(matchObj.data[0].league.cc)} - {matchObj.data[0].league.name} </small></p>
                            </div>
                            <div className='ml-auto'>
                                <p><small>{'2023'}</small></p>
                            </div>
                        </div>
                    </div>



                    <div className='is-flex is-justify-content-space-between px-4 mt-4' style={{ color: 'white' }} >
                        <div class="column has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].home.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' style={{ textShadow: 'black 2px 0 30px' }}>{matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div>
                                <div className='is-flex is-justify-content-center'>   <p className='is-size-4 has-text-centered' >{dayjs.unix(matchObj.data[0].time).format('h:mm A')}</p> </div>
                                <div className='is-flex is-justify-content-center'>   <p className='has-text-centered' >{dayjs.unix(matchObj.data[0].time).format('D MMMM YYYY')}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].away.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' >{matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name}</p> </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        } else {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>
        }
    }

    return (
        <div className='' style={{ backgroundColor: '#252f5a' }}>

            {
                renderPanel()
            }

            {
                renderBet()
            }
        </div>
    )
}

export default SportOddsList
