import React from 'react'
import Column from '../components/column'
import Desktop from '../components/desktop'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import LeftPane from '../components/left-pane'
import RightPane from '../components/right-pane'
import LoginForm from '../components/login'


function Login() {
    return (
        <>
            <Navbar></Navbar>
            
            <div className="mx-2">
                <div className="is-hidden-touch">
                <div>
            <div>
                <div className="columns">
                    <div
                        className="column is-3"
                         style={{ backgroundColor: '#252f5a' }}
                    >
                        <LeftPane sport='1' path='football'></LeftPane>
                    </div>
                    <div className="column is-6">
                        <LoginForm></LoginForm>
                    </div>
                    <div
                        className="column is-3"
                         style={{ backgroundColor: '#252f5a' }}
                    >
                        <RightPane></RightPane>
                    </div>
                </div>
            </div>

        </div>
                </div>
                <div className="is-hidden-desktop">
                <LoginForm></LoginForm>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Login
