/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useState, useEffect, useReducer } from 'react';
//import { countries } from '../assets/data/countries'
import { getCompetitionsBycountry, getTopCompetitions } from '../functions/api';
import { getcountries } from '../functions/api';
import Spinner from 'react-activity/dist/Spinner';
//import { MdArrowDropDownCircle } from 'react-icons/md';
import { IoMdArrowDroprightCircle, IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
//import { useQuery } from '../functions/api';
import { useRecoilState } from 'recoil'; 
import { cartTypeState, isNextClickState } from '../store/bets_store';
import iziToast from 'izitoast/dist/js/iziToast'
import BarVertical from './bar_vertical';
import { pays } from '../assets/data/pays';


const Country = (props) => {

    const [_dropdown, set_dropdown] = useState({ state: 'normal', listHide: true, value: <IoMdArrowDropdownCircle color='#FAC213' size={'20'} /> });
    const [_competitionByCountry, set_competitionByCountry] = useState();
    const value = props.value
    const nav = useNavigate()
    //  const index = props.index


    const toggleDropdown = (sport, country) => {
        if (_dropdown.state === 'normal') {

            getCompetitionsBycountry(sport, country, set_competitionByCountry)
            set_dropdown({
                state: 'dropped',
                listHide: false,
                value: <IoMdArrowDropupCircle color='#FAC213' size={'20'} />
            })


        }

        else {
            set_dropdown({
                state: 'normal',
                listHide: true,
                value: <IoMdArrowDropdownCircle color='#FAC213' size={'20'} />
            })

        }


    }

    const renderCompetitionByCountry = () => {
        if (_competitionByCountry && props.sport) {

            return _competitionByCountry.data.map((value, index) => {
                return (
                    <div className=" mt-1 is-flex" style={{ backgroundColor: '#207d09' }} onClick={() => nav('/sports/'+props.path+'?league=' + value.id)}>
                        <p className="p-2 "></p>
                        <p className="p-2 has-text-centered has-text-light"><b>{value.name}</b></p>
                        <p className='ml-auto pr-2 my-2'><IoMdArrowDroprightCircle color='#FAC213' size={'20'} /></p>
                    </div>
                )
            })
            // return JSON.stringify(_competitionByCountry)
        }
        else {
            return <div className='is-flex is-justify-content-center'><Spinner /></div>
        }

    }


    return (
        <div className=" mt-1" style={{ backgroundColor: '' }}>
            <div className="mt-1 is-flex">
                <p className="p-2 "></p>
                <p className="p-2 has-text-centered">{value.name}</p>
                <p className='ml-auto pr-2 my-2' onClick={() => toggleDropdown(props.sport, value.cc)}>{_dropdown.value}</p>
            </div>
            <div hidden={_dropdown.listHide} style={{ borderTop: '1px solid gray', backgroundColor: '#252f5a' }}>
                {
                    renderCompetitionByCountry()
                }
            </div>

        </div>

    )
}

function LeftPane(props) {

    const navigate = useNavigate()


    const [comp, setComp] = useState()
    const [countries, setCountries] = useState(pays)
    const [topCompetition, setTopCompetition] = useState()
    const [isNextClick, setIsNextClick] = useRecoilState(isNextClickState)


    const sport = props.sport || '1'



    useEffect(() => {
        getTopCompetitions(sport , setTopCompetition)
        getcountries(sport, setCountries)
    }, [sport]);



    const handleNavigate = (link) => {
        if (isNextClick === false) {
            navigate(link)
        } else {
            iziToast.show({
                title: 'Message',
                message: 'Veuillez attendre la fin du chargement .',
                color: 'green',
                position: "bottomLeft",
                backgroundColor: '#e5ae35',
                titleColor: 'black',
                messageColor: 'white'
            });
        }
    }
    const [allComps, setAllComps] = useState({
        hidden: true,
        class: "-"
    })
    const [topComps, setTopComps] = useState({
        hidden: false,
        class: "is-active"
    })

    const togglePane = (payload) => {

        if (payload === "all") {
            setAllComps({
                hidden: false,
                class: "is-active"
            })
            setTopComps({
                hidden: true,
                class: "-"
            })

        }
        else {
            setTopComps({
                hidden: false,
                class: "is-active"
            })
            setAllComps({
                hidden: true,
                class: "-"
            })
        }
    }

    const renderCountries = () => {
        if (!countries) {
            return 'load...'
        } else {
            return pays.results.map((value, index) => {
                return <Country sport={sport} value={value} path={props.path} index={index} />
            })

            // return JSON.stringify(countries)
        }
    }

    const renderName = (name) => {
        if (name && name.length > 25) {
            return name.slice(0, 25) + '...'
        }
        return name
    }


    const rendertopCompetition = () => {
        if (!topCompetition) {
            return <div className='is-flex is-justify-content-center mt-2'> <Spinner /> </div>
        } else {
            if (topCompetition) {
                return topCompetition.data.map((value, index) => {
                    return (
                        <div className="mt-1 is-flex" onClick={() => {
                            setIsNextClick(false)
                            handleNavigate('/sports/'+props.path+'?league=' + value.id)
                            // history.go(0)
                        }}>
                            <p className="p-2 "> </p>
                            <p className="p-2 has-text-centered help"><b>{renderName(value.name)}</b></p>
                            <p className='ml-auto pr-2 my-2'><IoMdArrowDroprightCircle color='#FAC213' size={'20'} /></p>
                        </div>
                    )
                })
                //return JSON.stringify(comp)
            } else {
                return topCompetition.data.map((value, index) => {
                    return (
                        <div className="mt-1 is-flex" onClick={() => {
                            handleNavigate('?league=' + value.id)
                            // history.go(0)
                        }} >
                            <p className="p-2 "></p>
                            <p className="p-2 has-text-centered help"><b>{renderName(value.name)}</b></p>
                            <p className='ml-auto pr-2 my-2'><IoMdArrowDroprightCircle color='#FAC213' size={'20'} /></p>
                        </div>
                    )
                })

                // return JSON.stringify(comp)
            }
        }

    }



    return (
        <div className="card pr-2" style={{ backgroundColor: '#252f5a', color:'white' }}>
            <div className='columns is-mobile'>
                <div className='column is-one-fifth' style={{ backgroundColor: '#FAC213' }}>
                    <BarVertical />
                </div>
                <div className="column auto p-2">
                    <div style={{ backgroundImage: "linear-gradient(#FAC213, #FAC213)", color: 'white' }}>
                        <p className='has-text-centered has-text-dark p-2'><b>COMPETITIONS </b></p>
                    </div>
                    <div className="head mt-2">
                        <div class="tabs is-centered is-primary has-text-light">
                            <ul>
                                <li className={topComps.class}><a onClick={() => togglePane('top')} className='' style={{ color: 'white' }}>Top</a></li>
                                <li className={allComps.class}><a onClick={() => togglePane('all')} className='' style={{ color: 'white' }}>Toutes</a></li>
                            </ul>
                        </div>

                    </div>
                    <div className="body mt-2" style={{ fontSize: '13.5px' }}>
                        <div hidden={topComps.hidden} style={{ overflowY: 'auto', height: '200vh' }}>

                            {
                                rendertopCompetition()
                            }

                            {/* {
                                JSON.stringify(topCompetition)
                            } */}

                        </div>
                        <div hidden={allComps.hidden} style={{ overflowY: 'auto', height: '150vh' }}>
                            {
                                renderCountries()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftPane
