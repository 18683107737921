/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Slider from './slider';
//import { fixtures } from '../assets/data/fixtures'
import { oddsdata } from '../assets/data/fixtures';
import Moment from 'react-moment';
import { IconName } from "react-icons/gi";
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState } from '../store/bets_store';
import { getDailyFootballFixtures } from '../functions/api';
import { useQuery } from '../functions/api';
import { closeLoader } from './fullscreen_loader';
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')


// const getOdds = (fixtureId, date) => {




//     const getTeams = async (fixtureId) => {
//         const baseUrl = 'https://v3.football.api-sports.io/'


//         const req = await axios.get(baseUrl + 'fixtures', {
//             headers: {
//                 'x-rapidapi-host': 'v3.football.api-sports.io',
//                 'x-rapidapi-key': '9639bb14849d23270b37fdddf7cd292a'
//             },
//             params: {
//                 fixture: fixtureId
//             }
//         }).then(x => {
//             const data = x.data
//             const home = data.response[0].teams.home.name
//             const homeId = data.response[0].teams.home.id
//             const away = data.response[0].teams.away.name
//             const awayId = data.response[0].teams.away.id

//             return {
//                 home,
//                 homeId,
//                 away,
//                 awayId
//             }

//         })

//         return req
//     }

//     const fixture = fixtureId;

//     const home = {
//         id: getTeams(fixture).then(x => x.homeId),
//         name: getTeams(fixture).then(x => x.home),
//         cote: oddsdata.result.response[0].bookmakers[0].bets[0].values[0].odd
//     }
//     const away = {
//         id: getTeams(fixture).then(x => x.awayId),
//         name: getTeams(fixture).then(x => x.away),
//         cote: oddsdata.result.response[0].bookmakers[0].bets[0].values[2].odd
//     }



//     return {
//         fixture,
//         date,
//         home,
//         away
//     }

// }

// const arr = []
// oddsdata.result.response.forEach(x => arr.push(getOdds(x.fixture.id, x.fixture.date)))


function Middle() {

    const [fixtures, setFixtures] = useState(null);
    const [fix, setFix] = useState('load');
    const [text, setText] = useRecoilState(textState)
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [page, setPage] = useState(1);
    const [resultLength, setResultLength] = useState(0)
    let query = useQuery()
    const name = query.get('name')

    useEffect(() => {
        getDailyFootballFixtures(page, setFixtures, closeLoader, setResultLength)

    }, []);



    // const betsArr = [
    //     {
    //         fixture: 'iiiii',
    //         date: Date.now(),
    //         home: {
    //             id: 'kgmgmg',
    //             name: 'jgnbgn',
    //             cote: 'hghfn'
    //         },
    //         away: {
    //             id: 'kgmgmg',
    //             name: 'jgnbgn',
    //             cote: 'hghfn'
    //         },


    //     },

    // ]

    const verifBetInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });


        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    // const verifFixtureAndBetNameInCart = (id, betName) => {

    //     const found = text.some(el => {
    //         if (el.fixtureId === id && el.betName === betName) {
    //             return true;
    //         } else {
    //             return false
    //         }
    //     });

    //     if (found) {
    //         return true

    //     } else {
    //         return false
    //     }
    // }

    const verifFixtureAndBetNameAndBetChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameAndBetNonChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose !== betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const removeFromCart = (id, betName, betChoose) => {

        text.some((el, index) => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                let arr = [...text]
                arr.splice(index, 1)
                setText(arr)
                return true
            } else {
                return false
            }
        });
    }

    const addBet = (payload, id) => {
        if (!verifFixtureInCart(id, payload.betName)) { //si match la pa egziste

            if (!verifBetInCart(id, payload.betName, payload.betChoose)) { //si match la pa egziste e si pari a pa egziste

                setText([...text, {
                    sport: payload.sport,
                    fixtureId: payload.fixtureId,
                    homeTeam: payload.homeTeam,
                    awayTeam: payload.awayTeam,
                    betName: payload.betName,
                    betChoose: payload.betChoose,
                    cote: payload.cote,
                    amount: 0
                }])
            } else { // si match la pa egziste men pari a egziste
                //removeFromCart(id, payload.betName, payload.betChoose)
            }
        } else if (verifFixtureAndBetNameAndBetChooseInCart(id, payload.betName, payload.betChoose)) { // si match la egziste

            removeFromCart(id, payload.betName, payload.betChoose)
        }
        else if (verifFixtureAndBetNameAndBetNonChooseInCart(id, payload.betName, payload.betChoose)) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0
            }])
        }
    }

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#FFC900', color: 'black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    // const paginate = (payload) => {

    //     //alert(resultLength)

    //     if (payload === 'prev') {
    //         if (page > 1) {
    //             openLoad()
    //             setPage(page - 1)

    //         }
    //     } else {
    //         if (basketFixtures.data.length >= 1) {
    //             openLoad()
    //             setPage(page + 1)
    //         }

    //     }
    // }

    const renderView = () => {



        //oddsdata.result.response[0].fixture.id
        if (fixtures === null) {
            return JSON.stringify(fix)
        }

        else {

            // const theArr = []
            // fixtures.data.response.forEach(e => {

            //     if (Math.round(new Date() / 1000) - e.fixture.timestamp < 0) {
            //         theArr.push(e)
            //     }

            // })

            return (


                <div>
                    <Slider></Slider>
                    <div className="mt-2">


                        <div>
                            <p>
                                <b> Football - Top </b>{" "}
                            </p>
                        </div>

                        <div className="mt-4">

                            <div>


                                {
                                    fixtures.data.map((val, i) => {
                                        return (
                                            <div className="mt-2">
                                                <div className="card p-2" style={{ backgroundColor: '#FEF7DC'}}>
                                                    <p className="is-flex">
                                                        <b>{val.teams.home.name} x {val.teams.away.name} &nbsp; </b> - {val.league.name}  <span className="ml-auto"> {dayjs(val.fixture.date).format('dddd, D MMMM YYYY, h:mm A')} </span>
                                                    </p>
                                                    <div className="is-flex">

                                                        <div class="field has-addons" onClick={() => addBet({
                                                            sport: 'F',
                                                            fixtureId: val.fixture.id,
                                                            homeTeam: val.teams.home.name,
                                                            awayTeam: val.teams.away.name,
                                                            betName: 'Resultat du match',
                                                            betChoose: '1',
                                                            cote: 2,
                                                            amount: 0
                                                        }, val.fixture.id)}>
                                                            <div class="control">
                                                                <input className="input" type="text" value='1' readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                                            </div>
                                                            <div class="control">
                                                                <a className="button is-primary" >
                                                                    2
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <div class="field has-addons pl-2" onClick={() => addBet({
                                                            sport: 'F',
                                                            fixtureId: val.fixture.id,
                                                            homeTeam: val.teams.home.name,
                                                            awayTeam: val.teams.away.name,
                                                            betName: 'Resultat du match',
                                                            betChoose: 'X',
                                                            cote: 1.5,
                                                            amount: 0
                                                        }, val.fixture.id)}>
                                                            <div class="control">
                                                                <input className="input" type="text" value="X" readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat du match', betChoose: 'X' })} />
                                                            </div>
                                                            <div class="control">
                                                                <a className="button is-primary" >
                                                                    1.5
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <div class="field has-addons pl-2" onClick={() => addBet({
                                                            sport: 'F',
                                                            fixtureId: val.fixture.id,
                                                            homeTeam: val.teams.home.name,
                                                            awayTeam: val.teams.away.name,
                                                            betName: 'Resultat du match',
                                                            betChoose: '2',
                                                            cote: 5,
                                                            amount: 0
                                                        }, val.fixture.id)}>
                                                            <div class="control">
                                                                <input className="input" type="text" value='2' readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                                            </div>
                                                            <div class="control">
                                                                <a className="button is-primary" >
                                                                    5
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <div className=" is-flex pl-2">
                                                            <div>
                                                                +145
                                                            </div>
                                                            <div className="ml-1">
                                                                {' >'}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>



                        </div>

                    </div>
                    <div className="mt-4">
                        <nav className="pagination" role="navigation" aria-label="pagination">
                            <a className="pagination-previous is-disabled" title="This is the first page">Previous</a>
                            <a className="pagination-next">Next page</a>
                            <ul className="pagination-list">
                                <li>
                                    <a className="pagination-link is-current" aria-label="Page 1" aria-current="page">1</a>
                                </li>
                                <li>
                                    <a className="pagination-link" aria-label="Goto page 2">2</a>
                                </li>
                                <li>
                                    <a className="pagination-link" aria-label="Goto page 3">3</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )

        }
    }


    return renderView()
}

export default Middle
