/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import pjloto from '../assets/images/pjloto.png'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useRecoilState } from 'recoil';
import { borletteState, lotto3State, lotto4State, lotto5State, mariageState } from '../store/bets_store';
import axios from 'axios';
import { apiUrl } from '../constant';
import ModalComponent from './modalComponent';
import { getToken } from '../functions/auth';
import { MdShare } from 'react-icons/md';


const MySwal = withReactContent(Swal)


function BorletteNavbar({ cartPrice }) {

    const [isActive, setIsActive] = useState(false);
    const [borletteData, setBorletteData] = useRecoilState(borletteState);
    const [mariageData, setMariageData] = useRecoilState(mariageState);
    const [lotto3Data, setLotto3Data] = useRecoilState(lotto3State);
    const [lotto4Data, setLotto4Data] = useRecoilState(lotto4State);
    const [lotto5Data, setLotto5Data] = useRecoilState(lotto5State);

    const [elements, setElements] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const handleCloseModal = () => setIsOpen(false)
    const [lotri, setLotri] = useState(null);




    const toggleBurgerMenu = () => {
        setIsActive(!isActive);
    };

    const getAmount = () => {
        let amount = 0

        for (const elem of borletteData) {
            if (elem.number && elem.amount) {
                amount = amount + parseInt(elem.amount)
            }
        }
        for (const elem of mariageData) {
            if (elem.number1 && elem.number2 && elem.amount) {
                amount = amount + parseInt(elem.amount)
            }
        }
        for (const elem of lotto3Data) {
            if (elem.number && elem.amount) {
                amount = amount + parseInt(elem.amount)
            }
        }
        for (const elem of lotto4Data) {
            if (elem.number && elem.amount && elem.option) {
                amount = amount + parseInt(elem.amount)
            }
        }
        for (const elem of lotto5Data) {
            if (elem.number && elem.amount && elem.option) {
                amount = amount + parseInt(elem.amount)
            }
        }

        // alert (amount)
        return parseInt(amount)

    }

    return (
        <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/lottery">
                    <img src={pjloto}
                        width={125}
                        height={150}
                        alt=""
                    />
                </a>
                <div className="navbar-end ml-auto">
                    <div className='navbar-item is-hidden-desktop'>
                        <div className="buttons">
                            <a className="button is-info is-outlined is-small">
                                <strong style={{ fontSize: '10px' }}>{getAmount().toFixed(2)} HTG</strong>
                            </a>
                            <a className="button is-info is-outlined is-small">
                                <strong style={{ fontSize: '10px' }}><MdShare /></strong>
                            </a>

                            <a className="button is-dark is-outlined is-small" href="#" onClick={() => {
                                axios.post(apiUrl + 'api/v1/draw/get_available', {}).then((x) => {
                                    if (x.data.data) {
                                        setIsOpen(true)
                                        setElements(x.data.data)
                                    }
                                })

                            }}>
                                <strong style={{ fontSize: '10px' }}>Acheter</strong>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <a
                    role="button"
                    className={`is-hidden-touchnavbar-burger burger ${isActive ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    href="#"
                    onClick={toggleBurgerMenu}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a> */}


            </div>


            <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <a className="button is-info is-outlined">
                                <strong>Montant: {getAmount().toFixed(2)} HTG</strong>
                            </a>
                            <a className="button is-info is-outlined">
                                <strong>Partager FichKOD</strong>
                            </a>

                            <a className="button is-dark is-outlined" href="#" onClick={() => {
                                axios.post(apiUrl + 'api/v1/draw/get_available', {}).then((x) => {
                                    if (x.data.data) {
                                        setIsOpen(true)
                                        setElements(x.data.data)
                                    }
                                })

                            }}>
                                <strong>Acheter</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div >

            <ModalComponent elements={elements}
                closeModal={handleCloseModal}
                isOpen={isOpen} mise={getAmount()}
                bet={[{
                    borlette: borletteData.map((v, i) => { if (v.amount && v.number) { return v } else { return null } }).filter(v => v),
                    mariage: mariageData.map((v, i) => { if (v.amount && v.number1 && v.number2) { return v } else { return null } }).filter(v => v),
                    lotto3: lotto3Data.map((v, i) => { if (v.amount && v.number) { return v } else { return null } }).filter(v => v),
                    lotto4: lotto4Data.map((v, i) => { if (v.amount && v.number && v.option) { return v } else { return null } }).filter(v => v),
                    lotto5: lotto5Data.map((v, i) => { if (v.amount && v.number && v.option) { return v } else { return null } }).filter(v => v)
                }]}
                id={getToken().id} />
        </nav >
    );
};

export default BorletteNavbar