import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { GiSoccerBall } from 'react-icons/gi'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import { pays } from '../assets/data/pays';
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')


function Tickets(props) {

    const [data, setData] = useState({ results: [] })
    const [leagues, setLeagues] = useState([])


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        // const response = await axios.get('https://api.b365api.com/v1/league', {
        //     params: {
        //         sport_id: 1,
        //         // skip_esports: true,
        //         token: '102880-oc6ARf181BjN8j',
        //         // league_id: 172,
        //         // page: 1,
        //         // FI: '125896820C1A_1_3'
        //     }
        // })
        // //const parsed = JSON.parse(response.data)
        // const data = response.data
        // setData(data)


        const getLeague = async (cc) => {

            const obj = []

            for (const elem of pays.results) {

                const res = await axios.get('https://api.b365api.com/v1/league', {
                    params: {
                        sport_id: 1,
                        // skip_esports: true,
                        token: '102880-oc6ARf181BjN8j',
                        // league_id: 172,
                        // page: 1,
                        // FI: '125896820C1A_1_3',
                        cc: elem.cc
                    }
                })

                obj.push({ cc, data: res.data })

            }

            setLeagues(obj)
        }

       // getLeague()
    }, [])



    if (data.results && data.results[0]) {

        return (
            <>
                <div className='p-4 has-text-dark mt-6'>
                    {/* {data.results.map((v, i) => <div className="card p-2 m-2" style={{ backgroundColor: '#ffecb3', fontSize: '14px' }}>
                    <p className="is-flex">
                        <small ><b> <GiSoccerBall /> {v.home.name} </b>&nbsp; - &nbsp; <b> {v.away.name} &nbsp; </b></small>
                        <span className="ml-auto">{dayjs.unix(v.time).format('h:mm A')}</span>
                    </p>
                    <p className="is-flex">
                        <small className=''> {'getObject(val.id).league.name'} - {'getObject(val.id).league.country'}</small>
                        <span className="ml-auto"><small>{dayjs.unix(v.time).format('dddd, D MMMM YYYY')}</small></span>
                    </p>
                    <div className="is-flex is-justify-content-space-between">

                        <div class="field has-addons">
                            <div class="control">
                                <input className="input is-small" type="text" value='1' readOnly />
                            </div>
                            <div class="control">
                                <a className="button is-primary is-small" >
                                    {'renderCote(val.bets.values[0].odd)'}
                                </a>
                            </div>
                        </div>

                        <div class="field has-addons pl-2" >
                            <div class="control">
                                <input className="input is-small" type="text" value="X" readOnly />
                            </div>
                            <div class="control">
                                <a className="button is-primary is-small" >
                                    {'renderCote(val.bets.values[1].odd)'}
                                </a>
                            </div>
                        </div>

                        <div class="field has-addons pl-2">
                            <div class="control">
                                <input className="input is-small" type="text" value='2' readOnly />
                            </div>
                            <div class="control">
                                <a className="button is-primary is-small" >
                                    {'renderCote(val.bets.values[2].odd)'}
                                </a>
                            </div>
                        </div>

                        <div className=" is-flex pl-2">
                            <div>
                                <p style={{ fontSize: '15px' }}>{"+" + 'val.total'}</p>
                            </div>
                        </div>
                    </div>
                </div>)} */}

                    {/* <div className='box'>
                        <p>Sport :  </p>
                        <p>Name :  </p>
                        <p>Time :  </p>
                    </div> */}
                    {/* {data.results[0].filter(x => x.type == 'CL').map((v, i) =>
                        data.results[0].filter(x => x.type == 'CT' && x.ID == v.ID).map((val, ind) =>
                            data.results[0].filter(x => x.type == 'EV' && x.CT == val.NA).map((val2, ind2) =>
                                <div className='box'>
                                    <p>Sport : {v.NA} </p>
                                    <p>Competition: {val.NA}</p>
                                    <p>Name : {val2.NA}  </p>
                                    <p>Time : {val2.ID} </p>
                                </div>)))} */}
                    {/* {data.results[0].filter(x => x.type == 'EV' && x.TM).map((v, i) => v.NA).map(v => <p>{v}</p>)} */}
                    {/* {JSON.stringify(data.results[0].filter(x=> x.type == 'EV').map((v,i)=> v.NA).map(v => v))} */}
                    {/* {data.results[0].filter(x => x.type == 'PA').map((v, i) => <p>{JSON.stringify(v)}</p>)} */}
                    {/* {pays.results.map((v, i) => <p className='has-text-dark'>vvvv</p>)}
                    vvvv */}
                </div>
            </>
        )
    } else {
        return JSON.stringify(leagues)
    }
}

export default Tickets
