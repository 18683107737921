/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { bet } from '../assets/data/paris'
import {
    useRecoilState,
} from 'recoil';
import { cartTypeState, textState, betButtonState, messageState } from '../store/bets_store';
import { GiBasketballBall, GiDart, GiHockey, GiPingPongBat, GiSoccerBall, GiTennisBall, GiVolleyballBall } from "react-icons/gi";
import { MdClose, MdShare, MdDelete, MdSportsHandball, MdSportsRugby, MdLock, MdSportsFootball, MdSportsHockey, MdSportsBaseball } from "react-icons/md";
import { roundIt } from '../functions/utils';
import { openLoad } from './fullscreen_loader';
import { createBetcode, getBetcode, placeBet } from '../functions/api';
import iziToast from 'izitoast/dist/js/iziToast'
import { getToken } from '../functions/auth';
import { useNavigate } from 'react-router-dom';
import Message from './message_modal'
import { BiTrophy } from 'react-icons/bi';
import { payState } from '../store/bets_store';
import uniqueRandom from 'unique-random';
import { BsWhatsapp } from 'react-icons/bs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { error } from '../functions/toast';



function RightPane(props) {

    const [text, setText] = useRecoilState(textState)
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [betButton, setBetButton] = useRecoilState(betButtonState)
    const [message, setMessage] = useRecoilState(messageState)
    const [pay, setPay] = useRecoilState(payState)
    const [cote, setCote] = useState(1)
    const [amount, setAmount] = useState(0)
    const [win, setWin] = useState(0)
    const [betcode, setBetcode] = useState(0)

    const MySwal = withReactContent(Swal)

    const handleChange = () => {
        setPay(!pay);
    }

    const getPayment = () => {
        if (pay) {
            return 'bonus'
        } else {
            return 'main'
        }
    }





    useEffect(() => {

        let val = 1
        text.forEach(element => {
            val = val * element.cote
            //setCote(val)
        });
        setCote(Math.round(val * 100) / 100)
        setWin(Math.round(amount * cote))
        return 1
    }, [text, amount, cote])


    const betPlaced = (res) => {

        setText([])

        iziToast.show({
            title: 'Success',
            message: 'Votre pari a ete place avec success .',
            color: 'green',
            position: "topRight",
            backgroundColor: '#198754',
            titleColor: 'black',
            messageColor: 'white'
        });

        if (res.cote !== null) {

            MySwal.fire({
                title: '<strong>Pari place avec succes</strong>',
                icon: 'success',
                html: <div className='box'>
                    <p className='has-text-centered mt-2'><b>Betcode : {res.betcode}</b></p>
                    <p className='has-text-centered mt-4' onClick={() => location.href = 'https://web.whatsapp.com://send?text=La cote de ma selection de pari s\'élève à ' + res.cote + ' . Le code de mon coupon gagnant est ' + res.betcode + ' sur https://pjparyaj.com ou en cliquant sur ce lien : https://pjparyaj.com?betcode=' + res.betcode + ' .'}><BsWhatsapp size={48} color='green' /> </p>
                </div>,
                showCloseButton: true,
            })
        } else {

            MySwal.fire({
                title: '<strong>Pari place avec succes</strong>',
                icon: 'success',
                html: <div className='box'>
                    <p className='has-text-centered mt-2'>NB: Les coupons simples ou placés en bonus ne sont pas distribuable par betcode .</p>
                </div>,
                showCloseButton: true,
            })
        }
        setBetButton({ disabled: false, text: 'Placer mon pari' })


    }

    const betNonPlaced = (mes) => {

        iziToast.show({
            title: 'Erreur',
            message: mes,
            color: 'red',
            position: "topRight",
            backgroundColor: '#FF3F00',
            titleColor: 'black',
            messageColor: 'white'
        });

        // const textError = <div> <b>Erreur : </b> {mes} </div>

        // setMessage({show : true , content: textError, type:'panier' })

        setBetButton({ disabled: false, text: 'Placer mon pari' })


    }



    const verifMultipleSameFixtureInCart = () => {
        let newArr = []
        text.forEach(element => {
            newArr.push(element.fixtureId)
        });

        const hasDuplicates = (array) => {
            return (new Set(array)).size !== array.length;
        }

        if (hasDuplicates(newArr)) {
            return true
        } else {
            return false
        }

    }

    const togglePane = (payload) => {

        if (payload === "simple") {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })

        }
        else {
            if (!verifMultipleSameFixtureInCart()) {

                setCartType({
                    type: 'combo',
                    combo: {
                        hidden: false,
                        class: 'is-active',
                    },
                    simple: {
                        hidden: true,
                        class: '',
                    }
                })
            }
        }
    }
    const renderWin = (win) => {
        if (Number(win) > 0) {
            return Number(win)
        } else {
            return 0
        }
    }

    const updateAmount = (data, index) => {

        const getData = (val) => {
            if (val == '') {
                return 0
            } else {
                return parseInt(val)
            }
        }

        let array = text.slice()
        array[index] = {
            ...array[index],
            amount: getData(data)
        }
        setText(array)
    }

    const getSimpleWin = () => {
        let val = 0
        text.forEach(element => {
            val = val + (element.amount * element.cote)
        });
        return Math.round(val)
    }

    const getSimpleMise = () => {
        let val = 0
        text.forEach(element => {
            val = val + element.amount
        });
        return parseInt(val)
    }

    const getTotalAndMise = () => {
        if (cartType.type == 'combo') {
            return {
                mise: renderWin(amount),
                win: roundIt(roundIt(renderWin(win)))
            }
        } else {
            return {
                mise: getSimpleMise(),
                win: getSimpleWin()
            }
        }
    }
    const renderSport = (sport) => {

        switch (sport) {
            case 'F':
                return {
                    icon: <GiSoccerBall />,
                    text: 'FOOTBALL'
                }
            case 'B':
                return {
                    icon: <GiBasketballBall />,
                    text: 'BASKETBALL'
                }
            case 'T':
                return {
                    icon: <GiTennisBall />,
                    text: 'TENNIS'
                }

            case 'Ba':
                return {
                    icon: <MdSportsBaseball />,
                    text: 'BASEBALL'
                }

            case 'Ha':
                return {
                    icon: <MdSportsHandball />,
                    text: 'HANDBALL'
                }

            case 'Ho':
                return {
                    icon: <MdSportsHockey />,
                    text: 'HOCKEY'
                }

            case 'Fa':
                return {
                    icon: <MdSportsFootball />,
                    text: 'FOOTBALL AMERICAIN'
                }

            case 'D':
                return {
                    icon: <GiDart />,
                    text: 'FLECHETTES'
                }

            case 'R':
                return {
                    icon: <MdSportsRugby />,
                    text: 'RUGBY'
                }

            case 'V':
                return {
                    icon: <GiVolleyballBall />,
                    text: 'VOLLEYBALL'
                }

            case 'Tt':
                return {
                    icon: <GiPingPongBat />,
                    text: 'TENNIS TABLE'
                }


            default:
                return {
                    icon: <GiSoccerBall />,
                    text: 'FOOTBALL'
                }
        }

    }
    const placeTheBet = () => {
        setBetButton({ disabled: true, text: 'Execution ...' })
        placeBet(text, cartType, getToken().id, getToken().token, { mise: getTotalAndMise().mise, win: getTotalAndMise().win }, betPlaced, betNonPlaced, getPayment())
    }


    const renderCombo = () => {

        if (text.length > 0) {
            return text.map((value, index) => {
                return (
                    <div className="mt-4" >
                        <div className="px-2">
                            <div className='columns is-mobile' style={{borderBottom:'4px solid green', fontSize:'11px'}}>
                                <div style={{ backgroundColor: 'whitesmoke', color: 'black' }} className="column is-auto">
                                    <div><small>{renderSport(value.sport).icon} -{renderSport(value.sport).text} </small></div>
                                    <div><small style={{ fontSize: '' }}>1 - {value.homeTeam}</small></div>
                                    <div><small style={{ fontSize: '' }}>2 - {value.awayTeam}</small></div>
                                </div>

                                <div className='column is-one-third' style={{ backgroundColor: 'whitesmoke', color: 'black' }}>
                                    <div className="has-text-centered">
                                        <small> {value.betName}<br />{value.betChoose}</small>
                                    </div>
                                </div>

                                <div className='column is-one-fifth' style={{ backgroundColor: '#FFC900', color: '#141E27', fontWeight: 'bold', fontSize: 10 }}>
                                    <div id={index} className='has-text-right' onClick={() => {
                                        let arr = [...text]
                                        arr.splice(index, 1)
                                        setText(arr)
                                    }}>
                                        <MdClose />
                                    </div>
                                    <br />
                                    <small className='has-text-centered'>Cote : {value.cote > 1.01 ? value.cote : <MdLock />}</small>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            return <p className='has-text-centered py-6 has-text-light'>Votre panier est vide , veuillez ajouter des paris .</p>
        }
    }

    const renderSimple = () => {

        if (text.length > 0) {
            return text.map((value, index) => {
                return (
                    <div className="mt-2">
                        <div className="pb-1">
                            <div className="card is-flex is-justify-content-space-between px-2" style={{ backgroundColor: "#f5f5f5" }}>
                                <div style={{ backgroundColor: 'whitesmoke', color: 'black' }} className="">
                                    <div><small>{renderSport(value.sport).icon} -{renderSport(value.sport).text} </small></div>
                                    <div><small style={{ fontSize: '' }}>1 - {value.homeTeam}</small></div>
                                    <div><small style={{ fontSize: '' }}>2 - {value.awayTeam}</small></div>
                                </div>
                                <div className='px-1'>
                                    <div className="has-text-centered">
                                        <small> {value.betName}<br />{value.betChoose}</small>
                                    </div>
                                </div>

                                <div className='px-1' style={{ backgroundColor: '#FFC900', color: '#141E27', fontWeight: 'bold' }}>
                                    <div id={index} className='has-text-right' onClick={() => {
                                        let arr = [...text]
                                        arr.splice(index, 1)
                                        setText(arr)
                                    }}>
                                        <MdClose />
                                    </div>
                                    <br />
                                    <small>Cote : {value.cote > 1.01 ? value.cote : <MdLock />}</small></div>
                            </div>
                            <div className='px-1 is-flex is-justify-content-space-between is-justify-content-space-between' style={{ backgroundColor: '#FFC900', color: 'black' }}>
                                <small className='py-2 has-text-dark'>Montant : </small>
                                <div class="control py-1" >
                                    <input class="input is-small" type="number" value={value.amount} style={{ width: '70px', }} onChange={(e) => updateAmount(e.currentTarget.value, index)} /> HTG
                                </div>
                            </div>
                            <div style={{ backgroundColor: '#198754', color: 'white' }} className="is-flex is-justify-content-space-between">
                                <p>Gain : {Math.round(value.cote * value.amount)} HTG</p>
                            </div>


                        </div>
                    </div>


                )
            })
        } else {
            return <p className='has-text-centered py-6 has-text-light'>Votre panier est vide , veuillez ajouter des paris .</p>
        }
    }

    const handleBetcode = (betObj) => {
        if (betObj && betObj.data.length > 0 && betObj.data !== 'not_found') {
            setText(betObj.data)
        } else {
            error('Betcode non valide .')
        }
    }

    return (
        <>
            <div style={{ overflowY: 'auto', height: '150vh', fontSize: '13px' }}>
                <div className='pb-2 is-hidden-touch'>
                    <div className="is-hidden-touch pb-2" style={{ overflowY: 'scroll', height: 'auto', backgroundColor: '#252f5a' }}>
                        <header className="card-heagder" style={{ borderBottom: '1px solid lightgrey' }}>
                            <div>
                                <div style={{ display: 'flex' }}>

                                    <div style={{}}>
                                        <div className="card-header-title">
                                            <div className='has-text-primary'>Panier <span class="tag is-primary is-small">{text.length}</span></div>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <div className="card-header-title" onClick={() => setText([])}>
                                            <div className='' ><MdDelete size={25} color='red' /></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </header>
                        <div className='p-2'>
                            <div class="tabs is-centered is-link" style={{ fontSize: '13px' }}>
                                <ul>
                                    <li className={cartType.simple.class}><a onClick={() => togglePane('simple')}><p className='has-text-light'>Simple</p></a></li>
                                    <li className={cartType.combo.class}><a onClick={() => togglePane('combo')} ><p className='has-text-light'>Combo</p></a></li>
                                </ul>
                            </div>

                            <div hidden={cartType.combo.hidden} className='pb-2'>

                                {
                                    renderCombo()
                                }

                            </div>
                            <div hidden={cartType.simple.hidden} className=''>

                                {
                                    renderSimple()
                                }

                            </div>
                        </div>
                        <footer className="card-footer" style={{ display: 'block' }}>
                            <div className="p-2">
                                <div>
                                    <div hidden={cartType.combo.hidden}>
                                        <div className="">
                                            <div className="is-flex">
                                                <span className="has-text-left has-text-light">
                                                    Cote :<br />{" "}
                                                </span>
                                                <span className="ml-auto has-text-light">
                                                    <b> {cote} </b>
                                                </span>
                                            </div>
                                            <div className="is-flex mt-2">
                                                <span className="has-text-left has-text-light">
                                                    Montant :<br />{" "}
                                                </span>
                                                <span className="ml-auto">
                                                    <input className="input" type="number" placeholder={renderWin(amount)} onChange={(e) => setAmount(e.currentTarget.valueAsNumber)} />
                                                </span>
                                            </div>
                                            <div className="is-flex mt-2">
                                                <span className="has-text-left has-text-light">
                                                    Gain :<br />{" "}
                                                </span>
                                                <span className="ml-auto has-text-light">
                                                    <b> {renderWin(win) + ' HTG'}</b>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div hidden={cartType.simple.hidden}>
                                    <div>
                                        <p className='has-text-centered has-text-light'> Mise totale : {getSimpleMise()} HTG</p>

                                        <p className='has-text-centered p-2 has-text-centered has-text-light'> Gain : {getSimpleWin()} HTG</p>
                                    </div>

                                </div>
                                <div className="has-text-centered mt-2 has-text-light">
                                    <label className="checkbox">

                                        <input type="checkbox" value={pay} onClick={(e) => handleChange(e.currentTarget.valueAsNumber)} />
                                        &nbsp; <small className="has-text-centered"> Utiliser le bonus </small>
                                    </label>
                                    <div hidden={!pay}><p className='help mt-2 has-text-centerd'> En utilisant les bonus vous n aurez que 30% de vos gains en réel sur la balance principale.</p></div>
                                </div>
                                <div className='is-flex is-justify-content-center mt-1'>
                                    <button className="button is-primary is-fullwidth " onClick={() => placeTheBet()} disabled={betButton.disabled} >
                                        {betButton.text}
                                    </button>
                                </div>
                                <div className='is-flex is-justify-content-center'>
                                    <button className="button is-light mt-2 is-fullwidth " onClick={() => createBetcode(text, cartType, error, (e) => {
                                        if (e.message == 'ok') {
                                            MySwal.fire({
                                                title: '<strong>Betcode géneré avec succes</strong>',
                                                icon: 'success',
                                                html: <div className='box'>
                                                    <p className='has-text-centered mt-2'><b>Betcode : {e.betcode}</b></p>
                                                    <p className='has-text-centered mt-4' onClick={() => location.href = 'whatsapp://send?text=Le cote de ma selection de pari s\'élève à ' + e.cote + ' . Le betcode de mon coupon gagnant est ' + e.betcode + ' sur https://pjparyaj.com ou en cliquant sur ce lien : https://pjparyaj.com?betcode=' + e.betcode + ' .'}><BsWhatsapp size={48} color='green' /> </p>
                                                </div>,
                                                showCloseButton: true,
                                            })
                                        } else {
                                            error('Une erreur s\'est produite . ')
                                        }
                                    })}>
                                        <MdShare></MdShare>  &nbsp; Partager le betcode
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </div>

                    <div className='card px-2 pb-2 mt-2 is-flex is-justify-content-center is-hidden-touch'>
                        <form class="" style={{ width: '100%' }} action='javascript:void(0)'>
                            <div class="field">
                                <label class="label">Betcode</label>
                                <div class="control">
                                    <input class="input" type="number" placeholder={'000000'} onChange={(e) => setBetcode(e.currentTarget.valueAsNumber)} />
                                </div>
                            </div>
                            <p class="help">Le betcode permet de partager des paris facilement , utilisez-les .</p>
                            <button class="button is-primary is-fullwidth mt-2 " onClick={() => getBetcode(parseInt(betcode), handleBetcode)}> Soumettre </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RightPane
