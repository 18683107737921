import React, { useState, useEffect } from 'react'
import { odds } from '../assets/data/odds'
import Moment from 'react-moment';
import { match } from '../assets/data/match'
import { MdBarChart, MdLock, MdSecurity } from 'react-icons/md';
import { BsFillCircleFill } from 'react-icons/bs'
import { BiStats } from 'react-icons/bi'
import { GiTrophy, GiSoccerBall } from 'react-icons/gi'
import { bet_list_soccer } from '../assets/data/bet_list';
import { bet365 } from '../assets/data/bet365';
import { getSingleOdd, getSingleMatch } from '../functions/api';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'react-activity/dist/Spinner';
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState, isNextClickState } from '../store/bets_store';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import img from '../assets/images/back2.jpg'
import { getPaysByCC, groupBy, renderCote } from '../functions/utils';
import * as dayjs from 'dayjs'
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')


function OddsList() {

    const [style, setStyle] = useState({ enCours: 'has-text-primary', gagne: '', perdu: '', tout: '' })
    const [resultLength, setResultLength] = useState(0)
    const [oddsObj, setOddsObj] = useState(null)
    const [matchObj, setMatchObj] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [text, setText] = useRecoilState(textState)
    const [oddsArray, setOddsArray] = useState(null)


    const getGame = () => {

        if (parseInt(searchParams.get("game")) > 0) {
            return searchParams.get("game")
        }
        return 0
    }

    const game = getGame()

    const setTheOddsObj = (oddsArr) => {
        const tempArr = []

        for (const elem of oddsArr.data) {
            tempArr.push(elem)
        }

        if (oddsArr.data.find(x => x.id == '981') || oddsArr.data.find(x => x.id == '10202')) {
            tempArr.push({
                id: "2023GG01",
                name: "Goals Over/Under",
                odds: (oddsArr.data.find(x => x.id == '981') ? oddsArr.data.find(x => x.id == '981').odds : []).concat(oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []).sort((a, b) => a.name - b.name)
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })
        }

        if (oddsArr.data.find(x => x.id == '10114') && oddsArr.data.find(x => x.id == '10150')) {
            tempArr.push({
                id: "2023GG02",
                name: "Double chance et les deux equipes marquent",
                odds: (oddsArr.data.find(x => x.id == '10150') ? oddsArr.data.find(x => x.id == '10150').odds : []).map((v, i) => {
                    const tempArr = []
                    for (const elem of oddsArr.data.find(x => x.id == '10114').odds) {
                        tempArr.push({
                            id: elem.id,
                            name: elem.name + ' | ' + v.name,
                            odds: (parseFloat(elem.odds) * parseFloat(v.odds)).toFixed(2),
                            // header:elem.header
                        })
                    }
                    return tempArr
                }).filter(v => v).flat()
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })

        }

        if (oddsArr.data.find(x => x.id == '40') && oddsArr.data.find(x => x.id == '10202')) {
            tempArr.push({
                id: "2023GG03",
                name: "Resultat du match et nombre de buts",
                odds: (oddsArr.data.find(x => x.id == '40') ? oddsArr.data.find(x => x.id == '40').odds : []).map((v, i) => {
                    const tempArr = []
                    for (const elem of oddsArr.data.find(x => x.id == '10202').odds) {
                        if (elem.name !== '0.5')
                            tempArr.push({
                                id: elem.id,
                                name: '| ' + elem.header + ' ' + elem.name,
                                odds: ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.45)).toFixed(2),
                                headerShown: false,
                                header: v.name,
                            })
                    }
                    return tempArr
                }).filter(v => v).flat()
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })

        }

        if (oddsArr.data.find(x => x.id == '10114') && oddsArr.data.find(x => x.id == '10202')) {
            tempArr.push({
                id: "2023GG04",
                name: "Double chance et nombre de buts",
                odds: (oddsArr.data.find(x => x.id == '10114') ? oddsArr.data.find(x => x.id == '10114').odds : []).map((v, i) => {
                    const tempArr = []
                    for (const elem of oddsArr.data.find(x => x.id == '10202').odds) {
                        if (elem.name !== '0.5' && ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.45)).toFixed(2) > 1.01)
                            tempArr.push({
                                id: elem.id,
                                name: '| ' + elem.header + ' ' + elem.name,
                                odds: ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.45)).toFixed(2),
                                headerShown: false,
                                header: v.name,
                            })
                    }
                    return tempArr
                }).filter(v => v).flat()
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })

        }

        if (oddsArr.data.find(x => x.id == '10150') && oddsArr.data.find(x => x.id == '10202')) {
            tempArr.push({
                id: "2023GG05",
                name: "Les deux équipes marquent et nombre de buts",
                odds: (oddsArr.data.find(x => x.id == '10150') ? oddsArr.data.find(x => x.id == '10150').odds : []).map((v, i) => {
                    const tempArr = []
                    for (const elem of oddsArr.data.find(x => x.id == '10202').odds) {
                        if (elem.name !== '0.5' && elem.name !== '1.5' && ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.2)).toFixed(2) > 1.01)
                            tempArr.push({
                                id: elem.id,
                                name: '| ' + elem.header + ' ' + elem.name,
                                odds: ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.2)).toFixed(2),
                                headerShown: false,
                                header: v.name,
                            })
                    }
                    return tempArr
                }).filter(v => v).flat()
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })
        }

        if (oddsArr.data.find(x => x.id == '10114') && oddsArr.data.find(x => x.id == '10150')) {
            tempArr.push({
                id: "2023GG06",
                name: "Double chance et les deux équipes marquent",
                odds: (oddsArr.data.find(x => x.id == '10114') ? oddsArr.data.find(x => x.id == '10114').odds : []).map((v, i) => {
                    const tempArr = []
                    for (const elem of oddsArr.data.find(x => x.id == '10150').odds) {
                        if (elem.name !== '0.5' && ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.12)).toFixed(2) > 1.01)
                            tempArr.push({
                                id: elem.id,
                                name: '| ' + elem.name,
                                odds: ((parseFloat(elem.odds) * parseFloat(v.odds) / 1.12)).toFixed(2),
                                headerShown: false,
                                header: v.name,
                            })
                    }
                    return tempArr
                }).filter(v => v).flat()
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })

        }

        if (oddsArr.data.find(x => x.id == '10127')) {
            tempArr.push({
                id: "2023GG07",
                name: "Nombre de buts équipe 1",
                fr: "Nombre de buts équipe 1",
                odds: (oddsArr.data.find(x => x.id == '10210') ? oddsArr.data.find(x => x.id == '10210').odds.map((v, i) => {
                    // if (v.header == 2) {
                    //     if (v.handicap == 'No') {
                    //         return {
                    //             id: "992743522",
                    //             odds: v.odds,
                    //             header: "1",
                    //             handicap: "Over 0.5"
                    //         }
                    //     } else if (v.handicap == 'Yes') {
                    //         return {
                    //             id: "992743522",
                    //             odds: v.odds,
                    //             header: "1",
                    //             handicap: "Under 0.5"
                    //         }
                    //     }
                    // }
                }).filter(v => v) : []).concat(oddsArr.data.find(x => x.id == '10127') ? oddsArr.data.find(x => x.id == '10127').odds : []).filter(x => x.header == "1")
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })
        }

        if (oddsArr.data.find(x => x.id == '10127')) {
            tempArr.push({
                id: "2023GG08",
                name: "Nombre de buts équipe 2",
                fr: "Nombre de buts équipe 2",
                odds: (oddsArr.data.find(x => x.id == '10210') ? oddsArr.data.find(x => x.id == '10210').odds.map((v, i) => {
                    // if (v.header == 1) {
                    //     if (v.handicap == 'Yes') {
                    //         return {
                    //             id: "992743522",
                    //             odds: v.odds,
                    //             header: "2",
                    //             handicap: "Under 0.5"
                    //         }
                    //     } else if (v.handicap == 'No') {
                    //         return {
                    //             id: "992743522",
                    //             odds: v.odds,
                    //             header: "2",
                    //             handicap: "Over 0.5"
                    //         }
                    //     }
                    // }
                }).filter(v => v) : []).concat(oddsArr.data.find(x => x.id == '10127') ? oddsArr.data.find(x => x.id == '10127').odds : []).filter(x => x.header == "2")
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })
        }

        if (oddsArr.data.find(x => x.id == '760') || oddsArr.data.find(x => x.id == '10234')) {
            tempArr.push({
                id: "2023GG09",
                name: "Corners Over/Under",
                odds: (oddsArr.data.find(x => x.id == '760') ? oddsArr.data.find(x => x.id == '760').odds : []).concat(oddsArr.data.find(x => x.id == '10234') ? oddsArr.data.find(x => x.id == '10234').odds : [])
                //odds: oddsArr.data.find(x => x.id == '10202') ? oddsArr.data.find(x => x.id == '10202').odds : []
            })
        }

        setOddsObj({ data: tempArr, status: 200 })
    }


    useEffect(async () => {
        if (game) {
            await getSingleMatch('1', game, setMatchObj)
            getSingleOdd(setTheOddsObj, setResultLength, game, '1')
        }
    }, [game]);


    const verifBetInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });


        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameAndBetChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }


    const verifFixtureAndBetNameAndBetNonChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose !== betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const removeFromCart = (id, betName, betChoose) => {

        text.some((el, index) => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                let arr = [...text]
                arr.splice(index, 1)
                setText(arr)
                return true
            } else {
                return false
            }
        });
    }

    const addBet = (payload, id) => {
        if (!verifFixtureInCart(id, payload.betName)) { //si match la pa egziste

            if (!verifBetInCart(id, payload.betName, payload.betChoose)) { //si match la pa egziste e si pari a pa egziste

                setText([...text, {
                    sport: payload.sport,
                    fixtureId: payload.fixtureId,
                    homeTeam: payload.homeTeam,
                    awayTeam: payload.awayTeam,
                    timestamp: payload.timestamp,
                    betName: payload.betName,
                    betChoose: payload.betChoose,
                    cote: payload.cote,
                    amount: 0,
                    data: payload.data,
                    type: payload.type || 'prematch',
                    name: payload.name || null,
                    betId: payload.betId || null,
                    handicap: payload.hand || null,
                    pa_id: payload.pa_id || null
                }])
            } else { // si match la pa egziste men pari a egziste

                //alert('test')
                //removeFromCart(id, payload.betName, payload.betChoose)
            }
        } else if (verifFixtureAndBetNameAndBetChooseInCart(id, payload.betName, payload.betChoose)) { // si match la egziste

            removeFromCart(id, payload.betName, payload.betChoose)
        } else if (verifFixtureAndBetNameInCart(id, payload.betName) === false) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                timestamp: payload.timestamp,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0,
                data: payload.data,
                type: payload.type || 'prematch',
                name: payload.name || null,
                betId: payload.betId || null,
                handicap: payload.hand || null,
                pa_id: payload.pa_id || null
            }])
        }
        else if (verifFixtureAndBetNameAndBetNonChooseInCart(id, payload.betName, payload.betChoose)) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                timestamp: payload.timestamp,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0,
                data: payload.data,
                type: payload.type || 'prematch',
                name: payload.name || null,
                betId: payload.betId || null,
                handicap: payload.hand || null,
                pa_id: payload.pa_id || null
            }])
        }

    }

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const toggleTabsCss = (tab) => {
        if (tab === 'tout') {
            setOddsArray(setTheArray(0))
            setStyle({
                tout: 'has-text-primary',
                resultat: '',
                buts: '',
                stats: ''
            })
        } else if (tab === 'resultat') {
            setOddsArray(setTheArray(1))
            setStyle({
                tout: '',
                resultat: 'has-text-primary',
                buts: '',
                stats: ''
            })
        } else if (tab === 'buts') {
            setOddsArray(setTheArray(2))
            setStyle({
                tout: '',
                resultat: '',
                buts: 'has-text-primary',
                stats: ''
            })
        } else {
            setOddsArray(setTheArray(3))
            setStyle({
                tout: '',
                resultat: '',
                buts: '',
                stats: 'has-text-primary'
            })
        }
    }

    const setTheArray = (payload) => {

        let arr = []

        // switch (payload) {
        //     case 1:
        //         for (const i of bet_list.response) {

        //             if (i.type == 1 || i.type == 12) {
        //                 arr.push(i)
        //             }

        //         }
        //     case 2:
        //         for (const i of bet_list.response) {

        //             if (i.type == 2 || i.type == 12) {
        //                 arr.push(i)
        //             }

        //         }
        //     case 3:
        //         for (const i of bet_list.response) {

        //             if (i.type == 3) {
        //                 arr.push(i)
        //             }

        //         }

        //         break;

        //     default:
        //         arr = bet_list.response
        // }

        return arr


    }

    const parseName = (nom, id) => {

        //return nom

        if (true) {

            RegExp.quote = function (str) {
                return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }

            let h = new RegExp(RegExp.quote(matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name), "g");
            let a = new RegExp(RegExp.quote(matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name), "g");
            let s = new RegExp('__', "g");
            let sd = new RegExp('/', "g");
            const name = nom.toString().replace(h, "1").replace(a, "2").replace(s, "/").replace(sd, "")


            const mapObj = {
                Home: '1',
                Away: '2',
                Draw: 'X',
                // Over: "superieur à",
                // Under: "inferieur à",
                Over: ">",
                Under: "<",
                Odd: "impair",
                Even: "pair",
                more: "",
                by: " -> ",
                "NO GOALS": '0',
                "o/yes": "oui >",
                "u/yes": "oui <",
                "o/no": "non >",
                "u/no": "non <",
                "Score Draw": 'pas de but',
                Yes: "oui",
                No: "non",
                "1st Half": '1er',
                "First": '1er',
                "Last": 'dernier',
                "2nd Half": '2eme',
                Header: 'Tete',
                FreeKick: 'Coup Franc',
                OwnGoal: 'Contre son camp',
                Shot: 'Tir',
                Exactly: "",
                Goal: 'but',
                before: 'avant',
                after: 'apres',
                "Full Time": '90',
                goal: 'but',
                Tie: 'X',
                " or ": '',
                Anytime: '',
                Only: 'uniquement',
                "Both Teams": 'Les deux equipes',
                "Free Kick": 'Coup franc',
                "Own Goal": "Contre son camp",
                "More": 'plus',
                "Starting Players": '',
                "Substitutes": '',
                "No Goalscorer": 'pas de buteur',
                "Outside the Box": 'Dehors du carré',
                'Cards': ' carton'

            }

            const result = name.replace(/Home|Away|Draw|Over|Under|Odd|Even|more|by|o\/yes|u\/yes|o\/no|u\/no|Score Draw|Yes|No|1st Half|2nd Half|Header|FreeKick|OwnGoal|Shot|Exactly|First|Last|Goal|before|after|Full Time|goal|Tie|NO GOALS| or |Anytime|Only|Both Teams|Free Kick|Own Goal|More|Starting Players|Substitutes|No Goalscorer|Outside the Box|Cards/gi, function (matched) {
                return mapObj[matched];
            })

            return result
        } else {
            return nom.toString()
        }
    }


    const getBet = (betId, betName, arr) => {
        if (oddsObj) {
            if (arr.length < 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    for (const elem of theArr) {
                        theArr2.push(arr.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arr.filter(x => x.header == v).map((v, i) => <th><div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                            sport: 'F',
                                            fixtureId: game,
                                            homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                            awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                            timestamp: matchObj.data[0].time,
                                            betName: betName,
                                            betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`),
                                            cote: renderCote(v.odds),
                                            amount: 0,
                                            data: { ...v, id: betId }
                                        }, game)}>
                                            <div class="control" style={{ width: '100px' }}>
                                                <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                            </div>
                                            <div class="control is-small">
                                                <a className="button is-primary is-small" href="#">
                                                    {renderCote(v.odds)}
                                                </a>
                                            </div>
                                        </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <div className='is-flex is-justify-content-space-between'>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between">
                                <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: renderCote(v.odds),
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '100px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {renderCote(v.odds)}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="is-hidden-desktop is-flex ">
                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: renderCote(v.odds),
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '50px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {renderCote(v.odds)}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </ div>
                    )

                }
            }
            else if (arr.length >= 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    theArr = theArr.map((v, i) => {
                        if (v == 'undefined') {
                            return 'autre'
                        } else {
                            return v
                        }
                    })



                    let arrNew = arr.map((v, i) => {
                        if (!v.header) {
                            return { ...v, header: 'autre' }
                        } else {
                            return v
                        }
                    })

                    for (const elem of theArr) {
                        theArr2.push(arrNew.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arrNew.filter(x => x.header == v).map((v, i) => <th className='is-flex is-flex-wrap-wrap p-1 is-justify-content-center'>
                                            <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                                sport: 'F',
                                                fixtureId: game,
                                                homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                                awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                                timestamp: matchObj.data[0].time,
                                                betName: betName,
                                                betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`),
                                                cote: renderCote(v.odds),
                                                amount: 0,
                                                data: { ...v, id: betId }
                                            }, game)}>
                                                <div class="control" style={{ width: '100px' }}>
                                                    <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap ? v.handicap + ' ' : '' || ''}${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                                </div>
                                                <div class="control is-small">
                                                    <a className="button is-primary is-small" href="#">
                                                        {renderCote(v.odds)}
                                                    </a>
                                                </div>
                                            </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: renderCote(v.odds),
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {renderCote(v.odds)}
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className="is-hidden-desktop is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`),
                                    cote: renderCote(v.odds),
                                    amount: 0,
                                    data: { ...v, id: betId }
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name || ''}`)} readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {renderCote(v.odds)}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </>
                    )
                }
            }
            else {
                return <div className="is-flex is-flex-wrap-wrap p-4">
                    <div class="field has-addons px-1 is-flex-grow-1">
                        <div class="control is-small">
                            <input className="input is-small" type="text" value='Non Disponible' disabled />
                        </div>
                        <div class="control is-small">
                            <a className="button is-primary is-small" href="#">
                                <MdLock />
                            </a>
                        </div>
                    </div>

                </div>
            }
        }
    }

    const renderBet = () => {

        if (oddsObj === null || matchObj === null) {
            // return <div className='is-flex is-justify-content-center mt-4 pb-4'>
            //     <Spinner color='#FFC900' />
            // </div>
            return void (0)
        }

        // return JSON.stringify(oddsObj)
        return (
            <>
                {/* <div className='card my-2 py-1 mx-2' >
                    <div className="mt-2 px-2 is-flex is-justify-content-center">
                        <button class="button is-white" onClick={() => toggleTabsCss('tout')}><p className={style.tout}> <BsFillCircleFill /> Tout</p></button>
                        <button class="button is-white" onClick={() => toggleTabsCss('resultat')}><p className={style.resultat}><GiTrophy /> Resultat</p></button>
                        <button class="button is-white" onClick={() => toggleTabsCss('buts')}><p className={style.buts}><GiSoccerBall /> Buts</p></button>
                        <button class="button is-white" onClick={() => toggleTabsCss('stats')}><p className={style.stats}><BiStats /> Stats</p></button>
                    </div>

                </div> */}

                <div className='px-2 pb-4'>
                    <div className='has-text-light'>
                    </div>
                    {
                        bet_list_soccer.map((v, i) => {
                            if (oddsObj.data.find(x => x.id == v.id)) {
                                return <div className="mt-2" style={{ fontSize: '13.5px' }}>

                                    <div className="card mt-2">
                                        <header className="card-header" style={{ backgroundColor: 'white' }}>
                                            <p className="ml-2 has-text-dark">
                                                {oddsObj.data.find(x => x.id == v.id).fr ? oddsObj.data.find(x => x.id == v.id).fr : v.fr || v.name}
                                                {/* - {v.id}{JSON.stringify(oddsObj.data.find(x => x.id == v.id))} */}
                                            </p>
                                        </header>
                                        {/* // */}
                                        <div className="card-content is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between" style={{ backgroundColor: 'white' }}>
                                            {/* {JSON.stringify(oddsObj.data.find(x => x.id == v.id))} */}
                                            {
                                                getBet(v.id, v.fr || v.name, v.render ? v.render(oddsObj.data.find(x => x.id == v.id).odds) : oddsObj.data.find(x => x.id == v.id).odds.map((v, i) => { return { ...v, headerShown: true } }))
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                        }
                        )
                    }
                </div>
            </>
        )
    }

    const renderPanel = () => {

        // const topRight = () => {
        //     if (matchObj.data[0].league.round) {
        //         return matchObj.data[0].extra.round
        //     } else {
        //         return matchObj.data[0].extra.round
        //     }
        // }
        if (matchObj !== null) {

            //return JSON.stringify(matchObj)

            return <div className="">
                <div className="card mx-2 pb-2" style={{ backgroundColor: `transparent`, backgroundSize: 'cover' }}>

                    <div className="header -darcolork" style={{ backgroundColor: 'yellow' }}>
                        <div className='is-flex px-2 has-text-dark'>
                            <div className='is-flex'>
                                <div className='mt-1'><GiSoccerBall /></div>
                                <p className='ml-2'><small> {getPaysByCC(matchObj.data[0].league.cc)} - {matchObj.data[0].league.name} </small></p>
                            </div>
                            <div className='ml-auto'>
                                <p><small>{'2023'}</small></p>
                            </div>
                        </div>
                    </div>



                    <div className='is-flex is-justify-content-space-between px-4 mt-4' style={{ color: 'white' }} >
                        <div class="column has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].home.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' style={{ textShadow: 'black 2px 0 30px' }}>{matchObj.data[0].o_home ? matchObj.data[0].o_home.name : matchObj.data[0].home.name}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div>
                                <div className='is-flex is-justify-content-center'>   <p className='is-size-4 has-text-centered' >{dayjs.unix(matchObj.data[0].time).format('h:mm A')}</p> </div>
                                <div className='is-flex is-justify-content-center'>   <p className='has-text-centered' >{dayjs.unix(matchObj.data[0].time).format('D MMMM YYYY')}</p> </div>
                            </div>
                        </div>
                        <div class="column  has-text-centered">
                            <div className='is-flex is-justify-content-center'>
                                <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].away.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                            </div>
                            <div>
                                <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' >{matchObj.data[0].o_away ? matchObj.data[0].o_away.name : matchObj.data[0].away.name}</p> </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        } else {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>
        }
    }

    return (
        <div className='' style={{ backgroundColor: '#252f5a' }}>

            {
                renderPanel()
            }



            {/* <div className="mt-2">
                    <p className="has-text-centered">{odds.response.map((val, i) => val.league.name)} <img src={odds.response.map((val, i) => val.league.logo)} alt={odds.response.map((val, i) => val.league.name)} width='32px' height='32px' /> </p>
                    <p className="has-text-centered is-size-5"> <img src={match.response.map((val, i) => val.teams.home.logo)} alt={match.response.map((val, i) => val.teams.home.name)} width='24px' height='24px' />&nbsp;{Home} vs {Away}&nbsp;<img src={match.response.map((val, i) => val.teams.away.logo)} alt={match.response.map((val, i) => val.teams.away.name)} width='24px' height='24px' /></p>
                    <p className="has-text-centered">{match.response.map((val, i) => <><Moment format="DD/MM/YYYY">{val.fixture.date}</Moment> - <Moment format="hh:mm">{val.fixture.date}</Moment></>)}</p>
                    <p className="has-text-centered is-size-4"><a class="button" onClick={() => alert('test')}> <MdBarChart /> Stats </a></p>
                </div> */}


            {
                renderBet()
            }
        </div>
    )
}

export default OddsList
