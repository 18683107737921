import React, { useState, useEffect } from 'react'

import logo from './logo.svg';
import './App.css';
//import 'bulma/css/bulma.min.css'
import './css/appstyle.css'
import '@creativebulma/bulma-badge/dist/bulma-badge.min.css'
import "@creativebulma/bulma-divider/dist/bulma-divider.min.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-tiny-fab/dist/styles.css';
import "react-activity/dist/Spinner.css";
import "izitoast/dist/css/iziToast.min.css";
import 'react-spring-bottom-sheet/dist/style.css'
import 'react-tabs/style/react-tabs.css';
import 'font-awesome/css/font-awesome.min.css';
import 'dayjs/locale/fr-ca'; // import locale
import Acceuil from './pages/acceuil'
import Home from './pages/home';
import Login from './pages/login';
import Signup from './pages/signup';
import Cart from './pages/cart';
import Recharge from './pages/recharge'
import CountryLeague from './pages/country_league';
import Event from './pages/events';
import EventBasket from './pages/events_basket';
import MyAccount from './pages/my_account';
import BottomTabs from './components/bottom-tabs';
import MyTransactions from './pages/transaction';
import EditPassword from './pages/edit_password';
import Navbar from './components/navbar';
import BetsHistory from './pages/bets_history';
import Sports from './pages/sports';
import Football from './pages/football';
import FootballList from './pages/football_list';
import BasketBall from './pages/basketball';
import BasketballList from './pages/basketball_list';
import VolleyBall from './pages/volleyball';
import VolleyballList from './pages/volleyball_list';
import Hockey from './pages/hockey';
import HockeyList from './pages/hockey_list';
import Rugby from './pages/rugby';
import RugbyList from './pages/rugby_list';
import HandBall from './pages/handball';
import HandballList from './pages/handball_list';
import FootballCompetition from './pages/football_competition';
import Virtual from './pages/virtual';
import Casino from './pages/casino';
import Message from './components/message_modal'
import { useRecoilValue } from 'recoil';
import { imageState, menuViewState, messageState, sportIsOpenState } from './store/bets_store'
import Loading from './components/site_loading';
import Retrait from './pages/retrait';
import ReactGA from 'react-ga';
import { Fab, Action } from 'react-tiny-fab';



import {
  Routes,
  Route,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { textState } from './store/bets_store';
import { cartTypeState } from './store/bets_store';
import { useRecoilState } from 'recoil';
import { getBetcode, getCompetitionsBycountry, getTopCompetitions, getcountries } from './functions/api';
import Swal from 'sweetalert2';
import MyFiyel from './pages/fiyel';
import EventLive from './pages/events_live';
import Tickets from './pages/tickets';
import axios from 'axios';
import Live from './pages/live';
import LiveInfo from './components/live_info';
import { MdAdd, MdOpenInBrowser, MdSportsBaseball, MdSportsBasketball, MdSportsHandball, MdSportsHockey, MdSportsRugby } from 'react-icons/md';
import { GiAmericanFootballHelmet, GiDart, GiPingPongBat, GiTennisBall, GiVolleyballBall } from 'react-icons/gi';
import SportPage from './pages/sport';
import SportEvent from './pages/sports-event';
import { apiUrl } from './constant';
import { SideMenu } from './components/side_menu';
import { IoMdCart } from 'react-icons/io';
import LotteryPage from './pages/lottery';
import LotteryPlayPage from './pages/play_lottery';
import GameEmbed from './pages/gameEmbed';
import ResetPassword from './pages/resetPassword';
import { pays } from './assets/data/pays';
import { sport_365 } from './assets/data/sports_2';
import TicketsHistory from './pages/ticketHistory';
import SearchResultPage from './pages/search_result';
import TchalaPage from './pages/tchala';
import Contact from './pages/contact';



// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString();


const ElementList = ({ elements }) => {
  return (
    <div className="columns is-multiline is-mobile">
      {elements.map((element, index) => (
        <div key={index} className="column is-one-third">
          {element}
        </div>
      ))}
    </div>
  );
};

const SportCompetitions = ({ name, id, sport, setShowSportList, dismiss }) => {

  const [topComp, setTopComp] = useState([])
  const [showCompList, setShowCompList] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getTopCompetitions(id, (d) => d.data ? setTopComp(d.data) : setTopComp([]))
    //getcountries(sport, setCountries)
  }, [id])


  const [_competitionByCountry, set_competitionByCountry] = useState([]);


  return (
    <>
      <div hidden={showCompList}>
        <p className='has-text-left help pb-2 has-text-dark' onClick={() => {
          setShowSportList(true)
        }}>Retour</p>

        <p className='has-text-centered pb-3 has-text-dark'>{name}</p>

        <ElementList
          elements={topComp.map((v, i) => {
            if (v.cc) {
              return { cc: v.cc };
            } else {
              return { cc: 'univ', org: v.cc };
            }
          }).filter(v => v).filter((item, index, self) => self.findIndex(obj => obj['cc'] === item['cc']) === index).map((v, i) => (
            <div className="block" onClick={() => {
              getCompetitionsBycountry(id, v.cc !== 'univ' ? v.cc : null, (d) => set_competitionByCountry(d.data))
              setShowCompList(true)
            }}>
              <div className='is-flex is-justify-content-center'>
                <figure className='image is-64x64'>
                  <img
                    src={`${v.cc !== 'univ' ? `https://assets.betsapi.com/v2/images/flags/${v.cc}.svg` : `https://icones.pro/wp-content/uploads/2021/05/symbole-du-globe-jaune.png`}`}
                    alt="Thumbnail"
                  />
                </figure>
              </div>
              <div className='has-text-centered'>
                <p className='help has-text-dark'>{pays.results.find(x => x.cc === v.cc) ? pays.results.find(x => x.cc === v.cc).name : `International ${v.cc !== 'univ' ? ' ' + v.cc.toUpperCase() : ''}`}</p>
              </div>
            </div>
          ))}
        />
        <div className='is-flex is-justify-content-center pb-4'> <button className='button is-small is-primary' onClick={() => {
          navigate('/sports/' + sport.toLowerCase())
          dismiss()
        }}> Voir tout les matchs </button></div>
      </div>
      <div style={{ backgroundColor: 'white' }} className='px-4 mt-2 pb-2' hidden={!showCompList} >
        <p className='has-text-left help pb-3 has-text-dark' onClick={() => {
          setShowCompList(false)
        }}>Retour</p>
        {_competitionByCountry.map((v, i) => <p className='has-text-centered has-text-dark help' onClick={() => {
          navigate('/sports/' + sport + '?league=' + v.id)
          dismiss()
        }}> {v.name} </p>)}
      </div>
    </>
  )

  // return (
  //   <div className="columns is-multiline is-mobile">
  //     {topComp.map((element, index) => (
  //       <div key={index} className="column is-one-third">
  //         {element}
  //       </div>
  //     ))}
  //   </div>
  // );
};

const App = (props) => {

  const [message, setMessage] = useRecoilState(messageState)
  const [text, setText] = useRecoilState(textState)
  const [type, setType] = useRecoilState(cartTypeState)
  const [view, setView] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useRecoilState(imageState)
  const [menuView, setMenuView] = useRecoilState(menuViewState)

  const getCode = () => {
    if (parseInt(searchParams.get("betcode")) > 0) {
      return searchParams.get("betcode")
    }
    return 0
  }

  const [isOpen, setIsOpen] = useRecoilState(sportIsOpenState);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [countries, setCountries] = useState(pays)
  const [topCompetition, setTopCompetition] = useState()


  const sport = props.sport || '1'



  useEffect(() => {
    getTopCompetitions(sport, setTopCompetition)
    getcountries(sport, setCountries)
  }, [sport]);


  const betcode = getCode()


  // ReactGA.initialize('UA-000000-01');
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const handleBetcode = (betObj) => {
    if (betObj && betObj.data.length > 0 & betObj.data !== 'not_found') {
      setType({
        type: 'combo',
        combo: {
          hidden: false,
          class: 'is-active',
        },
        simple: {
          hidden: true,
          class: '',
        }
      })

      setText(betObj.data)
      Swal.fire('Succes!',
        'Votre panier a été chargé avec success !',
        'success')
    }
  }

  useEffect(() => {

    axios.get(apiUrl + 'image/get_image').then(
      x => {
        const arr = x.data.data
        let newObj = {}
        for (const elem of arr) {
          newObj = { ...newObj, [elem.name]: elem.link }
        }
        setImage(newObj)
        //alert('good')
      }
    )

    setTimeout(() => {
      setView(false)
    }, 3000);

    if (betcode !== 0) {
      getBetcode(betcode, handleBetcode)
    }


  }, [])

  const [networkStatus, setNetworkStatus] = useState(true);

  const checkOnlineStatus = async () => {
    try {
      //  const online = await fetch("https://ipv4.icanhazip.com");
      const online = await axios.get('https://ipv4.icanhazip.com', { timeout: 5000 })
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };

  const check = () => {
    const interval = setInterval(async () => {
      const result = await checkOnlineStatus();
      setNetworkStatus(result)
    }, 15000);

    return () => clearInterval(interval);

  }

  useEffect(() => {
    check()
  }, [])

  const [showSportList, setShowSportList] = useState(true)

  const [sportData, setSportData] = useState({ id: '1', name: 'Football', sport: 'football' })

  const navigate = useNavigate()

  const renderSite = () => {
    if (1 + 1 == 3) {
      return <div style={{ backgroundColor: '#252f5a' }}>
        <div>
        </div>
        <Routes>
          <Route path='/' element={<Acceuil />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="signup" element={<Signup />} />
          <Route path="cart" element={<Cart />} />
          <Route path="recharge" element={<Recharge />} />
          <Route path="country" element={<CountryLeague />} />
          <Route path="my_account" element={<MyAccount />} />
          <Route path="bets_history" element={<BetsHistory />} />
          <Route path="tickets_history" element={<TicketsHistory />} />
          <Route path="transactions" element={<MyTransactions />} />
          <Route path="referral" element={<MyFiyel />} />
          <Route path="edit_password" element={<EditPassword />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sports" element={<Sports />} />
          <Route path="search/:text" element={<SearchResultPage />} />
          <Route path="virtual-games" element={<Virtual />} />
          <Route path="virtual-games/play/:loader/:session" element={<GameEmbed />} />
          <Route path="casino" element={<Casino />} />
          <Route path="sports/list/:id/:path" element={<FootballList />} />
          <Route path="sports/list/basketball" element={<BasketballList />} />
          <Route path="sports/list/handball" element={<HandballList />} />
          <Route path="sports/list/volleyball" element={<VolleyballList />} />
          <Route path="sports/list/hockey" element={<HockeyList />} />
          <Route path="sports/list/rugby" element={<RugbyList />} />
          <Route path="sports/football/event" element={<Event />} />
          <Route path="sports/live" element={<Live />} />
          <Route path="sports/live/event" element={<EventLive />} />
          <Route path="sports/football" element={<Football />} />
          <Route path="sports/football/league/:id" render={(props) => <FootballCompetition {...props} />} />


          <Route path="sports/basketball" element={<SportPage id='18' icon={<MdSportsBasketball />} name='Basketball' abr='B' path='basketball' />} />
          <Route path="sports/basketball/event" element={<SportEvent id='18' icon={<MdSportsBasketball />} name='Basketball' abr='B' path='basketball' />} />

          <Route path="sports/tennis" element={<SportPage id='13' icon={<GiTennisBall />} name='Tennis' abr='T' path='tennis' />} />
          <Route path="sports/tennis/event" element={<SportEvent id='13' icon={<GiTennisBall />} name='Tennis' abr='T' path='tennis' />} />

          <Route path="sports/volleyball" element={<SportPage id='91' icon={<GiVolleyballBall />} name='Volleyball' abr='V' path='volleyball' />} />
          <Route path="sports/volleyball/event" element={<SportEvent id='91' icon={<GiVolleyballBall />} name='Volleybal' abr='V' path='volleyball' />} />

          <Route path="sports/handball" element={<SportPage id='78' icon={<MdSportsHandball />} name='Handball' abr='Ha' path='handball' />} />
          <Route path="sports/handball/event" element={<SportEvent id='78' icon={<MdSportsHandball />} name='Handball' abr='Ha' path='handball' />} />

          <Route path="sports/baseball" element={<SportPage id='16' icon={<MdSportsBaseball />} name='Baseball' abr='Ba' path='baseball' />} />
          <Route path="sports/baseball/event" element={<SportEvent id='16' icon={<MdSportsBaseball />} name='Baseball' abr='Ba' path='baseball' />} />

          <Route path="sports/hockey" element={<SportPage id='17' icon={<MdSportsHockey />} name='Hockey sur glace' abr='Ho' path='hockey' />} />
          <Route path="sports/hockey/event" element={<SportEvent id='17' icon={<MdSportsHockey />} name='Hockey sur glace' abr='Ho' path='hockey' />} />

          <Route path="sports/american_football" element={<SportPage id='12' icon={<GiAmericanFootballHelmet />} name='Football Americain' abr='Fa' path='american_football' />} />
          <Route path="sports/american_football/event" element={<SportEvent id='12' icon={<GiAmericanFootballHelmet />} name='Football Americain' abr='Fa' path='american_football' />} />

          <Route path="sports/darts" element={<SportPage id='15' icon={<GiDart />} name='Fléchettes' abr='D' path='darts' />} />
          <Route path="sports/darts/event" element={<SportEvent id='15' icon={<GiDart />} name='Fléchettes' abr='D' path='darts' />} />

          <Route path="sports/table_tennis" element={<SportPage id='92' icon={<GiPingPongBat />} name='Tennis de Table' abr='Tt' path='table_tennis' />} />
          <Route path="sports/table_tennis/event" element={<SportEvent id='92' icon={<GiPingPongBat />} name='Tennis de Table' abr='Tt' path='table_tennis' />} />

          <Route path="sports/rugby" element={<SportPage id='8' icon={<MdSportsRugby />} name='Rugby' abr='R' path='rugby' />} />
          <Route path="sports/rugby/event" element={<SportEvent id='8' icon={<MdSportsRugby />} name='Rugby' abr='R' path='rugby' />} />

          <Route path="sports/football/league/:id" render={(props) => <FootballCompetition {...props} />} />

          <Route path="system/verify/retrait" element={<Retrait />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="lottery" element={<LotteryPage />} />
          <Route path="lottery/tchala" element={<TchalaPage />} />
          <Route path="lottery/play" element={<LotteryPlayPage />} />

        </Routes>

        <div style={{ position: 'fixed', zIndex: 2, top: 0, width: '100%' }} className='mb-6'>
          <Navbar />
        </div>
        <LiveInfo />

        <div style={{ position: 'fixed', zIndex: 95, top: 0, width: '100%', height: '100%' }} className='mb-6' hidden={!message.show}>
          <Message show={message.show} content={message.content} type={message.type} />
        </div>
        <div>
          {isOpen && (
            <div className="modal is-active">
              <div className="modal-background"></div>
              <div className="modal-content" style={{ width: '100%' }}>
                <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                <div style={{ backgroundColor: 'white' }} className='px-4 mt-2'>
                  <h4 >Top Competitions</h4>

                  <div
                    className="is-flex is-overflow-hidden mt-4"
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                      scrollbarWidth: 'none',
                      '-ms-overflow-style': 'none',
                    }}
                  >
                    <div className="columns is-flex-mobile" style={{ flexWrap: 'nowrap' }}>
                      {topCompetition.data ? topCompetition.data.map((v, i) => <div className="column" onClick={() => {
                        navigate('/sports/' + 'Football' + '?league=' + v.id)
                        closeModal()
                      }}>
                        <div className=''>

                          <div  className='is-flex is-justify-content-center'>
                            <div>
                              <img src={v.link || 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Trophy.svg/1581px-Trophy.svg.png'} alt="Thumbnail" style={{ width: '60px', height: '60px', objectFit: 'cover' }} />
                            </div>
                          </div>

                          <div className='is-flex is-justify-content-center' style={{ bottom: 0, width: '100%', textAlign: 'center', color: 'black', padding: '8px 0' }}>
                            <p className='help'>{v.name}</p>
                          </div>

                        </div>


                      </div>) : <p>Chargement</p>}
                      {/* Add more columns as needed */}
                    </div>
                  </div>

                </div>
                <div style={{ backgroundColor: 'white' }} className='px-4 mt-2 pb-2' hidden={!showSportList}>
                  <ElementList elements={sport_365.map((v, i) => <div className="card" style={{ display: 'block', backgroundColor: 'gold' }} onClick={() => {
                    setSportData({ id: v.id, name: v.sport, sport: v.sport })
                    setShowSportList(false)
                  }}>
                    <div className='has-text-centered help has-text-dark'>{v.icon}</div>
                    <div className='has-text-centered help has-text-dark'>{v.sport}</div>
                  </div>)} />
                </div>
                <div style={{ backgroundColor: 'white' }} className='px-4 mt-2 pb-2' hidden={showSportList}>
                  <div hidden={showSportList}>
                  </div>
                  <SportCompetitions id={sportData.id} name={sportData.name} sport={sportData.sport} setShowSportList={setShowSportList} dismiss={closeModal} />
                </div>

              </div>
            </div>
          )}
        </div>

        <div style={{ position: 'fixed', zIndex: 99, top: 0, width: '100%', height: '100%' }} className='mb-6' hidden={!view}>
          <Loading />
        </div>

        <div style={{ position: 'fixed', zIndex: 99, top: 0, width: '100%', height: '100%', marginLeft: '40%' }} hidden={!menuView}>
          <SideMenu />
        </div>
        <div className=''>

          {/* <div className='is-hidden-desktop'>
            <Fab
              icon={<IoMdCart />}
              alwaysShowTitle={true}
              text={`Panier (${text.length})`}
              onClick={() => setMessage({ show: true, content: 'Panier', type: 'panier' })}
              style={{ marginBottom: 4 }}
            >
            </Fab>
          </div> */}

        </div>


        <div className="card is-hidden-desktop" style={{ position: 'fixed', bottom: '0px', zIndex: 90, width: '100%', borderTop: '1px solid gray', backgroundColor: '#141E27' }}>
          <BottomTabs />
        </div>


        {/* <div className="card is-hidden-desktop" style={{ zIndex: 90, width: '100%', borderTop: '1px solid gray', backgroundColor: '#141E27' }}>
          <BottomTabs />
        </div> */}

      </div>

    } else {
      return <div className='px-4' style={{ backgroundColor: 'white' }}>
          <p className="is-size-1">Internal Server Error</p>
    <p>Sorry, but there was an internal server error. Please try again later.</p>
      </div>
    }
  }

  return <>{renderSite()}</>
}


// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path='/' element={<Layout />} />
//       </Routes>
//     </Router>
//   );
// }



export default App;
