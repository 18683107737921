import React, { useEffect, useState } from 'react'
import Column from '../components/column'

import Footer from '../components/footer'
import Navbar from '../components/navbar'
import LeftPane from '../components/left-pane'
import RightPane from '../components/right-pane'
import Tab from '../components/tab'
import img1 from '../assets/images/soccer.jpg'
import img2 from '../assets/images/basket.jpg'
import Slider from '../components/slider'
import img21 from '../assets/images/m1.jpg'
import img22 from '../assets/images/m2.jpg'
import { useRecoilValue } from 'recoil'
import { imageState } from '../store/bets_store'
import { contactData } from '../assets/data/contactData'







function Contact(props) {

    const image = useRecoilValue(imageState)



    return (
        <>
            <Navbar></Navbar>
            <div className="mx-2" style={{backgroundColor:'black'}} >
                <div className="" style={{ backgroundColor: '#252f5a' }}>
                    <div>
                        <div>
                            <div className="columns" style={{backgroundColor:'#252f5a'}}>
                                {/* <div
                                    className="column is-one-quarter is-hidden-touch"
                                    style={{ backgroundColor: '#252f5a' }}
                                > */}
                                    {/* <div className='px-1'>
                                        <button className='button is-primary is-fullwidth ' > Voir les cotes boostees </button>
                                        <img src={img1} className='mt-2' />
                                        <img src={img2} className='mt-2' />
                                    </div> */}

                     


                                {/* </div> */}
                                
                                {/* <div className="column">
                                    <div className="mt-2" style={{ color: 'white' }}>
                                        <Slider img1={image.img1} img2={image.img2} img3={image.img3} img4={image.img1} img5={image.img2} img6={image.img3} />
                                        <div className='pb-2 mt-2'>
                                            <Tab />
                                        </div>

                                    </div>
                                </div> */}
                                 <div dangerouslySetInnerHTML={{ __html: contactData }} style={{width:'100%'}} />
                                {/* <div
                                    className="column is-one-quarter is-hidden-touch"
                                    style={{ backgroundColor: '#252f5a' }}>
                                    <RightPane></RightPane>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Contact
