import React, { useEffect, useState } from 'react'
import { GiBasketballBall, GiHockey, GiSoccerBall, GiVolleyballBall } from 'react-icons/gi'
import { MdLiveTv, MdLock, MdSportsHandball, MdSportsRugby } from 'react-icons/md'
import Spinner from 'react-activity/dist/Spinner'
import { socket } from '../functions/socket'
import { useNavigate } from 'react-router-dom'
import AddBetComponent from '../functions/bet'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import * as dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil'
import userEvent from '@testing-library/user-event'
import axios from 'axios'
import LeftPane from './left-pane'
import RightPane from './right-pane'
import Slider from './slider'
import { liveState, textState } from '../store/bets_store'
import { bet_list_live } from '../assets/data/bet_list_live'
import { getPaysByCC } from '../functions/utils'

const ENDPOINT = "https://bet509.ml";

dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

const renderCote = (cote) => {
    if (parseFloat(cote) > 100) {
        return 100.00
    } else if (parseFloat(cote) * 0.99 > 1) {
        return (parseFloat(cote) * 0.99).toFixed(2)
    } else {
        return parseFloat(cote)
    }
}

const renderElapsed = (min) => {
    if (min !== 45) {
        return min + 1
    } else {
        return min
    }
}

function LiveMiddle() {


    const [result, setResult] = useRecoilState(liveState)
    const navigate = useNavigate()
    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart
    const getBet = (val) => {
        if (val) {
            if (result && val.odd.find(x => x.id == 59)) {
                //  alert(JSON.stringify(val))
                return val.odd.find(x => x.id == 59)
            } else {
                return { values: [{ odd: 1.00 }, { odd: 1.00 }, { odd: 1.00 }] }
            }
        }
    }

    const getDisplay = () => {
        if (getBet() == false) {
            return true
        } else {
            return false
        }
    }

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {
            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const renderMobile = () => {

        if (result === null) {
            return <div className='is-flex is-justify-content-center has-text-light mt-4'>
                {/* <Spinner color ='yellow'  /> */}
                <p>Chargement ....</p>
            </div>
        } else if (result.length == 0) {

            return (
                <div className='mt-4'>
                    <div className='is-flex is-justify-content-center  mt-4'>
                        <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher .</p>
                    </div>
                </div>
            )
        } else if (result !== null) {

            //return JSON.stringify(result)

            return (
                <div>
                    <div className=''>
                        <p className='has-text-light'> </p>
                        <div className='mt-1'>
                            {
                                result.map((val, i) => {
                                    return (
                                        <div className="mt-2">
                                            <p className="is-flex py-2" style={{ fontSize: '13.5px' }}>
                                                <small onClick={() => navigate('/sports/live/event?game=' + val.fixture.id)}><b> <GiSoccerBall /> {val.fixture.home.name} ({val.fixture.home.score}) </b>&nbsp; - &nbsp; <b> {val.fixture.away.name} ({val.fixture.away.score}) &nbsp; </b> <small className=''>  {val.fixture.league.name}</small></small>
                                            </p>

                                            <div className="px-2 columns is-mobile" style={{ backgroundColor: 'transparent', fontSize: '13.5px', borderBottom: '1px solid black' }}>
                                                <p className="column is-3">
                                                    <span className="ml-auto" ><small>{val.fixture.elapsed.min} m</small></span> <br />
                                                    <span className="ml-auto"><small>{val.fixture.elapsed.sec} s</small></span>
                                                </p>

                                                <div className="column is-auto is-flex is-justify-content-space-between">

                                                    <div class="field has-addons" onClick={() => addBet({
                                                        sport: 'F',
                                                        fixtureId: val.fixture.id,
                                                        country: val.fixture.league.name,
                                                        league: val.fixture.league.name,
                                                        homeTeam: val.fixture.home.name,
                                                        awayTeam: val.fixture.away.name,
                                                        timestamp: val.timestamp,
                                                        betName: 'Resultat final',
                                                        betChoose: '1',
                                                        cote: val.bets.odds[0].odds > 1.01 ? renderCote(val.bets.odds[0].odds) : null,
                                                        amount: 0,
                                                        type: 'live',
                                                        data: val.bets,
                                                        pa_id: val.bets.odds[0].id
                                                    }, val.fixture.id)}>
                                                        <div class="control">
                                                            <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat final', betChoose: '1' })} />
                                                        </div>
                                                        <div class="control">
                                                            <a className="button is-small is-primary"  >
                                                                {val.bets.odds[0].odds > 1.01 ? renderCote(val.bets.odds[0].odds) : <MdLock />}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div class="field has-addons pl-2" onClick={() => addBet({
                                                        sport: 'F',
                                                        fixtureId: val.fixture.id,
                                                        country: val.fixture.league.name,
                                                        league: val.fixture.league.name,
                                                        homeTeam: val.fixture.home.name,
                                                        awayTeam: val.fixture.away.name,
                                                        timestamp: val.timestamp,
                                                        betName: 'Resultat final',
                                                        betChoose: 'X',
                                                        cote: val.bets.odds[1].odds > 1.01 ? renderCote(val.bets.odds[1].odds) : null,
                                                        amount: 0,
                                                        type: 'live',
                                                        data: val.bets,
                                                        pa_id: val.bets.odds[1].id
                                                    }, val.fixture.id)}>
                                                        <div class="control">
                                                            <input className="input is-small" type="text" value="X" readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat final', betChoose: 'X' })} />
                                                        </div>
                                                        <div class="control">
                                                            <a className="button is-small is-primary" >
                                                                {val.bets.odds[1].odds > 1.01 ? renderCote(val.bets.odds[1].odds) : <MdLock />}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div class="field has-addons pl-2" onClick={() => addBet({
                                                        sport: 'F',
                                                        fixtureId: val.fixture.id,
                                                        country: val.fixture.league.name,
                                                        league: val.fixture.league.name,
                                                        homeTeam: val.fixture.home.name,
                                                        awayTeam: val.fixture.away.name,
                                                        timestamp: val.timestamp,
                                                        betName: 'Resultat final',
                                                        betChoose: '2',
                                                        cote: val.bets.odds[2].odds > 1.01 ? renderCote(val.bets.odds[2].odds) : null,
                                                        amount: 0,
                                                        type: 'live',
                                                        data: val.bets,
                                                        pa_id: val.bets.odds[2].id
                                                    }, val.fixture.id)}>
                                                        <div class="control">
                                                            <input className="input is-small" typse="text" value='2' readOnly style={getInputStyle(val.fixture.id, { betName: 'Resultat final', betChoose: '2' })} />
                                                        </div>
                                                        <div class="control">
                                                            <a className="button  is-primary is-small" >
                                                                {val.bets.odds[2].odds > 1.01 ? renderCote(val.bets.odds[2].odds) : <MdLock />}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className=" is-flex pl-2">
                                                        <div onClick={() => navigate('/sports/live/event?game=' + val.fixture.id)} >
                                                            <p style={{ fontSize: '12px' }}>{"Voir"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            )
        }
    }
    return (
        <>
            <div className="">
                {/* <div className="is-hidden-touch" hidden>
                    <div>
                        <div>
                            <div className="columns">

                                <div className="column is-3"  style={{ backgroundColor: '#252f5a' }} hidden >
                                   
                                </div>

                                <div className="column is-6" style={{ backgroundColor: '#141E27' }}>
                                    <p className="mt-2">
                                        <p className='has-text-centered is-size-5 p-4 has-text-light' > Live </p>

                                        <div className='is-flex is-justify-content-center is-align-items-center'>
                                            <p>
                                                {
                                                    renderMobile()
                                                }

                                            </p>
                                        </div>

                                    </p>
                                </div>

                                <div className="column is-3"  style={{ backgroundColor: '#252f5a' }} hidden>
                                    <RightPane />
                                </div>


                            </div>
                        </div>

                    </div>
                </div> */}
                <div className="has-text-light" style={{ overflowY: 'auto', height: '150vh' }}>
                    <div className=''>
                        <Slider />
                    </div>

                    <div className='card mt-2' style={{ backgroundColor: '#EAEAEA' }}>
                        <header class="card-header">
                            <p class="card-header-title has-text-dark">
                                <p className='has-text-centered is-size-5 has-text-dark mt-2' > Live <span className=''><MdLiveTv /></span> </p>
                            </p>
                        </header>
                        <div className=' pb-2 px-2'>
                            {
                                renderMobile()
                            }
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default LiveMiddle
