export const pays = {
    success: 1,
    results: [
      {
        cc: "ad",
        name: "Andorre",
        orig_name: "Andorra"
      },
      {
        cc: "ae",
        name: "Emirats Arabes Unis",
        orig_name: "United Arab Emirates"
      },
      {
        cc: "af",
        name: "Afghanistan",
        orig_name: "Afghanistan"
      },
      {
        cc: "ag",
        name: "Antigua-et-Barbuda",
        orig_name: "Antigua and Barbuda"
      },
      {
        cc: "ai",
        name: "Anguilla",
        orig_name: "Anguilla"
      },
      {
        cc: "al",
        name: "Albanie",
        orig_name: "Albania"
      },
      {
        cc: "am",
        name: "Arménie",
        orig_name: "Armenia"
      },
      {
        cc: "ao",
        name: "Angola",
        orig_name: "Angola"
      },
      {
        cc: "aq",
        name: "Antarctique",
        orig_name: "Antarctica"
      },
      {
        cc: "ar",
        name: "Argentine",
        orig_name: "Argentina"
      },
      {
        cc: "as",
        name: "Samoa américaines",
        orig_name: "American Samoa"
      },
      {
        cc: "at",
        name: "Autriche",
        orig_name: "Austria"
      },
      {
        cc: "au",
        name: "Australie",
        orig_name: "Australia"
      },
      {
        cc: "aw",
        name: "Aruba",
        orig_name: "Aruba"
      },
      {
        cc: "ax",
        name: "Iles Aland",
        orig_name: "Åland Islands"
      },
      {
        cc: "az",
        name: "Azerbaïdjan",
        orig_name: "Azerbaijan"
      },
      {
        cc: "ba",
        name: "Bosnie-Herzégovine",
        orig_name: "Bosnia & Herzegovina"
      },
      {
        cc: "bb",
        name: "Barbade",
        orig_name: "Barbados"
      },
      {
        cc: "bd",
        name: "Bengladesh",
        orig_name: "Bangladesh"
      },
      {
        cc: "be",
        name: "Belgique",
        orig_name: "Belgium"
      },
      {
        cc: "bf",
        name: "Burkina Faso",
        orig_name: "Burkina Faso"
      },
      {
        cc: "bg",
        name: "Bulgarie",
        orig_name: "Bulgaria"
      },
      {
        cc: "bh",
        name: "Bahreïn",
        orig_name: "Bahrain"
      },
      {
        cc: "bi",
        name: "Burundi",
        orig_name: "Burundi"
      },
      {
        cc: "bj",
        name: "Bénin",
        orig_name: "Benin"
      },
      {
        cc: "bl",
        name: "Saint Barthélemy",
        orig_name: "Saint Barthélemy"
      },
      {
        cc: "bm",
        name: "Bermudes",
        orig_name: "Bermuda"
      },
      {
        cc: "bn",
        name: "Brunéi",
        orig_name: "Brunei"
      },
      {
        cc: "bo",
        name: "Bolivie",
        orig_name: "Bolivia"
      },
      {
        cc: "bq",
        name: "Pays-Bas caribéens",
        orig_name: "Caribbean Netherlands"
      },
      {
        cc: "br",
        name: "Brésil",
        orig_name: "Brazil"
      },
      {
        cc: "bs",
        name: "Bahamas",
        orig_name: "Bahamas"
      },
      {
        cc: "bt",
        name: "Bhoutan",
        orig_name: "Bhutan"
      },
      {
        cc: "bv",
        name: "Île Bouvet",
        orig_name: "Bouvet Island"
      },
      {
        cc: "bw",
        name: "Bostwana",
        orig_name: "Botswana"
      },
      {
        cc: "by",
        name: "Biélorussie",
        orig_name: "Belarus"
      },
      {
        cc: "bz",
        name: "Bélize",
        orig_name: "Belize"
      },
      {
        cc: "ca",
        name: "Canada",
        orig_name: "Canada"
      },
      {
        cc: "cc",
        name: "Îles Cocos (Keeling)",
        orig_name: "Cocos (Keeling) Islands"
      },
      {
        cc: "cd",
        name: "Congo - Kinshasa",
        orig_name: "Congo - Kinshasa"
      },
      {
        cc: "cf",
        name: "République centrafricaine",
        orig_name: "Central African Republic"
      },
      {
        cc: "cg",
        name: "Congo-Brazzaville",
        orig_name: "Congo - Brazzaville"
      },
      {
        cc: "ch",
        name: "Suisse",
        orig_name: "Switzerland"
      },
      {
        cc: "ci",
        name: "Côte d'Ivoire",
        orig_name: "Côte d’Ivoire"
      },
      {
        cc: "ck",
        name: "les Îles Cook",
        orig_name: "Cook Islands"
      },
      {
        cc: "cl",
        name: "Chili",
        orig_name: "Chile"
      },
      {
        cc: "cm",
        name: "Cameroun",
        orig_name: "Cameroon"
      },
      {
        cc: "cn",
        name: "Chine",
        orig_name: "China"
      },
      {
        cc: "co",
        name: "Colombie",
        orig_name: "Colombia"
      },
      {
        cc: "cr",
        name: "Costa Rica",
        orig_name: "Costa Rica"
      },
      {
        cc: "cu",
        name: "Cuba",
        orig_name: "Cuba"
      },
      {
        cc: "cv",
        name: "Cap-Vert",
        orig_name: "Cape Verde"
      },
      {
        cc: "cw",
        name: "Curacao",
        orig_name: "Curaçao"
      },
      {
        cc: "cx",
        name: "L'île de noël",
        orig_name: "Christmas Island"
      },
      {
        cc: "cy",
        name: "Chypre",
        orig_name: "Cyprus"
      },
      {
        cc: "cz",
        name: "République tchèque",
        orig_name: "Czech Republic"
      },
      {
        cc: "de",
        name: "Allemagne",
        orig_name: "Germany"
      },
      {
        cc: "dj",
        name: "Djibouti",
        orig_name: "Djibouti"
      },
      {
        cc: "dk",
        name: "Danemark",
        orig_name: "Denmark"
      },
      {
        cc: "dm",
        name: "Dominique",
        orig_name: "Dominica"
      },
      {
        cc: "do",
        name: "République dominicaine",
        orig_name: "Dominican Republic"
      },
      {
        cc: "dz",
        name: "Algérie",
        orig_name: "Algeria"
      },
      {
        cc: "ec",
        name: "Equateur",
        orig_name: "Ecuador"
      },
      {
        cc: "ee",
        name: "Estonie",
        orig_name: "Estonia"
      },
      {
        cc: "eg",
        name: "Egypte",
        orig_name: "Egypt"
      },
      {
        cc: "eh",
        name: "Sahara occidental",
        orig_name: "Western Sahara"
      },
      {
        cc: "er",
        name: "Érythrée",
        orig_name: "Eritrea"
      },
      {
        cc: "es",
        name: "Espagne",
        orig_name: "Spain"
      },
      {
        cc: "et",
        name: "Ethiopie",
        orig_name: "Ethiopia"
      },
      {
        cc: "fi",
        name: "Finlande",
        orig_name: "Finland"
      },
      {
        cc: "fj",
        name: "Fidji",
        orig_name: "Fiji"
      },
      {
        cc: "fk",
        name: "les îles Falkland",
        orig_name: "Falkland Islands"
      },
      {
        cc: "fm",
        name: "Micronésie",
        orig_name: "Micronesia"
      },
      {
        cc: "fo",
        name: "Îles Féroé",
        orig_name: "Faroe Islands"
      },
      {
        cc: "fr",
        name: "France",
        orig_name: "France"
      },
      {
        cc: "ga",
        name: "Gabon",
        orig_name: "Gabon"
      },
      {
        cc: "gb",
        name: "Angleterre",
        orig_name: "England"
      },
      {
        cc: "gd",
        name: "Grenade",
        orig_name: "Grenada"
      },
      {
        cc: "ge",
        name: "Géorgie",
        orig_name: "Georgia"
      },
      {
        cc: "gf",
        name: "Guyane Française",
        orig_name: "French Guiana"
      },
      {
        cc: "gg",
        name: "Guernesey",
        orig_name: "Guernsey"
      },
      {
        cc: "gh",
        name: "Ghana",
        orig_name: "Ghana"
      },
      {
        cc: "gi",
        name: "Gibraltar",
        orig_name: "Gibraltar"
      },
      {
        cc: "gl",
        name: "Groenland",
        orig_name: "Greenland"
      },
      {
        cc: "gm",
        name: "Gambie",
        orig_name: "Gambia"
      },
      {
        cc: "gn",
        name: "Guinée",
        orig_name: "Guinea"
      },
      {
        cc: "gp",
        name: "Guadeloupe",
        orig_name: "Guadeloupe"
      },
      {
        cc: "gq",
        name: "Guinée Équatoriale",
        orig_name: "Equatorial Guinea"
      },
      {
        cc: "gr",
        name: "Grèce",
        orig_name: "Greece"
      },
      {
        cc: "gs",
        name: "Géorgie du Sud et Îles Sandwich du Sud",
        orig_name: "South Georgia & South Sandwich Islands"
      },
      {
        cc: "gt",
        name: "Guatemala",
        orig_name: "Guatemala"
      },
      {
        cc: "gu",
        name: "Guam",
        orig_name: "Guam"
      },
      {
        cc: "gw",
        name: "Guinée-Bissau",
        orig_name: "Guinea-Bissau"
      },
      {
        cc: "gy",
        name: "Guyane",
        orig_name: "Guyana"
      },
      {
        cc: "hk",
        name: "Hong Kong RAS Chine",
        orig_name: "Hong Kong SAR China"
      },
      {
        cc: "hm",
        name: "Îles Heard et McDonald",
        orig_name: "Heard & McDonald Islands"
      },
      {
        cc: "hn",
        name: "Honduras",
        orig_name: "Honduras"
      },
      {
        cc: "hr",
        name: "Croatie",
        orig_name: "Croatia"
      },
      {
        cc: "ht",
        name: "Haïti",
        orig_name: "Haiti"
      },
      {
        cc: "hu",
        name: "Hongrie",
        orig_name: "Hungary"
      },
      {
        cc: "id",
        name: "Indonésie",
        orig_name: "Indonesia"
      },
      {
        cc: "ie",
        name: "Irlande",
        orig_name: "Ireland"
      },
      {
        cc: "il",
        name: "Israël",
        orig_name: "Israel"
      },
      {
        cc: "im",
        name: "île de Man",
        orig_name: "Isle of Man"
      },
      {
        cc: "in",
        name: "Inde",
        orig_name: "India"
      },
      {
        cc: "io",
        name: "Territoire britannique de l'océan Indien",
        orig_name: "British Indian Ocean Territory"
      },
      {
        cc: "iq",
        name: "Irak",
        orig_name: "Iraq"
      },
      {
        cc: "ir",
        name: "Iran",
        orig_name: "Iran"
      },
      {
        cc: "is",
        name: "Islande",
        orig_name: "Iceland"
      },
      {
        cc: "it",
        name: "Italie",
        orig_name: "Italy"
      },
      {
        cc: "je",
        name: "Jersey",
        orig_name: "Jersey"
      },
      {
        cc: "jm",
        name: "Jamaïque",
        orig_name: "Jamaica"
      },
      {
        cc: "jo",
        name: "Jordan",
        orig_name: "Jordan"
      },
      {
        cc: "jp",
        name: "Japon",
        orig_name: "Japan"
      },
      {
        cc: "ke",
        name: "Kenya",
        orig_name: "Kenya"
      },
      {
        cc: "kg",
        name: "Kirghizistan",
        orig_name: "Kyrgyzstan"
      },
      {
        cc: "kh",
        name: "Cambodge",
        orig_name: "Cambodia"
      },
      {
        cc: "ki",
        name: "Kiribati",
        orig_name: "Kiribati"
      },
      {
        cc: "km",
        name: "Comores",
        orig_name: "Comoros"
      },
      {
        cc: "kn",
        name: "Saint-Christophe-et-Niévès",
        orig_name: "Saint Kitts and Nevis"
      },
      {
        cc: "kp",
        name: "Corée du Nord",
        orig_name: "North Korea"
      },
      {
        cc: "kr",
        name: "Corée du Sud",
        orig_name: "South Korea"
      },
      {
        cc: "kw",
        name: "Koweit",
        orig_name: "Kuwait"
      },
      {
        cc: "ky",
        name: "Îles Caïmans",
        orig_name: "Cayman Islands"
      },
      {
        cc: "kz",
        name: "Kazakhstan",
        orig_name: "Kazakhstan"
      },
      {
        cc: "la",
        name: "Laos",
        orig_name: "Laos"
      },
      {
        cc: "lb",
        name: "Liban",
        orig_name: "Lebanon"
      },
      {
        cc: "lc",
        name: "Sainte-Lucie",
        orig_name: "Saint Lucia"
      },
      {
        cc: "li",
        name: "Liechtenstein",
        orig_name: "Liechtenstein"
      },
      {
        cc: "lk",
        name: "Sri Lanka",
        orig_name: "Sri Lanka"
      },
      {
        cc: "lr",
        name: "Libéria",
        orig_name: "Liberia"
      },
      {
        cc: "ls",
        name: "Lesotho",
        orig_name: "Lesotho"
      },
      {
        cc: "lt",
        name: "Lituanie",
        orig_name: "Lithuania"
      },
      {
        cc: "lu",
        name: "Luxembourg",
        orig_name: "Luxembourg"
      },
      {
        cc: "lv",
        name: "Lettonie",
        orig_name: "Latvia"
      },
      {
        cc: "ly",
        name: "Libye",
        orig_name: "Libya"
      },
      {
        cc: "ma",
        name: "Maroc",
        orig_name: "Morocco"
      },
      {
        cc: "mc",
        name: "Monaco",
        orig_name: "Monaco"
      },
      {
        cc: "md",
        name: "Moldavie",
        orig_name: "Moldova"
      },
      {
        cc: "me",
        name: "Monténégro",
        orig_name: "Montenegro"
      },
      {
        cc: "mf",
        name: "Saint Martin",
        orig_name: "Saint Martin"
      },
      {
        cc: "mg",
        name: "Madagascar",
        orig_name: "Madagascar"
      },
      {
        cc: "mh",
        name: "Iles Marshall",
        orig_name: "Marshall Islands"
      },
      {
        cc: "mk",
        name: "Macédoine",
        orig_name: "Macedonia"
      },
      {
        cc: "ml",
        name: "Mali",
        orig_name: "Mali"
      },
      {
        cc: "mm",
        name: "Myanmar (Birmanie)",
        orig_name: "Myanmar (Burma)"
      },
      {
        cc: "mn",
        name: "Mongolie",
        orig_name: "Mongolia"
      },
      {
        cc: "mo",
        name: "Macao RAS Chine",
        orig_name: "Macau SAR China"
      },
      {
        cc: "mp",
        name: "Îles Mariannes du Nord",
        orig_name: "Northern Mariana Islands"
      },
      {
        cc: "mq",
        name: "Martinique",
        orig_name: "Martinique"
      },
      {
        cc: "mr",
        name: "Mauritanie",
        orig_name: "Mauritania"
      },
      {
        cc: "ms",
        name: "Montserrat",
        orig_name: "Montserrat"
      },
      {
        cc: "mt",
        name: "Malte",
        orig_name: "Malta"
      },
      {
        cc: "mu",
        name: "Maurice",
        orig_name: "Mauritius"
      },
      {
        cc: "mv",
        name: "Maldives",
        orig_name: "Maldives"
      },
      {
        cc: "mw",
        name: "Malawi",
        orig_name: "Malawi"
      },
      {
        cc: "mx",
        name: "Mexique",
        orig_name: "Mexico"
      },
      {
        cc: "my",
        name: "Malaisie",
        orig_name: "Malaysia"
      },
      {
        cc: "mz",
        name: "Mozambique",
        orig_name: "Mozambique"
      },
      {
        cc: "na",
        name: "Namibie",
        orig_name: "Namibia"
      },
      {
        cc: "nc",
        name: "Nouvelle Calédonie",
        orig_name: "New Caledonia"
      },
      {
        cc: "ne",
        name: "Niger",
        orig_name: "Niger"
      },
      {
        cc: "nf",
        name: "l'ile de Norfolk",
        orig_name: "Norfolk Island"
      },
      {
        cc: "ng",
        name: "Nigeria",
        orig_name: "Nigeria"
      },
      {
        cc: "ni",
        name: "Nicaragua",
        orig_name: "Nicaragua"
      },
      {
        cc: "nl",
        name: "Pays-Bas",
        orig_name: "Netherlands"
      },
      {
        cc: "no",
        name: "Norvège",
        orig_name: "Norway"
      },
      {
        cc: "np",
        name: "Népal",
        orig_name: "Nepal"
      },
      {
        cc: "nr",
        name: "Nauru",
        orig_name: "Nauru"
      },
      {
        cc: "nu",
        name: "Niué",
        orig_name: "Niue"
      },
      {
        cc: "nz",
        name: "Nouvelle-Zélande",
        orig_name: "New Zealand"
      },
      {
        cc: "om",
        name: "Oman",
        orig_name: "Oman"
      },
      {
        cc: "pa",
        name: "Panama",
        orig_name: "Panama"
      },
      {
        cc: "pe",
        name: "Pérou",
        orig_name: "Peru"
      },
      {
        cc: "pf",
        name: "Polynésie française",
        orig_name: "French Polynesia"
      },
      {
        cc: "pg",
        name: "Papouasie Nouvelle Guinée",
        orig_name: "Papua New Guinea"
      },
      {
        cc: "ph",
        name: "Philippines",
        orig_name: "Philippines"
      },
      {
        cc: "pk",
        name: "Pakistan",
        orig_name: "Pakistan"
      },
      {
        cc: "pl",
        name: "Pologne",
        orig_name: "Poland"
      },
      {
        cc: "pm",
        name: "Saint-Pierre-et-Miquelon",
        orig_name: "Saint Pierre and Miquelon"
      },
      {
        cc: "pn",
        name: "Îles Pitcairn",
        orig_name: "Pitcairn Islands"
      },
      {
        cc: "pr",
        name: "Porto Rico",
        orig_name: "Puerto Rico"
      },
      {
        cc: "ps",
        name: "Territoires palestiniens",
        orig_name: "Palestinian Territories"
      },
      {
        cc: "pt",
        name: "Portugal",
        orig_name: "Portugal"
      },
      {
        cc: "pw",
        name: "Palaos",
        orig_name: "Palau"
      },
      {
        cc: "py",
        name: "Paraguay",
        orig_name: "Paraguay"
      },
      {
        cc: "qa",
        name: "Qatar",
        orig_name: "Qatar"
      },
      {
        cc: "re",
        name: "Réunion",
        orig_name: "Réunion"
      },
      {
        cc: "ro",
        name: "Roumanie",
        orig_name: "Romania"
      },
      {
        cc: "rs",
        name: "Serbie",
        orig_name: "Serbia"
      },
      {
        cc: "ru",
        name: "Russie",
        orig_name: "Russia"
      },
      {
        cc: "rw",
        name: "Rwanda",
        orig_name: "Rwanda"
      },
      {
        cc: "sa",
        name: "Arabie Saoudite",
        orig_name: "Saudi Arabia"
      },
      {
        cc: "sb",
        name: "Les îles Salomon",
        orig_name: "Solomon Islands"
      },
      {
        cc: "sc",
        name: "les Seychelles",
        orig_name: "Seychelles"
      },
      {
        cc: "sd",
        name: "Soudan",
        orig_name: "Sudan"
      },
      {
        cc: "se",
        name: "Suède",
        orig_name: "Sweden"
      },
      {
        cc: "sg",
        name: "Singapour",
        orig_name: "Singapore"
      },
      {
        cc: "sh",
        name: "Sainte-Hélène",
        orig_name: "Saint Helena"
      },
      {
        cc: "si",
        name: "Slovénie",
        orig_name: "Slovenia"
      },
      {
        cc: "sj",
        name: "Svalbard et Jan Mayen",
        orig_name: "Svalbard and Jan Mayen"
      },
      {
        cc: "sk",
        name: "Slovaquie",
        orig_name: "Slovakia"
      },
      {
        cc: "sl",
        name: "Sierra Leone",
        orig_name: "Sierra Leone"
      },
      {
        cc: "sm",
        name: "Saint Marin",
        orig_name: "San Marino"
      },
      {
        cc: "sn",
        name: "Sénégal",
        orig_name: "Senegal"
      },
      {
        cc: "so",
        name: "Somalie",
        orig_name: "Somalia"
      },
      {
        cc: "sr",
        name: "Suriname",
        orig_name: "Suriname"
      },
      {
        cc: "ss",
        name: "Soudan du sud",
        orig_name: "South Sudan"
      },
      {
        cc: "st",
        name: "São Tomé et Príncipe",
        orig_name: "São Tomé and Príncipe"
      },
      {
        cc: "sv",
        name: "Salvador",
        orig_name: "El Salvador"
      },
      {
        cc: "sx",
        name: "Saint-Martin",
        orig_name: "Sint Maarten"
      },
      {
        cc: "sy",
        name: "Syrie",
        orig_name: "Syria"
      },
      {
        cc: "sz",
        name: "Swaziland",
        orig_name: "Swaziland"
      },
      {
        cc: "tc",
        name: "îles Turques-et-Caïques",
        orig_name: "Turks and Caicos Islands"
      },
      {
        cc: "td",
        name: "Tchad",
        orig_name: "Chad"
      },
      {
        cc: "tf",
        name: "Terres australes françaises",
        orig_name: "French Southern Territories"
      },
      {
        cc: "tg",
        name: "Togo",
        orig_name: "Togo"
      },
      {
        cc: "th",
        name: "Thaïlande",
        orig_name: "Thailand"
      },
      {
        cc: "tj",
        name: "Tadjikistan",
        orig_name: "Tajikistan"
      },
      {
        cc: "tk",
        name: "Tokélaou",
        orig_name: "Tokelau"
      },
      {
        cc: "tl",
        name: "Timor oriental",
        orig_name: "Timor-Leste"
      },
      {
        cc: "tm",
        name: "Turkménistan",
        orig_name: "Turkmenistan"
      },
      {
        cc: "tn",
        name: "Tunisie",
        orig_name: "Tunisia"
      },
      {
        cc: "to",
        name: "Tonga",
        orig_name: "Tonga"
      },
      {
        cc: "tr",
        name: "Turquie",
        orig_name: "Turkey"
      },
      {
        cc: "tt",
        name: "Trinité-et-Tobago",
        orig_name: "Trinidad and Tobago"
      },
      {
        cc: "tv",
        name: "Tuvalu",
        orig_name: "Tuvalu"
      },
      {
        cc: "tw",
        name: "Taïwan",
        orig_name: "Taiwan"
      },
      {
        cc: "tz",
        name: "Tanzanie",
        orig_name: "Tanzania"
      },
      {
        cc: "ua",
        name: "Ukraine",
        orig_name: "Ukraine"
      },
      {
        cc: "ug",
        name: "Ouganda",
        orig_name: "Uganda"
      },
      {
        cc: "um",
        name: "Îles périphériques des États-Unis",
        orig_name: "U.S. Outlying Islands"
      },
      {
        cc: "us",
        name: "Etats-Unis",
        orig_name: "USA"
      },
      {
        cc: "uy",
        name: "Uruguay",
        orig_name: "Uruguay"
      },
      {
        cc: "uz",
        name: "Ouzbékistan",
        orig_name: "Uzbekistan"
      },
      {
        cc: "va",
        name: "Cité du Vatican",
        orig_name: "Vatican City"
      },
      {
        cc: "vc",
        name: "Saint-Vincent-et-les Grenadines",
        orig_name: "St. Vincent & Grenadines"
      },
      {
        cc: "ve",
        name: "Venezuela",
        orig_name: "Venezuela"
      },
      {
        cc: "vg",
        name: "Îles Vierges britanniques",
        orig_name: "British Virgin Islands"
      },
      {
        cc: "vi",
        name: "Îles Vierges américaines",
        orig_name: "U.S. Virgin Islands"
      },
      {
        cc: "vn",
        name: "Viêt Nam",
        orig_name: "Vietnam"
      },
      {
        cc: "vu",
        name: "Vanuatu",
        orig_name: "Vanuatu"
      },
      {
        cc: "wf",
        name: "Wallis et Futuna",
        orig_name: "Wallis and Futuna"
      },
      {
        cc: "ws",
        name: "Samoa",
        orig_name: "Samoa"
      },
      {
        cc: "xk",
        name: "Kosovo",
        orig_name: "Kosovo"
      },
      {
        cc: "ye",
        name: "Yémen",
        orig_name: "Yemen"
      },
      {
        cc: "yt",
        name: "Mayotte",
        orig_name: "Mayotte"
      },
      {
        cc: "za",
        name: "Afrique du Sud",
        orig_name: "South Africa"
      },
      {
        cc: "zm",
        name: "Zambie",
        orig_name: "Zambia"
      },
      {
        cc: "zw",
        name: "Zimbabwe",
        orig_name: "Zimbabwe"
      }
    ].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
    
      if (nameA < nameB) {
        return -1;
      }
    
      if (nameA > nameB) {
        return 1;
      }
    
      return 0;
    })
  }