import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const DateInputModal = ({ isOpen, onRequestClose, onDateSelected }) => {
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleSubmit = () => {
        const [year, month, day] = selectedDate.split("-");
        const formattedDate = `${month}-${day}-${year}`;
        onDateSelected(formattedDate);
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Date Input Modal"
            className="ReactModal__Content"
        >
            <h2>Entrez la date</h2>
            <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="DateInputModal__Input"
            />
            <button className='button is-primary' onClick={handleSubmit}>OK</button>
            <button className='button is-light mt-2' onClick={onRequestClose}>Cancel</button>
        </Modal>
    );
};

export default DateInputModal;
