import {
  atom,
} from 'recoil';
import { getToken } from '../functions/auth';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()


export const textState = atom({
  key: 'textState', // unique ID (with respect to other atoms/selectors)
  default: [],
  effects_UNSTABLE: [persistAtom]
});
export const cartTypeState = atom({
  key: 'cartTypeState', // unique ID (with respect to other atoms/selectors)
  default: {
    type: 'combo',
    combo: {
      hidden: false,
      class: 'is-active',
    },
    simple: {
      hidden: true,
      class: '',
    }
  },
  effects_UNSTABLE: [persistAtom]
});

const verifState = () => {
  if (getToken().token !== null) {
    return true
  } else {
    return false
  }
}

export const is_connected = atom({
  key: 'is_connected', // unique ID (with respect to other atoms/selectors)
  default: verifState(),
});

export const oneCompetition = atom({
  key: 'oneCompetition', // unique ID (with respect to other atoms/selectors)
  default: [],
  dangerouslyAllowMutability: true
});

export const isNextClickState = atom({
  key: 'isNextClickState', // unique ID (with respect to other atoms/selectors)
  default: false,
});

export const betButtonState = atom({
  key: 'betButtonState', // unique ID (with respect to other atoms/selectors)
  default: { disabled: false, text: 'Placer mon pari' },
});

export const messageState = atom({
  key: 'message', // unique ID (with respect to other atoms/selectors)
  default: {show: false , content:<div> </div>, type : 'message'},
});

export const payState = atom({
  key: 'pay', // unique ID (with respect to other atoms/selectors)
  default: false,
});

export const liveState = atom({
  key: 'live_sport_pos', // unique ID (with respect to other atoms/selectors)
  default: null,
});

export const imageState = atom({
  key: 'pjparyaj_image', // unique ID (with respect to other atoms/selectors)
  default: {}
});


export const menuViewState = atom({
  key: 'pjparyaj_menu', // unique ID (with respect to other atoms/selectors)
  default: false
});

export const borletteState = atom({
  key: 'pjborletteState',
  default: Array.from({ length: 30 }, () => ({ number: '', amount: '' })),
});

export const mariageState = atom({
  key: 'pjmariageState',
  default: Array.from({ length: 50 }, () => ({ number1: '', number2: '', amount: '' })), 
});

export const lotto3State = atom({
  key: 'pjlotto3State',
  default: Array.from({ length: 30 }, () => ({ number: '', amount: '' })),
});

export const lotto4State = atom({
  key: 'pjlotto4State',
  default: Array.from({ length: 30 }, () => ({ number: '', amount: '', option :'' })),
});

export const lotto5State = atom({
  key: 'pjlotto5State',
  default: Array.from({ length: 30 }, () => ({ number: '', amount: '', option :'' })),
});

export const sportIsOpenState = atom({
  key: 'sport_is_open_pj', // unique ID (with respect to other atoms/selectors)
  default: false,
});
