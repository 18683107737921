import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useRecoilState } from 'recoil';
import { textState, isNextClickState } from '../store/bets_store';
import { cartTypeState } from '../store/bets_store';
import { GiBasketballBall } from 'react-icons/gi';
import { getDailyBasketballFixtures, getSingleGame } from '../functions/api';
import * as dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr-ca'; // import locale
import Spinner from 'react-activity/dist/Spinner';
import Slider from './slider'
import AddBetComponent from '../functions/bet'

dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


function BasketballMiddle() {
    
    const [basketFixtures, setBasketFixtures] = useState(null);
    const [page, setPage] = useState(1)
    const [resultLength, setResultLength] = useState(0);
    const [oddsArr, setOddsArr] = useState([])
    const [theArr, setTheArr] = useState(null)
    const [renderButtonText, setRenderButtonText] = useState({ text: 'Charger plus de resultats', disabled: false })
    const [searchParams, setSearchParams] = useSearchParams();
    const getLeague = () => {
        if (parseInt(searchParams.get("league")) > 0) {
            return parseInt(searchParams.get("league"))
        }
        return 0
    }
    const league = getLeague()
    const [isNextClick, setIsNextClick] = useRecoilState(isNextClickState)

    const filteredArray = (arr) => arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

    const navigate = useNavigate()
    
    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart

    const setTheBasket = (res) => {
        if (basketFixtures) {
            const newArr = basketFixtures.data.concat(res.data)
            const arr = []
            for (const el of newArr) {
                if (league > 0) {
                    if (el.league.id == league) {
                        arr.push(el)
                    }
                } else {
                    arr.push(el)
                }
            }
            setBasketFixtures({ data: filteredArray(arr) })
        } else {

            const arr = []
            for (const el of res.data) {
                if (league > 0) {
                    if (el.league.id == league) {
                        arr.push(el)
                    }
                } else {
                    arr.push(el)
                }
            }
            setBasketFixtures({ ...res, data: filteredArray(arr) })
        }
    }

    useEffect(() => {
        if (league === 0 && isNextClick === true) {
            getDailyBasketballFixtures(page, setTheBasket, setResultLength, dayjs.utc().unix(), null)
        } else if (league === 0 && isNextClick === false) {
            setBasketFixtures(null)
            getDailyBasketballFixtures(1, setTheBasket, setResultLength, dayjs.utc().unix(), null)
        }
        else if (league > 0 && isNextClick === false) {
            setBasketFixtures(null)
            getDailyBasketballFixtures(1, setTheBasket, setResultLength, dayjs.utc().unix(), parseInt(league))
        } else if (league > 0 && isNextClick === true) {
            getDailyBasketballFixtures(page, setTheBasket, setResultLength, dayjs.utc().unix(), parseInt(league))
        }
    }, [page, league]);



    useEffect(() => {
        if (basketFixtures) {
            const arr = basketFixtures.data.map((v, i) => v.id)
            //getDailyBasketballFixtures(page, setBasketFixtures, closeLoader, setResultLength)
            getSingleGame('basketball', arr, setOddsArr)
            //alert(JSON.stringify(arr))
            setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
            if (page > 4) {
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: true })
            } else {
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
            }
        }
    }, [basketFixtures]);

    useEffect(() => {
        if (oddsArr !== 'nada' && oddsArr.length >= 1) {
            const theArrVar = (basketFixtures, oddsArr) => {
                const arr = oddsArr.map((v, i) => v.id)
                const res = []
                let i = 0, len = basketFixtures.data.length

                while (i < len) {
                    if (arr.includes(basketFixtures.data[i].id)) {
                        res.push(basketFixtures.data[i])
                    }

                    i++
                }

                return res
            }

            setTheArr(theArrVar(basketFixtures, oddsArr))

        } else if (oddsArr == 'nada') {
            setTheArr([])
        } else {
            setTheArr(null)

        }
    }, [oddsArr])


    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#FFC900', color: 'black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const paginate = () => {
        if (theArr && oddsArr) {

            if (oddsArr !== 'nada') {
                //openLoad()
                setRenderButtonText({ text: <Spinner color='#FFC900' />, disabled: true })
                setPage(page + 1)


            }
        }
    }

    const getBet = (arr, id, bet) => {

        if (Array.isArray(arr)) {

            const obj = arr.find(x => x.id == id)
            if (obj && obj.bets) {
                return { odd: obj.bets.values[bet].odd, total: obj.total }
            } else {

                return {
                    odd: 'error',
                    total: 'error'
                }
            }
        } else {
            return <Spinner color='#FFC900' />
        }
    }

    const renderButton = () => {
        if (theArr && theArr.length >= 1) {
            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => paginate()} disabled={renderButtonText.disabled}>{renderButtonText.text}</button>
                </div>
            )
        }
    }

    // const getCompetition = () => {
    //     if (league > 0) {
    //         if (fixtures.odds.length > 0) {
    //             return (
    //                 <p> <img alt={getObject(fixtures.odds[0].id).league.name.slice(0).toUpperCase()} src={getObject(fixtures.odds[0].id).league.flag} width={16} height={16} /> {getObject(fixtures.odds[0].id).league.name} - {getObject(fixtures.odds[0].id).league.country} </p>
    //             )
    //         }
    //         return '- Competition non-trouvee -'
    //     }
    //     return '- Toutes competitions -'
    // }


    const renderMobile = () => {
        if (theArr === null || oddsArr === null) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher</p>
                </div>
            )
        }


        return (

            theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <small><b> <GiBasketballBall /> {val.teams.home.name} - {val.teams.away.name} </b></small> <span className="ml-auto"><small> {dayjs.unix(val.timestamp).format('h:mm A')}</small></span>
                            </p>
                            <p className="is-flex">
                                <small> <img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small> <span className="ml-auto"> <small>{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')} </small></span>
                            </p>
                            <div className="is-flex is-justify-content-space-between">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: getBet(oddsArr, val.id, 0).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {getBet(oddsArr, val.id, 0).odd}
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: getBet(oddsArr, val.id, 1).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {getBet(oddsArr, val.id, 1).odd}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2">
                                    <div className=" is-flex" onClick={() => navigate('/sports/basketball/event?game=' + val.id)}>
                                        <p style={{ fontSize: '15px' }}>+{getBet(oddsArr, val.id, 0).total}</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                )
            })
        )
    }

    const renderDesktop = () => {
        if (!theArr) {

            return (
                <div className='is-hidden-touch is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher</p>
                </div>
            )
        }
        else {


            return theArr.map((val, i) => {

                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <span> <b> <GiBasketballBall /> {val.teams.home.name} - {val.teams.away.name} </b> </span> <span className="ml-auto">{dayjs.unix(val.timestamp).format('h:mm A')}</span>
                            </p>

                            <p className="is-flex">
                                <small><img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small>
                                <span className="ml-auto">{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')}</span>
                            </p>

                            <div className="is-flex is-justify-content-space-between px-4 mt-2">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: getBet(oddsArr, val.id, 0).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 0).odd}
                                        </a>
                                    </div>
                                </div>


                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: getBet(oddsArr, val.id, 1).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 1).odd}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2 mt-2">
                                    <div onClick={() => navigate('/sports/basketball/event?game=' + val.id)}>
                                        +{getBet(oddsArr, val.id, 0).total}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            })


        }
    }


    return (

        <>

            <div className='my-2'>
                <div className='is-flex is-justify-content-center is-hidden-mobile mt-2'>

                    <Slider />

                </div>

                <div className='is-flex is-justify-content-space-between is-hidden-tablet'>
                    <div className='mt-2'>
                        <Slider />
                    </div>

                </div>
                <div>
                    <div className='is-hidden-desktop'>
                        <hr />
                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered is-size-5 has-text-light'> <GiBasketballBall /> BasketBall</p>
                            </div>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered has-text-light'><small> - Toutes competitions -</small></p>
                            </div>
                            {
                                renderMobile()
                            }
                        </div>
                        {renderButton()}
                    </div>
                </div>
                <div>
                    <div className='is-hidden-touch'>
                        <hr />

                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center'>

                                <p className='has-text-centered is-size-5 has-text-light'> <GiBasketballBall /> BasketBall</p>
                            </div>
                            {
                                renderDesktop()
                            }
                        </div>
                        {renderButton()}
                    </div>
                </div>
            </div>


















        </>

    )
}

export default BasketballMiddle;
