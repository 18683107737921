/* eslint-disable no-unused-vars */
//functions


export const todayDate = new Date().toISOString().slice(0, 10)
export const matchResult = (home, away) => {

    if (home > away) {
        return 'home'
    } else if (home < away) {
        return 'away'
    } else {
        return 'draw'
    }
}

export const doubleChance = (home, away) => {

    if (home === away) {
        return 'home/away'
    }
    else if (home > away || home === away) {
        return 'home'
    } else if (away > home || away === home) {
        return 'away'
    }
}

export const doubleChanceAny = (home, away) => {

    if (away !== home) {
        return true
    } else {
        return false
    }
}

export const allTeamScore = (home, away) => {

    if (home > 0 && away > 0) {
        return true
    } else {
        return false
    }

}

export const numberOfgoals = (home, away) => {

    const totalGoals = home + away

    switch (totalGoals) {
        case 0.5:
            if (totalGoals >= 1) {
                return true
            } else {
                return false
            }

        case 1.5:
            if (totalGoals >= 2) {
                return true
            }
            else {
                return false
            }

        case 2.5:
            if (totalGoals >= 3) {
                return true
            } else {
                return false
            }

        case 3.5:
            if (totalGoals >= 4) {
                return true
            } else {
                return false
            }

        case 4.5:
            if (totalGoals >= 5) {
                return true
            } else {
                return false
            }

        case 5.5:
            if (totalGoals >= 6) {
                return true
            }
            else {
                return false
            }

        case -0.5:
            if (totalGoals < 1) {
                return true
            }
            else {
                return false
            }


        case -1.5:
            if (totalGoals < 2) {
                return true
            }
            else {
                return false
            }

        case -2.5:
            if (totalGoals < 3) {
                return true
            }
            else {
                return false
            }


        case -3.5:
            if (totalGoals < 4) {
                return true
            }
            else {
                return false
            }


        case -4.5:
            if (totalGoals < 5) {
                return true
            }
            else {
                return false
            }


        case -5.5:
            if (totalGoals < 6) {
                return true
            }
            else {
                return false
            }


        default:
            return false

    }
}

export const scorer = (id, fixtures) => {

    const matchScorers = []

    if (matchScorers.indexOf(id) !== -1) {
        return true
    } else {
        return false
    }
}

export const cornerVictory = (home, away) => {
    if (home > away) {
        return 'home'
    } else if (home === away) {
        return 'X'
    } else {
        return 'away'
    }
}

export const cornersNumber = (home, away, payload) => {

    const totalCorners = home + away

    switch (payload) {
        case 8.5:
            if (totalCorners >= 9) {
                return true
            } else {
                return false
            }

        case 9.5:
            if (totalCorners >= 10) {
                return true
            }
            else {
                return false
            }

        case 10.5:
            if (totalCorners >= 11) {
                return true
            } else {
                return false
            }

        case 11.5:
            if (totalCorners >= 12) {
                return true
            } else {
                return false
            }

        case 12.5:
            if (totalCorners >= 13) {
                return true
            } else {
                return false
            }

        case -8.5:
            if (totalCorners < 9) {
                return true
            }
            else {
                return false
            }


        case -9.5:
            if (totalCorners < 10) {
                return true
            }
            else {
                return false
            }

        case -10.5:
            if (totalCorners < 11) {
                return true
            }
            else {
                return false
            }


        case -11.5:
            if (totalCorners < 12) {
                return true
            }
            else {
                return false
            }


        case -12.5:
            if (totalCorners < 13) {
                return true
            }
            else {
                return false
            }


        default:
            return false

    }
}

export const TeamCornersNumber = (team, payload) => {

    const totalCorners = team

    switch (payload) {
        case '0-2':
            if (totalCorners < 2) {
                return true
            } else {
                return false
            }

        case '3-4':
            if (totalCorners > 2 && totalCorners <= 4) {
                return true
            }
            else {
                return false
            }

        case '5-6':
            if (totalCorners > 4 && totalCorners <= 6) {
                return true
            } else {
                return false
            }

        case '7+':
            if (totalCorners >= 7) {
                return true
            } else {
                return false
            }

        default:
            return false

    }
}

export const firstHalfResult = (home, away) => {

    if (home > away) {
        return 'home'
    } else if (home < away) {
        return 'away'
    } else {
        return 'draw'
    }
}

export const firstHalfAllTeamScore = (home, away) => {

    if (home > 0 && away > 0) {
        return true
    } else {
        return false
    }

}

export const firstHalfNumberOfGoals = (home, away) => {

    const totalGoals = home + away

    switch (totalGoals) {
        case 0.5:
            if (totalGoals >= 1) {
                return true
            } else {
                return false
            }

        case 1.5:
            if (totalGoals >= 2) {
                return true
            }
            else {
                return false
            }

        case 2.5:
            if (totalGoals >= 3) {
                return true
            } else {
                return false
            }

        case 3.5:
            if (totalGoals >= 4) {
                return true
            } else {
                return false
            }

        case 4.5:
            if (totalGoals >= 5) {
                return true
            } else {
                return false
            }

        case 5.5:
            if (totalGoals >= 6) {
                return true
            }
            else {
                return false
            }

        case -0.5:
            if (totalGoals < 1) {
                return true
            }
            else {
                return false
            }


        case -1.5:
            if (totalGoals < 2) {
                return true
            }
            else {
                return false
            }

        case -2.5:
            if (totalGoals < 3) {
                return true
            }
            else {
                return false
            }


        case -3.5:
            if (totalGoals < 4) {
                return true
            }
            else {
                return false
            }


        case -4.5:
            if (totalGoals < 5) {
                return true
            }
            else {
                return false
            }


        case -5.5:
            if (totalGoals < 6) {
                return true
            }
            else {
                return false
            }


        default:
            return false

    }
}

export const firstHalfExactNumberOfGoals = (home, away) => {

    const totalGoals = home + away

    switch (totalGoals) {
        case 0:
            if (totalGoals === 0) {
                return true
            } else {
                return false
            }

        case 1:
            if (totalGoals === 1) {
                return true
            }
            else {
                return false
            }

        case 2:
            if (totalGoals === 2) {
                return true
            } else {
                return false
            }

        case 3:
            if (totalGoals === 3) {
                return true
            } else {
                return false
            }

        case 4:
            if (totalGoals === 4) {
                return true
            } else {
                return false
            }

        case 5:
            if (totalGoals === 5) {
                return true
            }
            else {
                return false
            }

        case 6:
            if (totalGoals === 6) {
                return true
            }
            else {
                return false
            }

        default:
            return false

    }
}

export const secondHalfAllTeamScore = (home, away) => {

    if (home > 0 && away > 0) {
        return true
    } else {
        return false
    }

}

export const secondHalfExactNumberOfGoals = (home, away) => {

    const totalGoals = home + away

    switch (totalGoals) {
        case 0:
            if (totalGoals === 0) {
                return true
            } else {
                return false
            }

        case 1:
            if (totalGoals === 1) {
                return true
            }
            else {
                return false
            }

        case 2:
            if (totalGoals === 2) {
                return true
            } else {
                return false
            }

        case 3:
            if (totalGoals === 3) {
                return true
            } else {
                return false
            }

        case 4:
            if (totalGoals === 4) {
                return true
            } else {
                return false
            }

        case 5:
            if (totalGoals === 5) {
                return true
            }
            else {
                return false
            }

        case 6:
            if (totalGoals === 6) {
                return true
            }
            else {
                return false
            }

        default:
            return false

    }
}

const evenNumberOfGoals = (home, away) => {
    const totalGoals = home + away
    if (totalGoals % 2 === 0) {
        return true
    }
    else {
        return false
    }
}

const homeConcedesGoals = (away) => {
    if (away >= 1) {
        return true
    }
    else {

        return false
    }
}

const awayConcedesGoals = (home) => {
    if (home >= 1) {
        return true
    }
    else {

        return false
    }
}

const doubleChanceHomeAndAllTeamScore = (homeTeam, awayTeam) => {
    if (doubleChance(homeTeam, awayTeam) === 'home' && allTeamScore(homeTeam, awayTeam)) {
        return true
    } else {
        return false
    }

}

const doubleChanceAwayAndAllTeamScore = (homeTeam, awayTeam) => {
    if (doubleChance(homeTeam, awayTeam) === 'away' && allTeamScore(homeTeam, awayTeam)) {
        return true
    } else {
        return false
    }

}

const doubleChanceAnyAndAllTeamScore = (homeTeam, awayTeam) => {
    if (doubleChanceAny(homeTeam, awayTeam) && allTeamScore(homeTeam, awayTeam)) {
        return true
    } else {
        return false
    }

}

const matchResultHomeAndAllTeamScore = (homeTeam , awayTeam) => {

    if( matchResult(homeTeam, awayTeam) === 'home' && allTeamScore(homeTeam,awayTeam)){

        return true
    } else {
        return false
    }
  


}