/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Spinner } from 'react-activity'
import { useNavigate } from 'react-router-dom'
import { bet } from '../assets/data/paris'
import { dekesman, getBetcode, getBetHistory, getCashout, getTicketHistory, takeCashout } from '../functions/api'
import { getToken } from '../functions/auth'
import * as dayjs from 'dayjs'
import { IoMdPaper, IoMdTrophy } from 'react-icons/io';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import { error, success } from '../functions/toast'
import { useRecoilState } from 'recoil'
import { textState } from '../store/bets_store'
import { MdShare } from 'react-icons/md'
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

function TicketsHistoryMiddleMobile() {

    const [totalOdds, setTotalOdds] = useState(1)
    const [betList, setBetList] = useState(null)
    const [show, setShow] = useState('pending')
    const [cashout, setCashout] = useState([])
    const [double, setDouble] = useState(false);
    const [text, setText] = useRecoilState(textState)

    const [tickets, setTickets] = useState(null)

    const [income, setIncome] = useState(false)


    const handleBetcode = (betObj) => {
        if (betObj && betObj.data.length > 0 && betObj.data !== 'not_found') {
            setText(betObj.data)
        } else {
            error('Betcode non valide .')
        }
    }
    const [style, setStyle] = useState({
        enCours: 'has-text-primary',
        gagne: '',
        perdu: '',
        tout: ''
    })

    const navigate = useNavigate()

    const setTheCashout = (elem, x) => {
        const newCashout = cashout
        newCashout.push({ id: elem.id, cashout: x.data })
        setCashout(newCashout)
    }

    useEffect(() => {
        if (getToken().id !== 0) {
            getTicketHistory(getToken().id, setTickets)
        } else {
            navigate('/login')
        }
    }, [])

    const getText = (freebets, price, d) => {

        const newArr = []

        for (const elem of d.borlette) {
            if (elem.number) {
                newArr.push(`borlette ${elem.number} ->  ${elem.amount} HTG`)
            }
        }

        for (const elem of d.mariage) {
            if (elem.number1 && elem.number2) {
                newArr.push(`mariage ${elem.number1} x ${elem.number2} ->  ${elem.amount} HTG`);
            }
        }

        for (const elem of d.lotto3) {
            if (elem.number) {
                newArr.push(`lotto3 ${elem.number} ->  ${elem.amount} HTG`)
            }
        }

        for (const elem of d.lotto4) {
            newArr.push(`lotto4 ${elem.number} || ${elem.option} -> ${elem.amount} HTG`)

        }

        for (const elem of d.lotto5) {

            newArr.push(`lotto5 ${elem.number} || ${elem.option} -> ${elem.amount} HTG`)
        }
        if (freebets) {
            for (const elem of freebets) {
                newArr.push(`mariage ${elem.number ? elem.number.slice(0, 2) : elem.slice(0, 2)} x ${elem.number ? elem.number.slice(2) : elem.slice(2)} ->  Gratuit(1500)`);
            }
        }

        return newArr
    }


    const renderShow = (sho) => {
        if (tickets) {
            switch (sho) {
                case 'all':
                    return tickets.data
                case 'win':
                    return tickets.data.filter(x => x.result === 'win')
                case 'false':
                    return tickets.data.filter(x => x.result === 'false')
                case 'pending':
                    return tickets.data.filter(x => x.result === 'pending')

                default:
                    return []
                    break;
            }
        } else {
            return []
        }

    }





    const toggleTabsCss = (tab) => {
        if (tab === 'enCours') {
            setStyle({
                enCours: 'has-text-primary',
                gagne: '',
                perdu: '',
                tout: ''
            })
            setShow('pending')

        } else if (tab === 'gagne') {
            setStyle({
                enCours: '',
                gagne: 'has-text-primary',
                perdu: '',
                tout: ''
            })

            setShow('win')

        } else if (tab === 'perdu') {
            setStyle({
                enCours: '',
                gagne: '',
                perdu: 'has-text-primary',
                tout: ''
            })
            setShow('false')
        } else {
            setStyle({
                enCours: '',
                gagne: '',
                perdu: '',
                tout: 'has-text-primary'
            })
            setShow('all')
        }
    }
    const setOdd = () => {
        let theOdd = totalOdds

        bet.forEach(el => {
            theOdd = theOdd * el.odd
        });

        return Math.round(theOdd)
    }

    const renderColor = (result) => {
        switch (result) {
            case 'win':
                return '#198754'
            case 'false':
                return '#d9534f'
            case 'pending':
                return '#209cee'

            default:
                return 'yellow'

        }
    }


    const renderShare = (state, payment, mise, win, code) => {

        if (state === 'pending' && payment === 'main' && [...code].every(c => '0123456789'.includes(c))) {
            return (
                <MdShare size={24} onClick={() => location.href = 'whatsapp://send?text=La cote de ma selection de pari s\'élève à ' + parseFloat(win / mise).toFixed(2) + ' . Le code de mon coupon gagnant est ' + code + ' sur https://pjparyaj.com ou en cliquant sur ce lien : https://pjparyaj.com?betcode=' + code + ' .'} />
            )
        }

        //return [...code].every(c => '0123456789'.includes(c)).toString()
    }

    const handleDekesman = (res) => {
        if (res.data == 'success') {
            success('Encaissement effectuée avec success')
            setIncome(false)
            setTimeout(() => {
                history.go(0)
            }, 1000);
        } else {
            error(res.message)
        }
    }

    const renderIncome = (ticket) => {

        if (!ticket.dekesman && ticket.result == 'win') {
            return <div>
                <button disabled={income} className='button is-small is-success' onClick={() => {
                    setIncome(true)
                    dekesman(ticket.id, handleDekesman)
                }}> Encaisser</button>
            </div>
        }
    }

    const renderBetList = () => {


        // return JSON.stringify(tickets)

        if (tickets === null) {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>

        } else if (renderShow(show).length === 0) {

            return <div className='is-flex is-justify-content-center is-align-items-center'>
                <div className='mt-4 has-text-light'>
                    <p>Pas de pari disponible .</p><br />
                </div>
            </div>

        }
        else if (tickets !== null) {
            return renderShow(show).sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            }).map((v, i) =>

                <div class="card mt-4">
                    <header class="card-header is-flex" style={{ backgroundColor: renderColor(v.result), color: 'white' }}>
                        <span class="px-2" style={{ fontSize: '13px' }}>
                            <small>ID: {v.id}</small>
                        </span>
                        <span className='ml-auto pr-2 mt-1'>
                            {renderShare(v.result, v.payment, v.mise, v.win, v.betCode)}
                        </span>
                    </header>
                    <div class=" mt-2 mx-2 content">
                        <div>
                            {
                                v ? <div>
                                    <p className='help'>Lotri: {v.lottery} </p>
                                    <p className='help'>Tirage: {v.drawing} </p>
                                    <p className='help'>Date: {v.date} </p>
                                    <p className='help'>Mise: {v.mise} </p>
                                    <p className='help'>Gain: {v.win} </p>
                                </div> : '......'
                            }
                            {v ? getText(v.freebets, v.price, v.bets[0]).map((v, i) => <p className='help'>{v}</p>) : '....'}
                        </div>
                        <div class="mt-2 py-2" style={{ borderTop: '2px solid black' }}>
                            {renderIncome(v)}
                        </div>
                    </div>

                </div>
            )
        }
    }


    return (
        <>
            <p className='has-text-centered is-size-5 mt-4 has-text-light'><IoMdPaper /> Mes Tickets PJLOTTO</p>
            <div className=" mt-4
        is-flex is-justify-content-center has-text-light">
                <button class="button is-white is-small" onClick={() => toggleTabsCss('enCours')}><p className={style.enCours}>En cours</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('gagne')}><p className={style.gagne}>Gagné</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('perdu')}><p className={style.perdu}>Perdu</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('tout')}><p className={style.tout}>Tout</p></button>
            </div>

            <div className='mt-4'>
                {renderBetList()}
            </div>

        </>
    )
}

export default TicketsHistoryMiddleMobile
