import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ResultModal = ({ isOpen, onRequestClose, result }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Result Modal"
            className="ReactModal__Content"
        >
            <h2>Résultats</h2>
            <p className="ResultModal__ResultText">{result && result.data && result.data.result ? result.data.result.map((v, i) => <p>{v.lottery} : {v.result}<br /></p>) : 'Pas de resultats .'}</p>
            <button onClick={onRequestClose}>OK</button>
        </Modal>
    );
};

export default ResultModal;
