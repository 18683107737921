import { GiAmericanFootballHelmet, GiBasketballBall, GiDart, GiPingPongBat, GiSoccerBall, GiTennisBall, GiVolleyballBall } from "react-icons/gi";
import { MdSportsBaseball, MdSportsHandball, MdSportsHockey, MdSportsRugby } from "react-icons/md";

export const sport_365 = [
    {
        id: '1', sport: 'Football',
        icon: <GiSoccerBall size={24} />,
        name: 'football'

    },
    {
        id: '18', sport: 'Basketball',
        render: (e) => e,
        icon: <GiBasketballBall size={24} />,
        name: 'basketball',
    },
    { id: '13', sport: 'Tennis', icon: <GiTennisBall size={24} />, name: 'tennis', },
    { id: '91', sport: 'Volleyball', icon: <GiVolleyballBall size={24} />, name: 'volleyball' },
    { id: '78', sport: 'Handball', icon: <MdSportsHandball size={24} />, name: 'handball' },
    { id: '16', sport: 'Baseball', icon: <MdSportsBaseball size={24} />, name: 'baseball' },
    // { id: '2', sport: 'Horse_Racing' },
    //  { id: '4', sport: 'Greyhounds' },
    { id: '17', sport: 'Hockey sur glace', icon: <MdSportsHockey size={24} />, name: 'hockey' },
    //  { id: '14', sport: 'Snooker' },
    { id: '12', sport: 'Football Americain', icon: <GiAmericanFootballHelmet size={24} />, name: 'american_football' },
    // { id: '3', sport: 'Cricket' },
    // { id: '83', sport: 'Futsal', icon: <Gifu },
    { id: '15', sport: 'Fléchettes', icon: <GiDart size={24} />, name: 'darts' },
    { id: '92', sport: 'Tennis de Table', icon: <GiPingPongBat size={24} />, name: 'table_tennis' },
    //  { id: '94', sport: 'Badminton' },
    { id: '8', sport: 'Rugby', icon: <MdSportsRugby size={24} />, name: 'rugby' },
    // { id: '19', sport: 'Rugby_League' },
    // { id: '36', sport: 'Australian_Rules' },
    // { id: '66', sport: 'Bowls' },
    //  { id: '9', sport: 'Boxing/UFC' },
    // { id: '75', sport: 'Gaelic_Sports' },
    // { id: '90', sport: 'Floorball' },
    // { id: '95', sport: 'Beach_Volleyball' },
    // { id: '110', sport: 'Water_Polo' },
    // { id: '107', sport: 'Squash' }
]