import { GiSoccerBall } from "react-icons/gi"
import { GiBasketballBall } from "react-icons/gi"
import { GiHockey } from "react-icons/gi"
import { MdSportsHandball } from "react-icons/md"
import { GiVolleyballBall } from "react-icons/gi"
import { MdSportsRugby } from "react-icons/md"


export const sports = [
    {
        name: "Football",
        id: 3,
        path: 'football',
        icon: <GiSoccerBall />
    },
    {
        name: "Basketball",
        id: 2,
        path: 'basketball',
        icon: <GiBasketballBall />
    },
    {
        name: "Handball",
        id: 5,
        path: 'handball',
        icon: <MdSportsHandball/>
    },
    {
        name: "Hockey",
        id: 6,
        path: 'hockey',
        icon: <GiHockey />
    },
    {
        name: "Rugby",
        id: 7,
        path: 'rugby',
        icon: <MdSportsRugby/>
    },
    {
        name: "Volleyball",
        id: 8,
        path: 'volleyball',
        icon: <GiVolleyballBall/>
    }
]