/* eslint-disable no-restricted-globals */
// eslint-disable-next-line no-restricted-globals

import axios from "axios"
import { apiUrl, dataUrl } from "../constant"
import { todayDate } from "../components/functions"
import React from "react";
import { useLocation } from "react-router-dom";
import { logout } from "./auth";


export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const getBalance = async (id, token, callback, logout) => {

    try {
        const response = await axios.post(apiUrl + '/user/get_balance',
            { id },
            {
                headers: { Authorization: `Bearer ${token}` }
            })

        callback(response)
    } catch (error) {
        const response = { status: 401 }
        callback(response)

    }


}

export const getDailyFootballFixtures = async (sport, page, callback, setLength, timestamp, league, home) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_odds', {
        page: page || 1,
        sport: sport,
        timestamp,
        league: league || 0,
        home: home || false
    })

    let data = response.data

    data.data.sort((a, b) => {
        return a.timestamp - b.timestamp
    })

    callback(data)
    setLength(data.data.length)

}

export const getDailyBasketballFixtures = async (page, callback, setLength, timestamp, league) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_fixtures', {
        sport: 'basketball',
        page: page || 1,
        timestamp,
        league: league || 0
    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)
    setLength(data.data.length)
}

export const getDailyHandballFixtures = async (page, callback, setLength, timestamp, league) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_fixtures', {
        sport: 'handball',
        page: page || 1,
        timestamp,
        league: league || 0

    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)
    setLength(data.data.length)

}

export const getDailyHockeyFixtures = async (page, callback, setLength, timestamp, league) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_fixtures', {
        sport: 'hockey',
        page: page || 1,
        timestamp,
        league: league || 0

    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)
    setLength(data.data.length)

}

export const getDailyRugbyFixtures = async (page, callback, setLength, timestamp, league) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_fixtures', {
        sport: 'rugby',
        page: page || 1,
        timestamp,
        league: league || 0

    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)
    setLength(data.data.length)

}

export const getDailyVolleyballFixtures = async (page, callback, setLength, timestamp, league) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_daily_fixtures', {
        sport: 'volleyball',
        page: page || 1,
        timestamp,
        league: league || 0

    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)
    setLength(data.data.length)

}

export const getSingleGame = async (sport, idsArr, callback) => {

    const response = await axios.post(dataUrl + '/daily/retrieve_daily_odds', {
        sport,
        idsArr,
    })

    const data = response.data
    const arr = []
    if (data.data.length > 0) {
        let i = 0, len = data.data.length
        while (i < len) {
            if (data.data[i].bets) {
                arr.push(data.data[i])
                i++
            } else {
                i++
            }
        }
    }

    if (arr.length >= 1) {
        callback(arr)
    } else {
        callback('nada')
    }
}

export const getSingleMatch = async (sport, id, callback) => {

    const response = await axios.post(dataUrl + '/daily/retrieve_single_game', {
        sport,
        id,
    })

    let data = response.data
    callback(data)
}

export const getSingleOdd = async (callback, setLength, id, sport) => {
    const response = await axios.post(dataUrl + '/daily/retrieve_single_odd', {
        sport: sport || 'football',
        id
    })

    let data = response.data
    callback(data)
    setLength(data.data.length)

}

export const getTopCompetitions = async (sport, callback) => {
    const response = await axios.post(dataUrl + '/competition/get_top_competition', {
        sport,
    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    //alert(JSON.stringify(data.data))
    callback(data)

}

export const getcountries = async (sport, callback) => {
    const response = await axios.post(dataUrl + '/competition/get_countries', {
        sport,
    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    callback(data)

}

export const getOneCompetition = async (sport, id) => {
    const response = await axios.post(dataUrl + '/competition/get_one_competition', {
        sport,
        id
    })
    //const parsed = JSON.parse(response.data)
    const data = response.data
    return data

}

export const getCompetitionsBycountry = async (sport, country, callback) => {

    const response = await axios.post(dataUrl + '/competition/get_competitions_by_country', {
        sport,
        country
    })

    const data = response.data
    callback(data)
}


export const placeBet = async (betObj, betType, id, token, amount, callback, error, payment) => {



    const theArr = []

    for (const bet of betObj) {
        theArr.push({
            sport: bet.sport,
            id: bet.fixtureId,
            type: bet.type || 'prematch'
        })
    }

    if (!id || !token || id == '' || token == '') {
        error('Veuillez vous connecter pour pouvoir placer un pari')
        return void (0)
    }

    if (betType.type !== 'combo' && !betObj.every(x => x.amount >= 5)) {
        error('La mise minimum est de 10 HTG sur chaque evenement .')
        return void (0)
    }

    if (payment === 'bonus') {
        if (betType.type !== 'combo') {
            error('Les bonus doivent etre misés sur des paris combinés')
            return void (0)
        } else if (amount.win / amount.mise < 50) {
            error('Les bonus doivent etre misés sur un cote superieur a 50')
            return void (0)
        } else if (amount.mise < 10) {
            error('La mise minimum en bonus est de 10 HTG')
            return void (0)
        } else if (betObj.length < 5) {
            error('Les combinaisons en bonus doit avoir un minimum de 5 évenements .')
            return void (0)
        } else if (!betObj.every(x => parseFloat(x.cote) >= 1.5)) {
            error('Les evenements en pari bonus doivent avoir un cote superieur ou égal à 1.5 .')
            return void (0)
        }
    }

    if (amount.mise < 10) {
        error('La mise minimum est de 10 HTG')
        return void (0)
    }

    if (amount.mise > 5000) {
        error('La mise maximum est de 5 000 HTG')
        return void (0)
    }

    if (amount.win > 1000000) {
        error('Le gain maximum est limité à 1000000.00 HTG')
        return void (0)
    }

    if (betObj.length < 1) {
        error('Votre panier est vide , veuillez ajouter des paris')
        return void (0)
    }

    if (betObj.length > 25) {
        error('le nombre de paris autorise par coupon est de 25')
        return void (0)
    }

    // if (!id || id == '') {

    // }



    const response = await axios.post(apiUrl + '/bet/is_betable', {
        fixArr: theArr
    })

    const data = response.data

    if (data.message == 'succes') {

        if (payment === 'main') {

            try {
                const balance = await axios.post(apiUrl + '/user/get_balance',
                    { id, type: 'main' },
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    })

                if (balance.data.result.balance >= amount.mise) {

                    const placeBet = await axios.post(apiUrl + '/bet/place_bet', {
                        betObj,
                        betType: betType.type,
                        id,
                        token,
                        mise: amount.mise,
                        win: amount.win,
                        payment: payment
                    })

                    if (placeBet.data.message == 'succes') {
                        callback(placeBet.data.data)
                    } else {
                        error('Une erreur s\' est produite .')

                    }

                } else {
                    error('Votre balance est insuffisant pour placer le pari , veuillez recharger votre compte .')
                }

            } catch (error) {

                logout()
                history.push('/login')
                history.go(0)

            }


        } else {

            const balance = await axios.post(apiUrl + '/user/get_balance',
                { id },
                {
                    headers: { Authorization: `Bearer ${token}` }
                })

            const user = await axios.post(apiUrl + '/user/get_info', {
                id
            })

            // if (user.data.data.is_player_active === false) {

            //     error('Veuillez effectuer un premier depot superieur ou égal à 250 HTG sur votre compte principale pour pouvoir utiliser le bonus .')
            //     return void (0)
            // }

            if (balance.data.result.bonus >= amount.mise) {

                const placeBet = await axios.post(apiUrl + '/bet/place_bet', {
                    betObj,
                    betType: betType.type,
                    id,
                    token,
                    mise: amount.mise,
                    win: amount.win / 2,
                    payment: 'bonus'
                })

                if (placeBet.data.message == 'succes') {
                    callback(placeBet.data.data)
                } else {
                    error('Une erreur s\' est produite .')
                }

            } else {
                error('Votre bonus est insuffisant pour placer ce pari .')
            }
        }
    } else {

        error('Certaines de vos selections ne sont plus disponibles , veuillez les enlever svp .')
    }
}

export const getBetHistory = async (id, callback) => {

    const response = await axios.post(apiUrl + '/bet/get_bet_history', {
        id
    })

    const data = response.data
    callback(data)
}

export const getTicketHistory = async (id, callback) => {

    const response = await axios.post(apiUrl + '/api/v1/ticket/get_ticket_history', {
        id
    })

    const data = response.data
    callback(data)
}

export const getUserInfo = async (id, callback) => {

    const response = await axios.post(apiUrl + '/user/get_info', {
        id
    })

    const data = response.data
    callback(data)
}

export const createPayment = async (amount, id, callback) => {

    if (id === 0) {
        return void (0)
    }

    const response = await axios.post(apiUrl + '/user/recharge', {
        amount: parseInt(amount),
        playerId: id
    })

    const data = response.data
    callback(data)
}

export const getLive = async (callback) => {

    const response = await axios.get('http://localhost/live/get', {
        headers: {
            'x-rapidapi-key': '9639bb14849d23270b37fdddf7cd292a'
        }
    })

    const data = response.data
    callback(data)
}

export const getCashout = async (elem, callback) => {

    const response = await axios.post(apiUrl + '/bet/calculate_cashout', {
        id: elem.id
    })

    const data = response.data
    callback(elem, data)
    //return data
}

export const takeCashout = async (elem, id, callback) => {

    const response = await axios.post(apiUrl + '/bet/get_cashout', {
        id: elem,
        playerId: id
    })

    const data = response.data
    callback(data)
    //return data
}

export const sendMoney = async (id, phone, amount, callback) => {

    if (phone > 30000000 && amount >= 10) {

        const response = await axios.post(apiUrl + '/transactions/send_money', {
            id,
            phone,
            amount
        })

        const data = response.data
        callback(data)
        //return data
    } else {
        callback({ data: 'Numero ou Montant incorrect' })
    }
}

export const getRetrait = async (id, comment, callback) => {

    const response = await axios.post(apiUrl + '/transactions/get_retrait', {
        id,
        comment
    })

    const data = response.data
    callback(data)
}

export const makeRetrait = async (id, number, amount, callback) => {

    const response = await axios.post(apiUrl + '/transactions/make_retrait', {
        playerId: id,
        phone: number,
        amount
    })

    const data = response.data
    callback(data)
}

export const makeRetraitCaisse = async (id, number, amount, callback) => {

    const response = await axios.post(apiUrl + '/transactions/make_retrait_caisse', {
        playerId: id,
        phone: number,
        amount
    })

    const data = response.data
    callback(data)
}

export const retraitAction = async (id, playerId, amount, action, callback) => {

    const response = await axios.post(apiUrl + '/transactions/retrait_action', {
        id,
        playerId,
        amount,
        action
    })

    const data = response.data
    callback(data)
}

export const getBetcode = async (betcode, callback) => {

    const response = await axios.post(apiUrl + '/bet/get_betcode', {
        betcode
    })

    const data = response.data
    callback(data)
}

export const getTransactions = async (id, callback) => {
    if (id !== 0) {
        const response = await axios.post(apiUrl + '/transactions/get_transactions', {
            id
        })

        const data = response.data
        callback(data)
    }
}

export const getFiyel = async (id, callback) => {
    if (id !== 0) {
        const response = await axios.post(apiUrl + '/user/get_fiyel', {
            id
        })

        const data = response.data
        callback(data)
    }
}

export const convertParrain = async (id, amount, callback) => {
    if (id !== 0) {
        const response = await axios.post(apiUrl + '/transactions/convert_parrain', {
            id,
            amount
        })

        const data = response.data
        callback(data)
    }
}

export const createBetcode = async (bet, betType, error, callback) => {

    if (bet.length == 0) {
        error('Votre panier est vide .')
        return void (0)
    }
    if (betType.type == 'simple') {
        error('Les betcodes ne sont pas autorisés en pari simple .')
        return void (0)
    }

    if (betType.type == 'combo' && bet.reduce((previousValue, currentValue) => previousValue * parseFloat(currentValue.cote), 1) > 1000) {
        error('Le cote maximal est de 1000')
        return void (0)
    }

    const response = await axios.post(apiUrl + '/bet/save_betcode', {
        bet
    })

    const data = response.data
    callback(data)
}

export const getVirtualSessionId = async (id, callback) => {
    if (id) {
        const response = await axios.post(apiUrl + '/user/get_virtual_session', {
            id,
        })

        const data = response.data
        callback(data)
    }
}

export const getSearchResult = async (text, callback) => {
    if (text) {
        const response = await axios.post(dataUrl + '/single/search', {
            search: text,
        })

        const data = response.data
        callback(data)
    }
}

export const dekesman = async (id, callback) => {
    if (id) {
        const response = await axios.post(apiUrl + '/api/v1/tickets/dekesman', {
            id
        })

        const data = response.data
        callback(data)
    }
}

export const getResult = async (date, callback) => {
    if (date) {
        const response = await axios.post(apiUrl + '/api/v1/drawing/get', {
            date,
            lottery: 'all',
            draw: 'all'
        })

        const data = response.data
        callback(data)
    }
}
