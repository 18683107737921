import React, { useState } from 'react';
//import { fixtures } from '../assets/data/fixtures';
import { GiSoccerBall,GiBasketballBall } from 'react-icons/gi';
import { RiMedal2Fill } from 'react-icons/ri';
import { IoEyeSharp } from 'react-icons/io5'
import { useEffect } from 'react';
import { getDailyBasketballFixtures,getSingleGame } from '../functions/api';
import Spinner from 'react-activity/dist/Spinner';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import * as dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import AddBetComponent from '../functions/bet'
import { renderCote } from '../functions/utils';

dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

const HomeBasketball= (props) => {

    const [basketFixtures, setBasketFixtures] = useState(null);
    const [resultLength, setResultLength] = useState(0);
    const [oddsArr, setOddsArr] = useState([])
    const [theArr, setTheArr] = useState(null)

    const filteredArray = (arr) => arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

    const navigate = useNavigate()
    
    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart

    const setTheBasket = (res) => {
        if (basketFixtures) {
            const newArr = basketFixtures.data.concat(res.data)
            const arr = []
            for (const el of newArr) {
              
                    arr.push(el)
    
            }
            setBasketFixtures({ data: filteredArray(arr) })
        } else {

            const arr = []
            for (const el of res.data) {

                    arr.push(el)
    
            }
            setBasketFixtures({ ...res, data: filteredArray(arr) })
        }
    }

    useEffect(() => {
     
            setBasketFixtures(null)
            getDailyBasketballFixtures(1, setTheBasket, setResultLength, dayjs.utc().unix(), null)
    }, []);



    useEffect(() => {
        if (basketFixtures) {
            const arr = basketFixtures.data.map((v, i) => v.id)
            //getDailyBasketballFixtures(page, setBasketFixtures, closeLoader, setResultLength)
            getSingleGame('basketball', arr, setOddsArr)
            //alert(JSON.stringify(arr))
        }
    }, [basketFixtures]);

    useEffect(() => {
        if (oddsArr !== 'nada' && oddsArr.length >= 1) {
            const theArrVar = (basketFixtures, oddsArr) => {
                const arr = oddsArr.map((v, i) => v.id)
                const res = []
                let i = 0, len = basketFixtures.data.length

                while (i < len) {
                    if (arr.includes(basketFixtures.data[i].id)) {
                        res.push(basketFixtures.data[i])
                    }

                    i++
                }

                return res
            }

            setTheArr(theArrVar(basketFixtures, oddsArr))

        } else if (oddsArr == 'nada') {
            setTheArr([])
        } else {
            setTheArr(null)

        }
    }, [oddsArr])


    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#FFC900', color: 'black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }


    const getBet = (arr, id, bet) => {

        if (Array.isArray(arr)) {

            const obj = arr.find(x => x.id == id)
            if (obj && obj.bets) {
                return { odd: obj.bets.values[bet].odd, total: obj.total }
            } else {

                return {
                    odd: 'error',
                    total: 'error'
                }
            }
        } else {
            return <Spinner color='#FFC900' />
        }
    }



    const renderMobile = () => {
        if (theArr === null || oddsArr === null) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher</p>
                </div>
            )
        }


        return (

            theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex" onClick={() => navigate('/sports/basketball/event?game=' + val.id)}>
                                <small><b> <GiBasketballBall /> {val.teams.home.name} - {val.teams.away.name} </b></small> <span className="ml-auto"><small> {dayjs.unix(val.timestamp).format('h:mm A')}</small></span>
                            </p>
                            <p className="is-flex">
                                <small> <img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small> <span className="ml-auto"> <small>{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')} </small></span>
                            </p>
                            <div className="is-flex is-justify-content-space-between">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: renderCote(getBet(oddsArr, val.id, 0).odd),
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {renderCote(getBet(oddsArr, val.id, 0).odd)}
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: renderCote(getBet(oddsArr, val.id, 1).odd),
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {renderCote(getBet(oddsArr, val.id, 1).odd)}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2">
                                    <div className=" is-flex" onClick={() => navigate('/sports/basketball/event?game=' + val.id)}>
                                        <p style={{ fontSize: '15px' }}>+{getBet(oddsArr, val.id, 0).total}</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                )
            })
        )
    }

    const renderDesktop = () => {
        if (!theArr) {

            return (
                <div className='is-hidden-touch is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher</p>
                </div>
            )
        }
        else {


            return theArr.map((val, i) => {

                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <span onClick={() => navigate('/sports/basketball/event?game=' + val.id)}> <b> <GiBasketballBall /> {val.teams.home.name} - {val.teams.away.name} </b> </span> <span className="ml-auto">{dayjs.unix(val.timestamp).format('h:mm A')}</span>
                            </p>

                            <p className="is-flex">
                                <small><img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small>
                                <span className="ml-auto">{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')}</span>
                            </p>

                            <div className="is-flex is-justify-content-space-between px-4 mt-2">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: renderCote(getBet(oddsArr, val.id, 0).odd),
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {renderCote(getBet(oddsArr, val.id, 0).odd)}
                                        </a>
                                    </div>
                                </div>


                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: renderCote(getBet(oddsArr, val.id, 1).odd),
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {renderCote(getBet(oddsArr, val.id, 1).odd)}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2 mt-2">
                                    <div onClick={() => navigate('/sports/basketball/event?game=' + val.id)}>
                                        +{getBet(oddsArr, val.id, 0).total}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            })


        }
    }


    return (

        <>

            <div className='my-2'>
                <div>
                    <div className='is-hidden-desktop'>
                        <hr />
                        <p className='has-text-centered is-size-5'> <RiMedal2Fill />  Top BasketBall </p>
                        <div className='mt-4 px-2'>
                            {
                                renderMobile()
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='is-hidden-touch'>
                        <hr />
                        <p className='has-text-centered is-size-5'> <RiMedal2Fill />  Top BasketBall </p>
                        <div className='mt-4 px-2'>
                   
                            {
                                renderDesktop()
                            }
                        </div>
                    </div>
                </div>
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => navigate('/sports/basketball')} ><IoEyeSharp /> Voir tout basketball</button>
                </div>
            </div>


















        </>

    )

}

export default HomeBasketball