import React from 'react'
import { useNavigate } from 'react-router-dom'

function Footer() {
    const nav = useNavigate()
    return (
        <div>

            <footer className="footer">
                <div className="columns px-6" style={{ backgroundColor: "#141E27", color: 'white' }}>
                    <div class="column has-text-centered">
                        <p>Termes et Conditions</p>
                        <p>Politique de confidentialite</p>
                        <p>Responsible Gaming</p>
                    </div>
                    <div className="column has-text-centered">
                        <p>Facebook</p>
                        <p>Instagram</p>
                        <p>Twitter</p>
                    </div>
                    <div className="column has-text-centered">
                        <p>Points de vente</p>
                        <p onClick={()=>{nav('/contact')}}>Contact</p>
                        <p>A propos</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
