import React, { useState, useEffect } from 'react'
import { sportsList } from '../assets/data/sports'
import { useNavigate } from 'react-router-dom'
import { sport_365 } from '../assets/data/sport'


function BarVertical(props) {

    const [_sports_list, set_sports_list] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        set_sports_list(sport_365)
    }, [])

    return (
        <div className=''>
            <div className="is-hidden-touch">
                <div>
                    {
                        _sports_list.map((l, i) => <div className='mt-4'><div className="" onClick={() => navigate('/sports/' + l.name)} > <div className='is-flex is-justify-content-center' style={{fontSize:'24', color:'black'}}> {l.icon}</div> <div className='is-flex is-justify-content-center has-text-centered has-text-dark help'>{l.sport}</div></div> </div>)
                    }
                </div>
            </div>
        </div>
    )
}

export default BarVertical
