import React, { useState, useEffect } from 'react'
import { MdBarChart, MdLock, MdSecurity } from 'react-icons/md';
import { BsFillCircleFill } from 'react-icons/bs'
import { BiStats } from 'react-icons/bi'
import { GiTrophy, GiSoccerBall } from 'react-icons/gi'
import { bet_list_live } from '../assets/data/bet_list_live';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'react-activity/dist/Spinner';
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState, isNextClickState } from '../store/bets_store';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import img from '../assets/images/back2.jpg'
import * as dayjs from 'dayjs'
import axios from 'axios';
import { dataUrl } from '../constant';
import { bet_list_soccer } from '../assets/data/bet_list';
import { getPaysByCC, groupBy } from '../functions/utils';
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

const renderCote = (cote) => {
    if (parseFloat(cote) > 100) {
        return 100.00
    } else if (parseFloat(cote) * 0.99 > 1) {
        return (parseFloat(cote) * 0.99).toFixed(2)
    } else {
        return parseFloat(cote)
    }
}

function OddsListLive(props) {

    const [style, setStyle] = useState({ enCours: 'has-text-primary', gagne: '', perdu: '', tout: '' })
    const [resultLength, setResultLength] = useState(0)
    const [oddsObj, setOddsObj] = useState(null)
    const [matchObj, setMatchObj] = useState(null)
    const [teamObj, setTeamObj] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [text, setText] = useRecoilState(textState)
    const [oddsArray, setOddsArray] = useState(bet_list_live.response)
    const getGame = () => {

        if (parseInt(searchParams.get("game")) > 0) {
            return searchParams.get("game")
        }
        return 0
    }

    const game = getGame()

    const ENDPOINT = dataUrl;


    const setLiveOdds = (d) => {

        setOddsObj(d.odds)
        setMatchObj({ data: d.fixture })
        setTeamObj(d.teams)
    }

    useEffect(() => {
        if (null) {
            axios.post(ENDPOINT + '/live/retrieve_single_live', {
                id: game
            }).then(x => {
                let data = x.data
                setLiveOdds(data.data)
                // setResult(data.fixture[0])
                // setLiveBet()

            })
        }
    }, [1]);

    useEffect(() => {
        const interval = setInterval(() => {

            axios.post(ENDPOINT + '/live/retrieve_single_live', {
                id: game
            }).then(x => {
                let data = x.data
                setLiveOdds(data.data)
                // setResult(data.fixture[0])
                // setLiveBet()
            })

        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const setResult = (d) => {
        if (d && d.data) {
            if (game > 0) {
                setMatchObj({ data: [d.data] })
            }
        }
    }



    const verifBetInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });


        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameAndBetChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }


    const verifFixtureAndBetNameAndBetNonChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose !== betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const removeFromCart = (id, betName, betChoose) => {

        text.some((el, index) => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                let arr = [...text]
                arr.splice(index, 1)
                setText(arr)
                return true
            } else {
                return false
            }
        });
    }

    const addBet = (payload, id) => {

        if (payload.cote == null) {
            return 0
        }

        if (!verifFixtureInCart(id, payload.betName)) { //si match la pa egziste

            if (!verifBetInCart(id, payload.betName, payload.betChoose)) { //si match la pa egziste e si pari a pa egziste

                setText([...text, {
                    sport: payload.sport,
                    fixtureId: payload.fixtureId,
                    homeTeam: payload.homeTeam,
                    awayTeam: payload.awayTeam,
                    timestamp: payload.timestamp,
                    betName: payload.betName,
                    betChoose: payload.betChoose,
                    cote: payload.cote,
                    amount: 0,
                    type: payload.type,
                    name: payload.name || null,
                    betId: payload.betId || null,
                    handicap: payload.hand || null,
                    data: payload.data,
                    pa_id: payload.pa_id || null
                }])
            } else { // si match la pa egziste men pari a egziste

                //alert('test')
                //removeFromCart(id, payload.betName, payload.betChoose)
            }
        } else if (verifFixtureAndBetNameAndBetChooseInCart(id, payload.betName, payload.betChoose)) { // si match la egziste

            removeFromCart(id, payload.betName, payload.betChoose)
        } else if (verifFixtureAndBetNameInCart(id, payload.betName) === false) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                timestamp: payload.timestamp,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0,
                type: payload.type,
                name: payload.name || null,
                betId: payload.betId || null,
                handicap: payload.hand || null,
                data: payload.data,
                pa_id: payload.pa_id || null

            }])
        }
        else if (verifFixtureAndBetNameAndBetNonChooseInCart(id, payload.betName, payload.betChoose)) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                timestamp: payload.timestamp,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0,
                type: payload.type,
                name: payload.name || null,
                betId: payload.betId || null,
                handicap: payload.hand || null,
                data: payload.data,
                pa_id: payload.pa_id || null
            }])
        }

    }

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '1px solid #141E27' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const toggleTabsCss = (tab) => {
        if (tab === 'tout') {
            setOddsArray(setTheArray(0))
            setStyle({
                tout: 'has-text-primary',
                resultat: '',
                buts: '',
                stats: ''
            })
        } else if (tab === 'resultat') {
            setOddsArray(setTheArray(1))
            setStyle({
                tout: '',
                resultat: 'has-text-primary',
                buts: '',
                stats: ''
            })
        } else if (tab === 'buts') {
            setOddsArray(setTheArray(2))
            setStyle({
                tout: '',
                resultat: '',
                buts: 'has-text-primary',
                stats: ''
            })
        } else {
            setOddsArray(setTheArray(3))
            setStyle({
                tout: '',
                resultat: '',
                buts: '',
                stats: 'has-text-primary'
            })
        }
    }

    const setTheArray = (payload) => {

        let arr = []

        switch (payload) {
            case 1:
                for (const i of bet_list_live.response) {

                    if (i.type == 1 || i.type == 12) {
                        arr.push(i)
                    }

                }
            case 2:
                for (const i of bet_list_live.response) {

                    if (i.type == 2 || i.type == 12) {
                        arr.push(i)
                    }

                }
            case 3:
                for (const i of bet_list_live.response) {

                    if (i.type == 3) {
                        arr.push(i)
                    }

                }

                break;

            default:
                arr = bet_list_live.response
        }

        return arr


    }





    const parseName = (nom, id) => {

        //return nom

        if (true) {

            RegExp.quote = function (str) {
                return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }



            let h = new RegExp(RegExp.quote(teamObj.find(x => x.OR == '0') ? teamObj.find(x => x.OR == '0').NA : matchObj.data[0].home.name || matchObj.data[0].home.name), "g");
            let a = new RegExp(RegExp.quote(teamObj.find(x => x.OR == '1') ? teamObj.find(x => x.OR == '1').NA : matchObj.data[0].home.name || matchObj.data[0].away.name), "g");
            let s = new RegExp('/', "g");
            const name = nom.toString().replace(h, "1").replace(a, "2").replace(s, "")


            const mapObj = {
                Home: '1',
                Away: '2',
                Draw: 'X',
                // Over: "superieur à",
                // Under: "inferieur à",
                Over: ">",
                Under: "<",
                Odd: "impair",
                Even: "pair",
                more: "",
                by: " -> ",
                "NO GOALS": '0',
                "o/yes": "oui >",
                "u/yes": "oui <",
                "o/no": "non >",
                "u/no": "non <",
                "Score Draw": 'pas de but',
                Yes: "oui",
                No: "non",
                "1st Half": '1er',
                "First": '1er',
                "Last": 'dernier',
                "2nd Half": '2eme',
                Header: 'Tete',
                FreeKick: 'Coup Franc',
                OwnGoal: 'Contre son camp',
                Shot: 'Tir',
                Exactly: "",
                Goal: 'but',
                before: 'avant',
                after: 'apres',
                "Full Time": '90',
                goal: 'but',
                Tie: 'X',
                " or ": '/',
                Anytime: '',
                Only: 'uniquement',
                "Both Teams": 'Les deux equipes',
                "Free Kick": 'Coup franc',
                "Own Goal": "Contre son camp",
                "More": 'plus',
                "Starting Players": '',
                "Substitutes": '',
                "No Goalscorer": 'pas de buteur',
                "Outside the Box": 'Dehors du carré',
                'Cards': ' carton'

            }

            const result = name.replace(/Home|Away|Draw|Over|Under|Odd|Even|more|by|o\/yes|u\/yes|o\/no|u\/no|Score Draw|Yes|No|1st Half|2nd Half|Header|FreeKick|OwnGoal|Shot|Exactly|First|Last|Goal|before|after|Full Time|goal|Tie|NO GOALS| or |Anytime|Only|Both Teams|Free Kick|Own Goal|More|Starting Players|Substitutes|No Goalscorer|Outside the Box|Cards/gi, function (matched) {
                return mapObj[matched];
            })

            return result
        } else {
            return nom.toString()
        }
    }



    useEffect(() => {

        const getName = (val) => {
            if (bet_list_soccer.find(x => x.fr === val)) {
                return bet_list_soccer.find(x => x.fr === val).name
            } else {
                return 'not-found'
            }

        }

        if (oddsObj !== null && matchObj !== null) {
            const newArr = text.map((v, i) => {

                //  alert(matchObj.data[0].bet365_id)

                if (matchObj.data[0].bet365_id == v.fixtureId) {
                    const name = getName(v.betName)
                    //alert(name)
                    const newOddsObj = bet_list_soccer.map((v, i) => {
                        if (oddsObj.find(x => x.name == v.name)) {
                            //return oddsObj.find(x => x.name == v.name)
                            if (v.render) {
                                return { id: v.id, name: v.name, odds: v.render(oddsObj.find(x => x.name == v.name).odds) }
                            } else {
                                return { id: v.id, name: v.name, odds: oddsObj.find(x => x.name == v.name).odds.map((v, i) => { return { ...v, headerShown: true } }) }
                            }
                            //return v.render ? v.render(oddsObj.find(x => x.name == v.name).odds) : oddsObj.find(x => x.name == v.name).odds.map((y, i) => { return { id:  oddsObj.find(x => x.name == v.name).id , name:  oddsObj.find(x => x.name == v.name).name , odds: {...y, headerShown: true }} })
                        }
                    }).filter(v => v)

                    const odd = newOddsObj.find(x => x.name == name)

                    if (odd) {
                        var theCote = 1
                        const res = odd.odds
                        if (res.length < 4) {
                            if (res.filter(x => x.header).length > 0) {
                                for (i = 0; i < res.length; i++) {
                                    const z = res[i]
                                    if (parseName(`${z.team && z.team.length > 0 ? 'Eq. ' + z.team + ' ,' : ''}${z.header && z.headerShown ? z.header + ' ' : ''}${z.handicap || ''}/${z.name2 ? z.name2 : z.name || ''}`) == v.betChoose) {
                                        if (res[i].suspended == '0') {
                                            theCote = res[i].odds
                                        } else {
                                            theCote = 1
                                        }
                                    }
                                }
                            } else {
                                for (i = 0; i < res.length; i++) {
                                    const z = res[i]
                                    if (parseName(`${z.team && z.team.length > 0 ? 'Eq. ' + z.team + ' ,' : ''}${z.header && z.headerShown ? z.header + ',' : ''}${z.handicap || ''}/${z.name2 ? z.name2 : z.name || ''}`) == v.betChoose) {
                                        if (res[i].suspended == '0') {
                                            theCote = res[i].odds
                                        } else {
                                            theCote = 1
                                        }
                                    }
                                }
                            }
                        } else if (res.length >= 4) {
                            if (res.filter(x => x.header).length > 0) {
                                for (i = 0; i < res.length; i++) {
                                    const z = res[i]
                                    if (parseName(`${z.team && z.team.length > 0 ? 'Eq. ' + z.team + ' ,' : ''}${z.header && z.headerShown ? z.header + ' ' : ''}${z.handicap || ''}/${z.name2 ? z.name2 : z.name || ''}`) == v.betChoose) {
                                        if (res[i].suspended == '0') {
                                            theCote = res[i].odds
                                        } else {
                                            theCote = 1
                                        }
                                    }
                                }
                            } else {
                                for (i = 0; i < res.length; i++) {
                                    const z = res[i]
                                    if (parseName(`${z.team && z.team.length > 0 ? 'Eq. ' + z.team + ' ,' : ''}${z.header && z.headerShown ? z.header + ',' : ''}${z.handicap || ''}/${z.name2 ? z.name2 : z.name || ''}`) == v.betChoose) {
                                        if (res[i].suspended == '0') {
                                            theCote = res[i].odds
                                        } else {
                                            theCote = 1
                                        }
                                    }
                                }
                            }
                        }

                        return { ...v, cote: renderCote(theCote) }

                    } else {
                        //alert(6)
                        return { ...v, cote: 1 }
                    }
                } else {
                    return v
                }
            })
            setText(newArr)
        }

    }, [oddsObj])


    const getBet = (betId, betName, arr) => {
        if (oddsObj) {
            if (arr.length < 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    for (const elem of theArr) {
                        theArr2.push(arr.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arr.filter(x => x.header == v).map((v, i) => <th><div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                            sport: 'F',
                                            fixtureId: game,
                                            homeTeam: matchObj.data[0].home.name,
                                            awayTeam: matchObj.data[0].away.name,
                                            timestamp: matchObj.data[0].time,
                                            betName: betName,
                                            betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                            cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                            amount: 0,
                                            data: { ...v, id: betId },
                                            type: 'live',
                                            pa_id: v.id

                                        }, game)}>
                                            <div class="control" style={{ width: '100px' }}>
                                                <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                            </div>
                                            <div class="control is-small">
                                                <a className="button is-primary is-small" href="#">
                                                    {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                                </a>
                                            </div>
                                        </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <div className='is-flex is-justify-content-space-between'>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between">
                                <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                    cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                    amount: 0,
                                    data: { ...v, id: betId },
                                    type: 'live',
                                    pa_id: v.id
                                }, game)}>
                                    <div class="control" style={{ width: '100px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="is-hidden-desktop is-flex ">
                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                    cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                    amount: 0,
                                    data: { ...v, id: betId },
                                    type: 'live',
                                    pa_id: v.id
                                }, game)}>
                                    <div class="control" style={{ width: '50px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </ div>
                    )

                }
            }
            else if (arr.length >= 4) {

                if (arr.filter(x => x.header).length > 0) {
                    // return 
                    let theArr = []
                    let theArr2 = []

                    for (const [key, value] of Object.entries(groupBy(arr, 'header'))) {
                        theArr.push(key)
                    }

                    theArr = theArr.map((v, i) => {
                        if (v == 'undefined') {
                            return 'autre'
                        } else {
                            return v
                        }
                    })



                    let arrNew = arr.map((v, i) => {
                        if (!v.header) {
                            return { ...v, header: 'autre' }
                        } else {
                            return v
                        }
                    })

                    for (const elem of theArr) {
                        theArr2.push(arrNew.map((v, i) => {
                            if (v.header == elem) {
                                return v
                            }
                        }).filter(x => x))
                    }

                    return theArr.map((v, i) => <>
                        <table class="table" style={{ background: 'none' }}>
                            <thead>
                                <tr>
                                    {/* <th className='has-text-centered'>{parseName(v)}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        arrNew.filter(x => x.header == v).map((v, i) => <th className='is-flex is-flex-wrap-wrap p-1 is-justify-content-center'>
                                            <div class="field has-addons px-1 is-flex-grow-1" onClick={() => addBet({
                                                sport: 'F',
                                                fixtureId: game,
                                                homeTeam: matchObj.data[0].home.name,
                                                awayTeam: matchObj.data[0].away.name,
                                                timestamp: matchObj.data[0].time,
                                                betName: betName,
                                                betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                                cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                                amount: 0,
                                                data: { ...v, id: betId },
                                                type: 'live',
                                                pa_id: v.id
                                            }, game)}>
                                                <div class="control" style={{ width: '100px' }}>
                                                    <input className="input is-small" type="text" value={parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap ? v.handicap + ' ' : '' || ''}${v.name2 ? v.name2 : v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ' ' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                                </div>
                                                <div class="control is-small">
                                                    <a className="button is-primary is-small" href="#">
                                                        {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                                    </a>
                                                </div>
                                            </div></th>)
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </>)

                } else {
                    return arr.map((v, i) =>
                        <>
                            <div className="is-hidden-touch is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                    cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                    amount: 0,
                                    data: { ...v, id: betId },
                                    type: 'live',
                                    pa_id: v.id
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`)} width='10px' readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className="is-hidden-desktop is-flex is-flex-wrap-wrap p-1 is-justify-content-center">
                                <div class="field has-addons px-1
                     is-flex-grow-1" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: game,
                                    homeTeam: matchObj.data[0].home.name,
                                    awayTeam: matchObj.data[0].away.name,
                                    timestamp: matchObj.data[0].time,
                                    betName: betName,
                                    betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`),
                                    cote: v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : null,
                                    amount: 0,
                                    data: { ...v, id: betId },
                                    type: 'live',
                                    pa_id: v.id
                                }, game)}>
                                    <div class="control" style={{ width: '80px' }}>
                                        <input className="input is-small" type="text" value={parseName(`${v.team ? 'Eq. ' + v.team + ' ,' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`)} readOnly style={getInputStyle(game, { betName: betName, betChoose: parseName(`${v.team && v.team.length > 0 ? 'Eq. ' + v.team + ' ,' : ''}${v.header && v.headerShown ? v.header + ',' : ''}${v.handicap || ''}/${v.name2 ? v.name2 : v.name || ''}`) })} />
                                    </div>
                                    <div class="control is-small">
                                        <a className="button is-primary is-small" href="#">
                                            {v.odds > 1.01 && v.suspended == '0' ? renderCote(v.odds) : <MdLock />}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </>
                    )
                }
            }
            else {
                return <div className="is-flex is-flex-wrap-wrap p-4">
                    <div class="field has-addons px-1 is-flex-grow-1">
                        <div class="control is-small">
                            <input className="input is-small" type="text" value='Non Disponible' disabled />
                        </div>
                        <div class="control is-small">
                            <a className="button is-primary is-small" href="#">
                                <MdLock />
                            </a>
                        </div>
                    </div>

                </div>
            }
        }
    }

    const renderBet = () => {

        //return JSON.stringify(oddsObj)

        if (oddsObj === null || matchObj === null) {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>
            // return void (0)
        } else {
            return (
                <>
                    {/* <div className='card my-2 py-1 mx-2' >
                        <div className="mt-2 px-2 is-flex is-justify-content-center">
                            <button class="button is-white" onClick={() => toggleTabsCss('tout')}><p className={style.tout}> <BsFillCircleFill /> Tout</p></button>
                            <button class="button is-white" onClick={() => toggleTabsCss('resultat')}><p className={style.resultat}><GiTrophy /> Resultat</p></button>
                            <button class="button is-white" onClick={() => toggleTabsCss('buts')}><p className={style.buts}><GiSoccerBall /> Buts</p></button>
                            <button class="button is-white" onClick={() => toggleTabsCss('stats')}><p className={style.stats}><BiStats /> Stats</p></button>
                        </div>

                    </div> */}

                    <div className='px-2 pb-4'>
                        {
                            bet_list_soccer.map((v, i) => {
                                if (oddsObj.find(x => x.name == v.name)) {
                                    return <div className="mt-2" style={{ fontSize: '13.5px' }}>

                                        <div className="card mt-2">
                                            <header className="card-header" style={{ backgroundColor: 'white' }}>
                                                <p className="ml-2 has-text-dark">
                                                    {v.fr || v.name}
                                                </p>
                                            </header>
                                            {/* // */}
                                            <div className="card-content is-flex is-flex-wrap-wrap p-1 is-justify-content-space-between" style={{ backgroundColor: 'white' }}>
                                                {
                                                    getBet(v.id, v.fr || v.name, v.render ? v.render(oddsObj.find(x => x.name == v.name).odds) : oddsObj.find(x => x.name == v.name).odds.map((v, i) => { return { ...v, headerShown: true } }))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }
                            }
                            )
                        }
                    </div>
                </>
            )
        }
    }

    const renderPanel = () => {

        //return JSON.stringify(matchObj)
        if (matchObj && matchObj.data && matchObj.data.length > 0) {

            if (matchObj && matchObj !== []) {

                const topRight = () => {
                    if (matchObj.data[0] && matchObj.data[0].league.round) {
                        return matchObj.data[0].league.round
                    } else {
                        return '2023'
                    }
                }
                if (matchObj !== null) {

                    return <div className="">
                        <div className="card mx-2 pb-2" style={{ backgroundColor: `transparent`, backgroundSize: 'cover' }}>

                            <div className="header is-primary" style={{ backgroundColor: 'yellow' }}>
                                <div className='is-flex px-2 has-text-dark'>
                                    <div className='is-flex'>
                                        <div className='mt-1'><GiSoccerBall /></div>
                                        <p className='ml-2'><small> {getPaysByCC(matchObj.data[0].league.cc)} - {matchObj.data[0].league.name} </small></p>
                                    </div>
                                    <div className='ml-auto'>
                                        <p><small>{'2023'}</small></p>
                                    </div>
                                </div>
                            </div>



                            <div className='is-flex is-justify-content-space-between px-4 mt-4' style={{ color: 'white' }} >
                                <div class="column has-text-centered">
                                    <div className='is-flex is-justify-content-center'>
                                        <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].home.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                                    </div>
                                    <div>
                                        <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' style={{ textShadow: 'black 2px 0 30px' }}>{matchObj.data[0].home.name}</p> </div>
                                    </div>
                                </div>
                                <div class="column  has-text-centered">
                                    <div>
                                        <div className='is-flex is-justify-content-center'>   <p className='is-size-4 has-text-centered' >{matchObj.data[0].ss}</p> </div>
                                        <div className='is-flex is-justify-content-center'>   <p className='has-text-centered' >{matchObj.data[0].timer ? matchObj.data[0].timer.tm + ':' + matchObj.data[0].timer.ts : 'Terminé'}</p> </div>
                                    </div>
                                </div>
                                <div class="column  has-text-centered">
                                    <div className='is-flex is-justify-content-center'>
                                        <div style={{ width: '64px', height: '64px', backgroundImage: `url(https://assets.b365api.com/images/team/b/${matchObj.data[0].away.image_id}.png)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'whitesmoke', border: '1px solid black' }}> </div>
                                    </div>
                                    <div>
                                        <div className='is-flex is-justify-content-center'>  <p className='has-text-centered' >{matchObj.data[0].away.name}</p> </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                } else {
                    return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                        <Spinner color='#FFC900' />
                    </div>
                }
            } else {
                return <p className='has-text-centered has-text-light'>Chargement ...</p>
            }
        } else {
            return <p className='has-text-centered has-text-light'>Chargement .... </p>
        }
    }

    return (
        <div className='pt-2' style={{ backgroundColor: '#252f5a', color: 'white' }}>

            {
                renderPanel()
            }
            {/* {
                JSON.stringify(oddsObj?oddsObj.find(x=> x.name === 'Fulltime Result'):null)
            } */}
            {
                renderBet()
            }
        </div>
    )
}

export default OddsListLive
