/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Spinner } from 'react-activity'
import { useNavigate } from 'react-router-dom'
import { bet } from '../assets/data/paris'
import { getBetcode, getBetHistory, getCashout, takeCashout } from '../functions/api'
import { getToken } from '../functions/auth'
import * as dayjs from 'dayjs'
import { IoMdPaper, IoMdTrophy } from 'react-icons/io';
import { MdCancel, MdSettingsBackupRestore, MdShare, MdSportsBaseball, MdSportsFootball, MdSportsHockey, MdTimer } from 'react-icons/md';
import { RiRefund2Fill } from 'react-icons/ri';
import { GiDart, GiPingPongBat, GiSoccerBall, GiTennisBall } from 'react-icons/gi';
import { GiBasketballBall } from 'react-icons/gi';
import { MdSportsHandball, MdSportsRugby } from 'react-icons/md';
import { GiHockey, GiVolleyballBall } from 'react-icons/gi';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import { error, success } from '../functions/toast'
import { useRecoilState } from 'recoil'
import { textState } from '../store/bets_store'
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')

function BetsHistoryMiddleMobile() {

    const [totalOdds, setTotalOdds] = useState(1)
    const [betList, setBetList] = useState(null)
    const [show, setShow] = useState('pending')
    const [cashout, setCashout] = useState([])
    const [double, setDouble] = useState(false);
    const [text, setText] = useRecoilState(textState)

    const handleBetcode = (betObj) => {
        if (betObj && betObj.data.length > 0 && betObj.data !== 'not_found') {
            setText(betObj.data)
        } else {
            error('Betcode non valide .')
        }
    }
    const [style, setStyle] = useState({
        enCours: 'has-text-primary',
        gagne: '',
        perdu: '',
        tout: ''
    })

    const navigate = useNavigate()

    const setTheCashout = (elem, x) => {
        const newCashout = cashout
        newCashout.push({ id: elem.id, cashout: x.data })
        setCashout(newCashout)
    }

    useEffect(() => {
        if (getToken().id !== 0) {
            getBetHistory(getToken().id, setBetList)
        } else {
            navigate('/login')
        }
    }, [])


    useEffect(() => {
        if (betList !== null) {

            for (const elem of betList.data) {
                if (elem.result === 'pending') {
                    getCashout(elem, setTheCashout)
                }
            }
        }
    }, [betList])



    const retrieveCashout = (id) => {
        if (cashout.find(x => x.id == id)) {
            return {
                hidden: false,
                cashout: cashout.filter(x => x.id == id)[0].cashout
            }
        } else {
            return {
                hidden: true,
                cashout: 0
            }
        }
    }

    const handleCashout = (msg) => {
        if (msg.data === 'success') {
            navigate(0)
            success('Opération effectuée avec succes')
        } else {
            error('Une erreur s\' est produite , veuillez rafraichir la page et essayez a nouveau .')
        }

    }



    const renderShow = (sho) => {
        if (betList) {
            switch (sho) {
                case 'all':
                    return betList.data
                case 'win':
                    return betList.data.filter(x => x.result === 'win')
                case 'false':
                    return [...betList.data.filter(x => x.result != 'false' && x.bets.filter(v => v.status == false).length > 1), ...betList.data.filter(x => x.result === 'false')]
                case 'pending':
                    return betList.data.filter(x => x.result === 'pending' && x.bets.filter(v => v.status == false).length < 2)

                default:
                    break;
            }
        }

    }





    const toggleTabsCss = (tab) => {
        if (tab === 'enCours') {
            setStyle({
                enCours: 'has-text-primary',
                gagne: '',
                perdu: '',
                tout: ''
            })
            setShow('pending')

        } else if (tab === 'gagne') {
            setStyle({
                enCours: '',
                gagne: 'has-text-primary',
                perdu: '',
                tout: ''
            })

            setShow('win')

        } else if (tab === 'perdu') {
            setStyle({
                enCours: '',
                gagne: '',
                perdu: 'has-text-primary',
                tout: ''
            })
            setShow('false')
        } else {
            setStyle({
                enCours: '',
                gagne: '',
                perdu: '',
                tout: 'has-text-primary'
            })
            setShow('all')
        }
    }
    const setOdd = () => {
        let theOdd = totalOdds

        bet.forEach(el => {
            theOdd = theOdd * el.odd
        });

        return Math.round(theOdd)
    }

    const renderColor = (result) => {
        switch (result) {
            case 'win':
                return '#198754'
            case 'false':
                return '#d9534f'
            case 'pending':
                return '#209cee'

            default:
                return 'yellow'

        }
    }

    const renderStatus = (stat) => {

        switch (stat) {
            case false:
                return <MdCancel color='#d9534f' />
                break;
            case true:
                return <IoMdTrophy color='#198754' />
                break;
            case 'refund':
                return <RiRefund2Fill />
                break;
            case 'pending':
                return <MdTimer color='#209cee' />
                break;

            default:
                return <MdTimer color='#209cee' />
                break;
        }

    }

    const renderSport = (sport) => {

        switch (sport) {
            case 'F':
                return <GiSoccerBall />
            case 'B':
                return <GiBasketballBall />
            case 'T':
                return <GiTennisBall />
            case 'Ba':
                return <MdSportsBaseball />
            case 'Ha':
                return <MdSportsHandball />
            case 'Ho':
                return <MdSportsHockey />
            case 'Fa':
                return <MdSportsFootball />
            case 'D':
                return <GiDart />
            case 'R':
                return <MdSportsRugby />
            case 'V':
                return <GiVolleyballBall />
            case 'Tt':
                return <GiPingPongBat />
            default:
                return <GiSoccerBall />
        }

    }

    const renderBetElement = (array) => array.map((v, i) =>
        <div className='is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray', fontSize: '12px' }}>
            <div>
                <div>
                    <small><b>{renderSport(v.sport)} - {v.name}</b></small>
                </div>
                <div>
                    <small>{dayjs.unix(v.timestamp).format('D MMMM YYYY, h:mm A')}</small>
                </div>
                <div>
                    <small>{v.betName}: <b>{v.betChoose} || Cote: {v.cote} </b></small>
                </div>
            </div>
            <div className='is-flex is-justify-content-center is-align-items-center pr-4'>

                {renderStatus(v.status)}
            </div>
        </div>)

    const renderShare = (state, payment, mise, win, code) => {

        if (state === 'pending' && payment === 'main' && [...code].every(c => '0123456789'.includes(c))) {
            return (
                <MdShare size={24} onClick={() => location.href = 'whatsapp://send?text=La cote de ma selection de pari s\'élève à ' + parseFloat(win / mise).toFixed(2) + ' . Le code de mon coupon gagnant est ' + code + ' sur https://pjparyaj.com ou en cliquant sur ce lien : https://pjparyaj.com?betcode=' + code + ' .'} />
            )
        }

        //return [...code].every(c => '0123456789'.includes(c)).toString()
    }

    const renderBetList = () => {

        if (betList === null) {
            return <div className='is-flex is-justify-content-center mt-4 pb-4'>
                <Spinner color='#FFC900' />
            </div>

        } else if (renderShow(show).length === 0) {

            return <div className='is-flex is-justify-content-center is-align-items-center'>
                <div className='mt-4 has-text-light'>
                    <p>Pas de pari disponible .</p><br />
                </div>
            </div>

        }
        else if (betList !== null) {
            return renderShow(show).sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.date) - new Date(a.date);
            }).map((v, i) =>

                <div class="card mt-4">
                    <header class="card-header is-flex" style={{ backgroundColor: renderColor(v.result), color: 'white' }}>
                        <span class="px-2" style={{ fontSize: '13px' }}>
                            <small> {v.type.slice(0, 1).toUpperCase() + v.type.slice(1)} - {dayjs(v.date).format('D MMMM YYYY, h:mm A')}</small> <br />
                            <small>ID: {v.id}</small>
                        </span>
                        <span className='ml-auto pr-2 mt-1'>
                            {renderShare(v.result, v.payment, v.mise, v.win, v.betCode)}
                        </span>
                    </header>
                    <div class=" mt-2 mx-2 content">
                        {

                            renderBetElement(v.bets)

                        }
                        <div class="py-2">
                            <div className='mt-4 is-flex is-justify-content-space-between'>
                                <div> <button className='button is-small is-light' style={{ fontSize: '8px' }}><p style={{ fontSize: '9px' }}>Mise : {v.mise.toFixed(2)} HTG </p></button> </div>
                                <div> <button className='button is-small is-light' style={{ fontSize: '8px' }}><p style={{ fontSize: '9px' }}>Gain Max : {v.win.toFixed(2)} HTG </p>  </button> </div>
                                <div hidden={retrieveCashout(v.id).hidden} > <button className='button is-small is-primary' style={{ fontSize: '9px' }} disabled={double} onClick={(e) => {
                                    setDouble(true);
                                    takeCashout(v.id, getToken().id, handleCashout)
                                }}> <p style={{ fontSize: '9px' }}>Cashout : {retrieveCashout(v.id).cashout.toFixed(2)} HTG </p></button> </div>
                                <div hidden={v.result === 'pending' ? false : true}>
                                    {/* <button class="button is-small is-info"  onClick={() => getBetcode(parseInt(v.betCode), handleBetcode)} style={{ fontSize: '9px' }}> <MdSettingsBackupRestore/> Reparier </button> */}
                                    <button class="button is-small is-info" onClick={() => alert('Option desactivée')} style={{ fontSize: '9px' }}> <MdSettingsBackupRestore /> Reparier </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
    }


    return (
        <>
            <p className='has-text-centered is-size-5 mt-4 has-text-light'><IoMdPaper /> Mes Paris</p>
            <div className=" mt-4
        is-flex is-justify-content-center has-text-light">
                <button class="button is-white is-small" onClick={() => toggleTabsCss('enCours')}><p className={style.enCours}>En cours</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('gagne')}><p className={style.gagne}>Gagné</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('perdu')}><p className={style.perdu}>Perdu</p></button>
                <button class="button is-white is-small" onClick={() => toggleTabsCss('tout')}><p className={style.tout}>Tout</p></button>
            </div>

            <div className='mt-4'>
                {renderBetList()}
            </div>

        </>
    )
}

export default BetsHistoryMiddleMobile
