//export const apiUrl = 'https://bet509-backend-nodejs.herokuapp.com'
export const apiUrl = 'https://pjparyaj.ayitibetting.com/'
//export const apiUrl = 'https://bet509.xyz'
//export const apiUrl = 'http://bet509-backend-nodejs-dev.us-east-1.elasticbeanstalk.com'
//export const apiUrl = 'https://bet509.ml'
//export const apiUrl = 'http://localhost:3500/'
export const todayDate = new Date().toISOString().slice(0, 10);
export const validNumbers = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99']
export const dataUrl = 'https://bet509.ayitibetting.com'
//export const dataUrl = 'http://localhost:3005'
