import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-activity'
import { FaExchangeAlt } from 'react-icons/fa'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import { getTransactions } from '../functions/api'
import { getToken } from '../functions/auth'
import * as dayjs from 'dayjs'

dayjs.locale('fr-ca')
dayjs.extend(advancedFormat)

const MyTransactionsMiddle = () => {

    const [transactions, setTransactions] = useState(null)

    useEffect(() => {
        getTransactions(getToken().id, setTransactions)
    }, [])


    const renderStatus = (stat) => {
        switch (stat.toLowerCase()) {
            case 'pending':
                return 'En attente'

            case 'success':
                return 'Success'

            case 'failed':
                return 'Echec'

            default:
                return stat
        }
    }

    const renderMet = (m) => {
        if (m !== 'Moncash') {
            return m
        } else {
            return ''
        }
    }

    const renderid = (m , i ) => {
        if (m == 'Moncash') {
            return 'Moncash'
        } else {
            return i
        }
    }

    const renderto = (m , to ) => {
        if (m == 'Moncash') {
            return to
        } else {
            return ''
        }
    }

    const renderDepot = (arr) => {
        if (arr.length == 0) {
            return <p className='has-text-centered'> Pas de transactions</p>
        } else {
            return arr.map((v, i) => < div className='px-2 py-2 is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray' }}>
                <p><small>{v.gateway}</small></p> <p><small>{v.data.cost} HTG</small></p> <p><small>{dayjs(v.created).format('DD/MM/YYYY , hh:mm')}</small></p> <p style={{ color: 'green' }}><small>{renderStatus(v.status)}</small></p>
            </div>)
        }
    }

    const renderRetrait = (arr) => {
        if (arr.length == 0) {
            return <p className='has-text-centered'> Pas de transactions</p>
        } else {
            return arr.map((v, i) => < div className='px-2 py-2 is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray' }}>
                <p><small>{renderid(v.method,v.id) }</small></p><p><small>{renderMet(v.method)}</small></p><p><small>{renderto(v.method , v.to)}</small></p> <p><small>{v.amount} HTG</small></p> <p><small>{dayjs(v.created).format('DD/MM/YYYY , hh:mm')}</small></p> <p style={{ color: 'green' }}><small>{renderStatus(v.status)}</small></p>
            </div>)
        }
    }

    const renderParis = (arr) => {
        if (arr.length == 0) {
            return <p className='has-text-centered'> Pas de transactions</p>
        } else {
            return arr.map((v, i) => < div className='px-2 py-2 is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray' }}>
                <p><small>{v.method}</small></p> <p><small>{v.amount} HTG</small></p> <p><small>{dayjs(v.created).format('DD/MM/YYYY , hh:mm')}</small></p> <p style={{ color: 'green' }}><small>{renderStatus(v.status)}</small></p>
            </div>)
        }
    }

    const renderTransfert = (arr) => {
        if (arr.length == 0) {
            return <p className='has-text-centered'> Pas de transactions</p>
        } else {
            return arr.map((v, i) => < div className='px-2 py-2 is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray' }}>
                <p><small>{v.to}</small></p> <p><small>{v.amount} HTG</small></p> <p><small>{dayjs(v.created).format('DD/MM/YYYY , hh:mm')}</small></p> <p style={{ color: 'green' }}><small>{renderStatus(v.status)}</small></p>
            </div>)
        }
    }

    const renderReception = (arr) => {
        if (arr.length == 0) {
            return <p className='has-text-centered'> Pas de transactions</p>
        } else {
            return arr.map((v, i) => < div className='px-2 py-2 is-flex is-justify-content-space-between' style={{ borderBottom: '1px solid gray' }}>
                <p><small>{v.comment}</small></p> <p><small>{v.amount} HTG</small></p> <p><small>{dayjs(v.created).format('DD/MM/YYYY , hh:mm')}</small></p> <p style={{ color: 'green' }}><small>{renderStatus(v.status)}</small></p>
            </div>)
        }
    }

    const renderTransaction = (trans) => {
        if (trans === null) {
            return <div className='is-flex is-justify-content-center is-align-items-center'><Spinner color='green' /></div>
        } else {
            return (
                <div>
                    <div className='card mt-4'>
                        <header class="card-header is-flex" style={{ backgroundColor: 'green', color: 'white' }}> <p className='ml-2'>Depots</p> </header>
                        <div className='card-content'>
                            {renderDepot(trans.data.depo)}

                        </div>



                    </div>
                    <div className='card mt-2'>
                        <header class="card-header is-flex" style={{ backgroundColor: 'green', color: 'white' }}> <p className='ml-2'>Retraits</p> </header>
                        <div className='card-content'>
                            {renderRetrait(trans.data.retrait)}
                        </div>

                    </div >
                    <div className='card mt-2'>
                        <header class="card-header is-flex" style={{ backgroundColor: 'green', color: 'white' }}> <p className='ml-2'>Paris</p>  </header>
                        <div className='card-content'>
                            {renderParis(trans.data.pari)}
                        </div>

                    </div>
                    <div className='card mt-2'>
                        <header class="card-header is-flex" style={{ backgroundColor: 'green', color: 'white' }}> <p className='ml-2'>Transferts</p> </header>
                        <div className='card-content'>
                            {renderTransfert(trans.data.send)}
                        </div>

                    </div>

                    {/* <div className='card mt-2'>
                        <header class="card-header is-flex" style={{ backgroundColor: 'green', color: 'white' }}> <p className='ml-2'>Reception</p> </header>
                        <div className='card-content'>
                            {renderReception(trans.data.receive)}
                        </div>

                    </div> */}
                </div>
            )
        }
    }


    return (
        <div className='mt-2'>
            <p className='has-text-centered is-size-5 has-text-light'> <FaExchangeAlt /> Mes transactions</p>
            {renderTransaction(transactions)}
        </div>
    )
}

export default MyTransactionsMiddle
