/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-activity'
import { FaExchangeAlt } from 'react-icons/fa'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import { convertParrain, getFiyel, getTransactions, getUserInfo } from '../functions/api'
import { getToken } from '../functions/auth'
import * as dayjs from 'dayjs'
import { RiCommunityFill } from 'react-icons/ri';
import { IoIosPeople } from 'react-icons/io';
import Swal from 'sweetalert2';
import { error, success } from '../functions/toast';
import { useNavigate } from 'react-router-dom';
import { MdPerson } from 'react-icons/md';

dayjs.locale('fr-ca')
dayjs.extend(advancedFormat)

const MyFiyelMiddle = () => {

    const [userInfo, setUserInfo] = useState({
        data: {
            codeParrain: '',
            balanceParrain: ''
        }
    })

    const [fiyel, setFiyel] = useState(null)

    useEffect(() => {
        getUserInfo(getToken().id, setUserInfo)
        getFiyel(getToken().id, setFiyel)
    }, [])

    const navigate = useNavigate()

    const handleConvert = (data) => {
        if (data.data === 'success') {
            success('Convertion reussie')
            navigate(0)

        } else {
            error('Conversion echouée')
        }
    }
    const openConvert = () => {

        Swal.fire({
            title: 'Entrez le montant',
            input: 'number',
            // inputLabel: 'Your IP address',
            inputPlaceholder: '100',
            showCancelButton: true,
            confirmButtonColor: 'green',
            inputValidator: (value) => {
                if (!value || parseInt(value) < 100) {
                    return 'Veuillez rentrer un montant superieur ou egal a 100'
                }
            }
        }).then(x => {
            if (x.value) {
                convertParrain(getToken().id, parseInt(x.value), handleConvert)
                //Swal.fire(x.value)
            }
        })
    }

    const renderFiyel = (data) => {

        if (data === null) {
            return <Spinner />
        } else if (data.data.length == 0) {
            return <p className='has-text-centered mt-2'> <small> Pas de filleuls </small></p>
        } else {
            return data.data.map((v, i) =>
                <div className='is-flex' style={{ borderBottom: '1px solid black' }}>
                    <small className='ml-2'> {v.phone + '**'}</small> <small className='ml-auto'> {dayjs(v.date).format()}</small>
                </div>)
        }
    }



    return (
        <div className='mt-2 pb-4'>
            <p className='has-text-centered is-size-5 has-text-light' > <IoIosPeople /> Mes filleuls</p>
            {/* <p className='has-text-centered has-text-light mt-4'>Code Parrain : {userInfo.data.codeParrain}</p> */}
            <p className='has-text-centered has-text-light mt-4'><small>https://pjparyaj.com/signup?parrain={userInfo.data.codeParrain.toUpperCase()}</small></p>
            <p className='has-text-centered has-text-primary' onClick={() => location.href = 'whatsapp://send?text=Inscrivez-vous sur PJPARYAJ en cliquant sur ce lien https://pjparyaj.com/signup?parrain=' + userInfo.data.codeParrain.toUpperCase() + ' et recevez un bonus de 50 HTG pour placer un pari sur vos équipes favoris . '}><small>Partager mon lien parrainage sur Whatsapp</small></p>
            <p className='has-text-centered has-text-info' onClick={() => location.href = 'http://www.facebook.com/sharer/sharer.php?u=https://pjparyaj.com/signup?parrain=' + userInfo.data.codeParrain.toUpperCase() + '&t=Inscrivez-vous sur PJPARYAJ en cliquant sur ce lien https://pjparyaj.com/signup?parrain=' + userInfo.data.codeParrain.toUpperCase() + ' et recevez un bonus de 50 HTG pour placer un pari sur vos équipe favoris . '}><small>Partager mon lien parrainage sur Facebook</small></p>
            <div className='is-flex is-justify-content-center mt-4'>
                <div className='box'>
                    <div class="container" style={{fontSize:'13px'}}>
                        <table class="table is-bordered is-striped is-fullwidth">
                            <thead>
                                <tr>
                                    <th>BALANCE PARRAIN</th>
                                    <th>MES FILLEULS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{userInfo.data.balanceParrain} HTG</td>
                                    <td>{fiyel?.data.length}</td>
                                </tr>
                                <tr>
                                    <th>CODE PARRAINAGE</th>
                                    <th>SOUS FILLEULS</th>
                                </tr>
                                <tr>
                                    <td>{userInfo?.data.codeParrain.toUpperCase()}</td>
                                    <td>0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* <p className='has-text-centered'>Balance parrainage : {userInfo.data.balanceParrain} HTG</p>
                    <p className='has-text-centered mt-4 has-text-primary' onClick={() => openConvert()}><small>Convertir</small></p> */}

                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header' style={{ backgroundColor: 'darkgreen', color: 'white' }}>
                    <p className='ml-2'> Liste de mes filleuls</p>
                </div>

                <div className='card-content'>
                    <div className='is-flex' style={{ backgroundColor: 'darkgray', color: 'white' }}>
                        <small className='ml-2'> Utilisateur </small> <small className='ml-auto'> Date d'Inscription</small>
                    </div>
                    {renderFiyel(fiyel)}
                </div>
            </div>

            <div className='card mt-4'>
                <div className='card-header' style={{ backgroundColor: 'darkgreen', color: 'white' }}>
                    <p className='ml-2'> Termes et Conditions </p>
                </div>
                <p className='has-text-dark help has-text-centered'>

                    <b> Comment devenir parrain sur PJ Paryaj? </b> <br />

                    Pour devenir parrain, le joueur doit avoir un compte valide sur PJ Paryaj.<br />

                    <b> Comment obtenir des filleuls sur PJ Paryaj? </b>  <br />

                    Pour obtenir des filleuls, le parrain doit se rendre sur "Mon compte" ( <MdPerson />) pour trouver son code parrain.<br />

                    Le parrain peut partager ce code avec ses amis et connaissances pour leur permettre de devenir filleuls.<br />

                    À chaque inscription, les filleuls doivent entrer ce code dans le formulaire d'inscription.<br />

                    NB: Il est interdit d'ajouter un code parrain lorsque l'on a déjà un compte PJ Paryaj.<br />

                    <b> Bonus Parrainage pour le Parrain </b>  <br />

                    Le parrain aura la possibilité de recevoir un gain sur sa balance parrain en fonction du solde résultant des paris des filleuls et des sous-filleuls chaque mois. Le code parrainage se renouvelle chaque 6 mois.<br />

                    Si le solde des filleuls est négatif, le parrain ne gagne rien, et si le solde est positif, le gain sera automatiquement versé sur sa balance Filleul chaque 1er du mois.<br />

                    Votre Balance parrain sera disponible quand vous aurez au minimum 2 filleuls.
                </p>
            </div>
        </div>
    )
}

export default MyFiyelMiddle
