import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useRecoilState } from 'recoil';
import { textState } from '../store/bets_store';
import { cartTypeState } from '../store/bets_store';
import { fixtures } from '../assets/data/fixtures';
import { GiBasketballBall, GiHockey, GiSoccerBall, GiVolleyballBall } from 'react-icons/gi';
import Moment from 'react-moment';
import { getDailyVolleyballFixtures, getSingleGame } from '../functions/api';
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr-ca'; // import locale
import { MdSportsHandball } from 'react-icons/md';
import { closeLoader, openLoad } from './fullscreen_loader';
import Spinner from 'react-activity/dist/Spinner';
import Slider from './slider'
import { useNavigate } from 'react-router-dom';



dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

function VolleyballMiddle() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [cartType, setCartType] = useRecoilState(cartTypeState)
    const [text, setText] = useRecoilState(textState)
    const [volleyballFixtures, setVolleyballFixtures] = useState(null);
    const [resultLength, setResultLength] = useState(0)
    const [page, setPage] = useState(1);
    const [oddsArr, setOddsArr] = useState([])
    const [theArr, setTheArr] = useState(null)
    const [renderButtonText, setRenderButtonText] = useState({ text: 'Charger plus de resultats', disabled: false })
    const navigate = useNavigate()

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const setTheVolleyball = (res) => {
        if (volleyballFixtures) {
            const newArr = volleyballFixtures.data.concat(res.data)
            setVolleyballFixtures({ data: newArr })
        } else {
            setVolleyballFixtures(res)
        }
    }
    useEffect(() => {
        getDailyVolleyballFixtures(page, setTheVolleyball, setResultLength, dayjs.utc().unix())
    }, [page]);

    useEffect(() => {
        if (volleyballFixtures) {
            const arr = volleyballFixtures.data.map((v, i) => v.id)
            getSingleGame('volleyball', arr, setOddsArr)
            //alert(JSON.stringify(arr))
            setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
            if (page > 4) {
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: true })
            } else {

                setTimeout(() => {
                    setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
                }, 2000);

            }
        }
    }, [volleyballFixtures]);

    useEffect(() => {
        if (oddsArr !== 'nada' && oddsArr.length >= 1) {

            const theArrVar = (volleyballFixtures, oddsArr) => {
                const arr = oddsArr.map((v, i) => v.id)
                const res = []
                let i = 0, len = volleyballFixtures.data.length

                while (i < len) {
                    if (arr.includes(volleyballFixtures.data[i].id)) {
                        res.push(volleyballFixtures.data[i])
                    }

                    i++
                }

                return res
            }

            setTheArr(theArrVar(volleyballFixtures, oddsArr))

        } else if (oddsArr == 'nada') {
            setTheArr([])
        } else {
            setTheArr(null)

        }
    }, [oddsArr])



    const verifBetInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });


        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameInCart = (id, betName) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameAndBetChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const verifFixtureAndBetNameAndBetNonChooseInCart = (id, betName, betChoose) => {

        const found = text.some(el => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose !== betChoose) {
                return true;
            } else {
                return false
            }
        });

        if (found) {
            return true

        } else {
            return false
        }
    }

    const removeFromCart = (id, betName, betChoose) => {

        text.some((el, index) => {
            if (el.fixtureId === id && el.betName === betName && el.betChoose === betChoose) {
                let arr = [...text]
                arr.splice(index, 1)
                setText(arr)
                return true
            } else {
                return false
            }
        });
    }

    const addBet = (payload, id) => {
        if (!verifFixtureInCart(id, payload.betName)) { //si match la pa egziste

            if (!verifBetInCart(id, payload.betName, payload.betChoose)) { //si match la pa egziste e si pari a pa egziste

                setText([...text, {
                    sport: payload.sport,
                    fixtureId: payload.fixtureId,
                    homeTeam: payload.homeTeam,
                    awayTeam: payload.awayTeam,
                    betName: payload.betName,
                    betChoose: payload.betChoose,
                    cote: payload.cote,
                    amount: 0
                }])
            } else { // si match la pa egziste men pari a egziste
                //removeFromCart(id, payload.betName, payload.betChoose)
            }
        } else if (verifFixtureAndBetNameAndBetChooseInCart(id, payload.betName, payload.betChoose)) { // si match la egziste

            removeFromCart(id, payload.betName, payload.betChoose)
        }
        else if (verifFixtureAndBetNameAndBetNonChooseInCart(id, payload.betName, payload.betChoose)) {
            setCartType({
                type: 'simple',
                combo: {
                    hidden: true,
                    class: '',
                },
                simple: {
                    hidden: false,
                    class: 'is-active',
                }
            })
            setText([...text, {
                sport: payload.sport,
                fixtureId: payload.fixtureId,
                homeTeam: payload.homeTeam,
                awayTeam: payload.awayTeam,
                betName: payload.betName,
                betChoose: payload.betChoose,
                cote: payload.cote,
                amount: 0
            }])
        }
    }

    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#FFC900', color: 'black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const paginate = () => {
        if (theArr && oddsArr) {

            if (oddsArr !== 'nada') {
                //openLoad()
                setRenderButtonText({ text: <Spinner color='#FFC900' />, disabled: true })
                setPage(page + 1)


            }
        }
    }

    const getBet = (arr, id, bet) => {
        const obj = arr.find(x => x.id == id)
        if (obj.bets) {
            return { odd: obj.bets.values[bet].odd, total: obj.total }
        } else {

            return {
                odd: 'error',
                total: 'error'
            }
        }
    }

    const renderButton = () => {
        if (theArr && theArr.length >= 1) {
            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => paginate()} disabled={renderButtonText.disabled}>{renderButtonText.text}</button>
                </div>
            )
        }
    }

    const renderMobile = () => {

        if (!theArr) {

            return (
                <div className='is-hidden-desktop is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 1) {
            return <div className='is-hidden-touch'>
                <hr />
                <div className='mt-4 '>
                    <div className='is-hidden-touch is-flex is-justify-content-center mt-6'>
                        <p> Désolé , il n'y a plus d'evenement a afficher pour aujourd'hui .</p>
                    </div>
                </div>
            </div>
        }
        return (
            theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <small><b> <GiBasketballBall /> {val.teams.home.name} - {val.teams.away.name} </b></small> <span className="ml-auto"><small> {dayjs.unix(val.timestamp).format('h:mm A')}</small></span>
                            </p>
                            <p className="is-flex">
                                <small> <img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small> <span className="ml-auto"> <small>{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')} </small></span>
                            </p>
                            <div className="is-flex is-justify-content-space-between">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: getBet(oddsArr, val.id, 0).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {getBet(oddsArr, val.id, 0).odd}
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: getBet(oddsArr, val.id, 1).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            {getBet(oddsArr, val.id, 1).odd}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2"  onClick={() => navigate('/sports/volleyball/event?game=' + val.id)}>
                                    <div>
                                        <p style={{ fontSize: '15px' }}>{"+" + getBet(oddsArr, val.id, 0).total}</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                )
            })
        )
    }

    const renderDesktop = () => {
        if (!theArr) {

            return (
                <div className='is-hidden-touch is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {
            return <div className='is-hidden-touch'>
                <div className='mt-4 '>
                    <div className='is-hidden-touch is-flex is-justify-content-center has-text-light mt-6'>
                        <p> Désolé , il n'y a plus d'evenement a afficher pour aujourd'hui .</p>
                    </div>
                </div>
            </div>
        } else {

            return theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <span> <GiVolleyballBall />  {val.teams.home.name} - {val.teams.away.name} </span> <span className="ml-auto">{dayjs.unix(val.timestamp).format('h:mm A')}</span>
                            </p>

                            <p className="is-flex">
                                <small><img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small>
                                <span className="ml-auto">{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')}</span>
                            </p>

                            <div className="is-flex is-justify-content-space-evenly mt-2">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: getBet(oddsArr, val.id, 0).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 0).odd}
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: getBet(oddsArr, val.id, 1).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 1).odd}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2 mt-2"  onClick={() => navigate('/sports/volleyball/event?game=' + val.id)}>
                                    <div>
                                        {"+" + getBet(oddsArr, val.id, 0).total}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            })


        }
    }


    return (

        <>

            <div className='my-2'>
                <div className='is-flex is-justify-content-center is-hidden-mobile'>

                    <Slider />


                </div>

                <div className='is-flex is-justify-content-space-between is-hidden-tablet px-2'>
                    <div className='mt-2'>
                        <Slider />
                    </div>


                </div>

                <div>
                    <div className='is-hidden-desktop px-2 pb-2'>
                        <hr />
                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center px-6'>
                                <p className='has-text-centered is-size-5 has-text-light'><GiVolleyballBall /> Volleyball</p>
                            </div>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered has-text-light'><small> - Toutes competitions -</small></p>
                            </div>
                            {
                                renderMobile()
                            }
                        </div>
                        {renderButton()}
                    </div>
                </div>
                <div>
                    <div className='is-hidden-touch'>
                        <hr />
                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered is-size-5 has-text-light'>  <GiVolleyballBall /> VolleyBall</p>
                            </div>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered has-text-light'><small> - Toutes competitions -</small></p>
                            </div>
                            {
                                renderDesktop()
                            }
                        </div>
                        {renderButton()}
                    </div>
                </div>
            </div>


















        </>

    )
}

export default VolleyballMiddle;
