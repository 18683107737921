import React, { useState } from 'react';
//import { fixtures } from '../assets/data/fixtures';
import { GiSoccerBall, } from 'react-icons/gi';
import { RiMedal2Fill } from 'react-icons/ri';
import { IoEyeSharp } from 'react-icons/io5'
import { useEffect } from 'react';
import { getDailyFootballFixtures } from '../functions/api';
import Spinner from 'react-activity/dist/Spinner';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import * as dayjs from 'dayjs'
import { json, useNavigate } from 'react-router-dom'
import AddBetComponent from '../functions/bet'
import { getPaysByCC, renderCote } from '../functions/utils';
dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')
const HomeFootball = (props) => {

    const [fixtures, setFixtures] = useState(null);
    const [resultLength, setResultLength] = useState(0)
    const navigate = useNavigate()


    const filteredArray = (arr) => arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)


    const setTheFootball = (res) => {
        if (!fixtures) {
            setFixtures(res.data)
        } else {
            setFixtures(fixtures.concat(res.data))
        }
    }

    useEffect(() => {

        setFixtures(null)
        getDailyFootballFixtures('1', 1, setTheFootball, setResultLength, dayjs.utc().unix(), null, true)

    }, []);


    const getObject = (id) => fixtures.fixtures.find(x => x.fixture.id == id)
    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart



    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#141E27', color: 'white', border: '2px solid black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black', border: '1px solid black' }
        }
    }


    const renderMobile = () => {

        if (fixtures === null) {
            return <div className='is-flex is-justify-content-center mt-4'>
                <Spinner color='#FFC900' />
            </div>
        } else if (fixtures.length == 0) {

            return (
                <div className='mt-4'>
                    <div className='is-flex is-justify-content-center px-6'>
                        <p className='has-text-centered is-size-5 has-text-light'> <GiSoccerBall /> Football </p>
                    </div>
                    <div className='is-flex is-justify-content-center mt-4'>
                        <p className='has-text-light'> Désolé il n' y a plus d' evenement a afficher .</p>
                    </div>
                </div>
            )
        }

        //return JSON.stringify(fixtures.odds)

        let theArr = fixtures

        return (
            <div>
                <div className=''>
                    <div className='px-2 pb-2'>
                        {/* {JSON.stringify(theArr)} */}
                        {/* <div className='is-flex is-justify-content-center'>
                            <p className='has-text-centered has-text-dark'><small> {getCompetition()} </small></p>
                        </div> */}
                        {
                            theArr.map((val, i) => {
                                return (
                                    <div className="mt-2">
                                        <p className="is-flex py-2" style={{ fontSize: '13.5px' }}>
                                            <small onClick={() => navigate('/sports/football/event?game=' + val.fixture.id)}><b> <GiSoccerBall /> {val.fixture.home.name} </b>&nbsp; - &nbsp; <b> {val.fixture.away.name} &nbsp; </b> <small className=''>  {val.fixture.league.name}</small></small>
                                        </p>
                                        <div className="px-2 columns is-mobile" style={{ backgroundColor: 'transparent', fontSize: '13.5px', borderBottom: '1px solid black' }}>
                                            <p className="column is-3">
                                                <span className="ml-auto" ><small>{dayjs.unix(val.timestamp).format('h:mm A')}</small></span> <br />
                                                <span className="ml-auto"><small>{dayjs.unix(val.timestamp).format('D / MM / YYYY')}</small></span>
                                            </p>
                                            <div className="column is-auto is-flex is-justify-content-space-between">
                                                <div class="field has-addons" onClick={() => addBet({
                                                    sport: 'F',
                                                    fixtureId: val.fixture.id,
                                                    country: val.fixture.league.name,
                                                    league: val.fixture.league.name,
                                                    homeTeam: val.fixture.home.name,
                                                    awayTeam: val.fixture.away.name,
                                                    timestamp: val.timestamp,
                                                    betName: 'Résultat final',
                                                    betChoose: '1',
                                                    cote: renderCote(val.bets.odds[0].odds),
                                                    amount: 0,
                                                    data: val.bets
                                                }, val.fixture.id)}>
                                                    <div class="control">
                                                        <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.fixture.id, { betName: 'Résultat final', betChoose: '1' })} />
                                                    </div>
                                                    <div class="control">
                                                        <a className="button  is-primary is-small" style={{ border: '1px solid black' }}>
                                                            {renderCote(val.bets.odds[0].odds)}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="field has-addons ml-2" onClick={() => addBet({
                                                    sport: 'F',
                                                    fixtureId: val.fixture.id,
                                                    country: val.fixture.league.name,
                                                    league: val.fixture.league.name,
                                                    homeTeam: val.fixture.home.name,
                                                    awayTeam: val.fixture.away.name,
                                                    timestamp: val.timestamp,
                                                    betName: 'Résultat final',
                                                    betChoose: 'X',
                                                    cote: renderCote(val.bets.odds[1].odds),
                                                    amount: 0,
                                                    data: val.bets
                                                }, val.fixture.id)}>
                                                    <div class="control">
                                                        <input className="input is-small" type="text" value="X" readOnly style={getInputStyle(val.fixture.id, { betName: 'Résultat final', betChoose: 'X' })} />
                                                    </div>
                                                    <div class="control">
                                                        <a className="button is-primary is-small" style={{ border: '1px solid black' }}>
                                                            {renderCote(val.bets.odds[1].odds)}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="field has-addons ml-2" onClick={() => addBet({
                                                    sport: 'F',
                                                    fixtureId: val.fixture.id,
                                                    country: val.fixture.league.name,
                                                    league: val.fixture.league.name,
                                                    homeTeam: val.fixture.home.name,
                                                    awayTeam: val.fixture.away.name,
                                                    timestamp: val.timestamp,
                                                    betName: 'Résultat final',
                                                    betChoose: '2',
                                                    cote: renderCote(val.bets.odds[2].odds),
                                                    amount: 0,
                                                    data: val.bets
                                                }, val.fixture.id)}>
                                                    <div class="control" >
                                                        <input className="input is-small" typse="text" value='2' readOnly style={getInputStyle(val.fixture.id, { betName: 'Résultat final', betChoose: '2' })} />
                                                    </div>
                                                    <div class="control">
                                                        <a className="button is-primary is-small" style={{ border: '1px solid black' }}>
                                                            {renderCote(val.bets.odds[2].odds)}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div onClick={() => navigate('/sports/football/event?game=' + val.fixture.id)} className='ml-2'>
                                                    <p style={{ fontSize: '12px' }}>{"+" + val.total}</p>
                                                </div>
                                            </div>
                                            {/* <p className="column is-1 pr-4">
                                                <div onClick={() => navigate('/sports/football/event?game=' + val.fixture.id)} >
                                                    <p style={{ fontSize: '12px' }}>{"+" + val.total}</p>
                                                </div>
                                            </p> */}
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </div>

        )
    }

    return (

        <>
            {/* <div className='is-hidden-touch' style={{ backgroundColor: '#f6dfae' }}>
                <hr />
                <p className='has-text-centered has-text-dark is-size-6'> <RiMedal2Fill />  Top Football </p>
                <div className=''>

                    {
                        renderDesktop()
                    }
                </div>

            </div> */}

            <div className='pb-2' style={{}}>
                <div className=''>

                    {
                        renderMobile()
                    }
                </div>
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => navigate('/sports/football')} ><IoEyeSharp /> Voir tout Football</button>
                </div>

            </div>
        </>

    )


}

export default HomeFootball