import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { isNextClickState } from '../store/bets_store';
import { getDailyRugbyFixtures, getSingleGame } from '../functions/api';
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr-ca'; // import locale
import { MdSportsRugby } from 'react-icons/md';
import Spinner from 'react-activity/dist/Spinner';
import Slider from './slider'
import AddBetComponent from '../functions/bet'
import { useSearchParams, useNavigate } from 'react-router-dom'


dayjs.extend(advancedFormat);
dayjs.locale('fr-ca')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)



function RugbyMiddle() {

    const [rugbyFixtures, setRugbyFixtures] = useState(null);
    const [page, setPage] = useState(1)
    const [resultLength, setResultLength] = useState(0)
    const [oddsArr, setOddsArr] = useState([])
    const [theArr, setTheArr] = useState(null)
    const [renderButtonText, setRenderButtonText] = useState({ text: 'Charger plus de resultats', disabled: false })
    const [searchParams, setSearchParams] = useSearchParams();
    const getLeague = () => {
        if (parseInt(searchParams.get("league")) > 0) {
            return parseInt(searchParams.get("league"))
        }
        return 0
    }
    const league = getLeague()
    const [isNextClick, setIsNextClick] = useRecoilState(isNextClickState)

    const filteredArray = (arr) => arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

    const navigate = useNavigate()

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const setTheRugby = (res) => {
        if (rugbyFixtures) {
            const newArr = rugbyFixtures.data.concat(res.data)
            const arr = []
            for (const el of newArr) {
                if (league > 0) {
                    if (el.league.id == league) {
                        arr.push(el)
                    }
                } else {
                    arr.push(el)
                }
            }
            setRugbyFixtures({ data: filteredArray(arr) })
        } else {
            const arr = []

            for (const el of res.data) {
                if (league > 0) {
                    if (el.league.id == league) {
                        arr.push(el)
                    }
                } else {
                    arr.push(el)
                }
            }
            setRugbyFixtures({ ...res, data: filteredArray(arr) })
        }
    }


    useEffect(() => {
        if (league === 0 && isNextClick === true) {
            getDailyRugbyFixtures(page, setTheRugby, setResultLength, dayjs.utc().unix(), null)
        } else if (league === 0 && isNextClick === false) {
            setRugbyFixtures(null)
            getDailyRugbyFixtures(1, setTheRugby, setResultLength, dayjs.utc().unix(), null)
        }
        else if (league > 0 && isNextClick === false) {
            setRugbyFixtures(null)
            getDailyRugbyFixtures(1, setTheRugby, setResultLength, dayjs.utc().unix(), parseInt(league))
        } else if (league > 0 && isNextClick === true) {
            getDailyRugbyFixtures(page, setTheRugby, setResultLength, dayjs.utc().unix(), parseInt(league))
        }
    }, [page, league]);

    useEffect(() => {
        if (rugbyFixtures) {
            const arr = rugbyFixtures.data.map((v, i) => v.id)
            getSingleGame('rugby', arr, setOddsArr)
            //alert(JSON.stringify(arr))
            setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
            if (page > 4) {
                setRenderButtonText({ text: 'Charger plus de resultats', disabled: true })
            } else {

                setTimeout(() => {
                    setRenderButtonText({ text: 'Charger plus de resultats', disabled: false })
                }, 2000);

            }
        }
    }, [rugbyFixtures]);

    useEffect(() => {
        if (oddsArr !== 'nada' && oddsArr.length >= 1) {
            const theArrVar = (rugbyFixtures, oddsArr) => {
                const arr = oddsArr.map((v, i) => v.id)
                const res = []
                let i = 0, len = rugbyFixtures.data.length

                while (i < len) {
                    if (arr.includes(rugbyFixtures.data[i].id)) {
                        res.push(rugbyFixtures.data[i])
                    }

                    i++
                }

                return res
            }

            setTheArr(theArrVar(rugbyFixtures, oddsArr))

        } else if (oddsArr == 'nada') {
            setTheArr([])
        } else {
            setTheArr(null)

        }
    }, [oddsArr])


    const addBet = AddBetComponent().addBet
    const verifBetInCart = AddBetComponent().verifBetInCart


    const getInputStyle = (id, payload) => {
        const theId = id
        if (verifBetInCart(theId, payload.betName, payload.betChoose)) {
            return { backgroundColor: '#FFC900', color: 'black' }
        } else {

            return { backgroundColor: '#E6E6E6', color: 'black' }
        }
    }

    const paginate = () => {
        if (theArr && oddsArr) {

            if (oddsArr !== 'nada') {
                //openLoad()
                setRenderButtonText({ text: <Spinner color='#FFC900' />, disabled: true })
                setPage(page + 1)


            }
        }
    }

    const getBet = (arr, id, bet) => {

        if (Array.isArray(arr)) {

            const obj = arr.find(x => x.id == id)
            if (obj) {
                return { odd: obj.bets.values[bet].odd, total: obj.total }
            } else {

                return {
                    odd: 'error',
                    total: 'error'
                }
            }
        } else {
            return <Spinner color='#FFC900' />
        }
    }

    const renderButton = () => {
        if (theArr && theArr.length >= 1) {
            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <button class="button is-primary is-small" onClick={() => paginate()} disabled={renderButtonText.disabled}>{renderButtonText.text}</button>
                </div>
            )
        }
    }

    const renderMobile = () => {
        if (!theArr) {

            return (
                <div className='is-hidden-desktop is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {
            return <div>
                <div className='is-flex is-justify-content-center mt-6 has-text-light has-text-centered'>
                    <p> Désolé , il n'y a plus d'evenement a afficher .</p>
                </div>
            </div>
        }

        return (
            theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2">
                            <p className="is-flex">
                                <small><b> <MdSportsRugby /> {val.teams.home.name} - {val.teams.away.name} </b></small> <span className="ml-auto"><small> {dayjs.unix(val.timestamp).format('h:mm A')}</small></span>
                            </p>
                            <p className="is-flex">
                                <small> <img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small> <span className="ml-auto"> <small>{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')} </small></span>
                            </p>
                            <div className="is-flex is-justify-content-space-between">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: 2,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            2
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'F',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: 2,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input is-small" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary is-small" >
                                            5
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2"  onClick={() => navigate('/sports/rugby/event?game=' + val.id)}>
                                    <div>
                                        <p style={{ fontSize: '15px' }}>+145</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                )
            })
        )
    }

    const renderDesktop = () => {
        if (!theArr) {

            return (
                <div className='is-flex is-justify-content-center mt-4'>
                    <Spinner color='#FFC900' />
                </div>
            )
        } else if (theArr.length == 0) {
            return <div className='is-hidden-touch'>
                <div className='mt-4 '>
                    <div className='is-hidden-touch is-flex is-justify-content-center mt-6 has-text-light'>
                        <p> Désolé , il n'y a plus d'evenement a afficher pour aujourd'hui .</p>
                    </div>
                </div>
            </div>
        } else {




            return theArr.map((val, i) => {
                return (
                    <div className="mt-2">
                        <div className="card p-2" style={{ backgroundColor: '#ffecb3' }}>
                            <p className="is-flex">
                                <span><MdSportsRugby />  {val.teams.home.name} - {val.teams.away.name} </span> <span className="ml-auto">{dayjs.unix(val.timestamp).format('h:mm A')}</span>
                            </p>

                            <p className="is-flex">
                                <small><img src={val.country.flag} width={16} height={16} /> {val.league.name} &nbsp; - {val.country.name}</small>
                                <span className="ml-auto">{dayjs.unix(val.timestamp).format('dddd, D MMMM YYYY')}</span>
                            </p>

                            <div className="is-flex is-justify-content-space-evenly mt-2">

                                <div class="field has-addons" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '1',
                                    cote: getBet(oddsArr, val.id, 0).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='1' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 0).odd}
                                        </a>
                                    </div>
                                </div>

                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: 'X',
                                    cote: getBet(oddsArr, val.id, 1).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='X' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '1' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 1).odd}
                                        </a>
                                    </div>
                                </div>


                                <div class="field has-addons pl-2" onClick={() => addBet({
                                    sport: 'BASKETBALL',
                                    fixtureId: val.id,
                                    homeTeam: val.teams.home.name,
                                    awayTeam: val.teams.away.name,
                                    betName: 'Resultat du match',
                                    betChoose: '2',
                                    cote: getBet(oddsArr, val.id, 2).odd,
                                    amount: 0
                                }, val.id)}>
                                    <div class="control">
                                        <input className="input" type="text" value='2' readOnly style={getInputStyle(val.id, { betName: 'Resultat du match', betChoose: '2' })} />
                                    </div>
                                    <div class="control">
                                        <a className="button is-primary" >
                                            {getBet(oddsArr, val.id, 2).odd}
                                        </a>
                                    </div>
                                </div>

                                <div className=" is-flex pl-2 mt-2"  onClick={() => navigate('/sports/rugby/event?game=' + val.id)}>
                                    <div>
                                        {'+' + getBet(oddsArr, val.id, 0).total}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            })


        }
    }


    return (

        <>

            <div className='my-2'>
                <div className='is-flex is-justify-content-center is-hidden-mobile'>


                    <Slider />

                </div>

                <div className='is-flex is-justify-content-space-between is-hidden-tablet px-2'>


                    <div className='mt-2'>
                        <Slider />
                    </div>

                </div>


                <div>
                    <div className='is-hidden-desktop px-2 pb-2'>
                        <hr />
                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center px-6'>
                                <p className='has-text-centered is-size-5 has-text-light'> <MdSportsRugby /> Rugby</p>
                            </div>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered has-text-light'><small> - Toutes competitions -</small></p>
                            </div>
                            {
                                renderMobile()
                            }
                        </div>
                        {renderButton()}
                    </div>
                </div>
                <div>
                    <div className='is-hidden-touch'>
                        <hr />
                        <div className='mt-4'>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered is-size-5 has-text-light'>  <MdSportsRugby /> Rugby </p>
                            </div>
                            <p className='has-text-centered'></p>
                            <div className='is-flex is-justify-content-center'>
                                <p className='has-text-centered has-text-light'><small> - Toutes competitions -</small></p>
                            </div>
                            {
                                renderDesktop()
                            }
                        </div>
                        {renderButton()}

                    </div>
                </div>
            </div>


















        </>

    )
}

export default RugbyMiddle;
